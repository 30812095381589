/**
 *  - MENU_HELPERS
 *  - SIDEBAR_MENU
 *    - MENU_MAIN
 *    - MENU_SUB_MAIN
 *  - RESPONSIVE
 */

/*****************/
/* MENU_HELPERS  */
/*****************/
@mixin v2__menu_toggle {
  cursor: pointer;
  position: absolute;
  top: 25px;
  left: 10px;
  padding: 0;
  margin-bottom: 0;
}

@mixin v2__sidebar_menu_close {
  display: flex;
  position: fixed;
  top: 15px;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.08);
  left: 200px;
  z-index: 10;
  cursor: pointer;
  align-items: center;

  i {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    font-weight: var(--font-normal);
    transform: none;
    color: var(--color-gray-300);
  }
}

@mixin v2__menu_toggle_compact {
  position: relative;
  left: auto;
  margin: 0;
  top: 15px;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.08);
}

@mixin v2__menu_big {
  width: 240px;

  #logout-btn .logout-btn em,
  .v2__sidebar_menu_content .sidebar-header img,
  .v2__sidebar_menu_content .sidebar-header .institution-current span,
  .v2__sidebar_menu_content #menu .menu li em {
    display: initial;
  }

  .v2__sidebar_menu_content {
    .sidebar-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0 33px 0;
      margin: 0;
      height: 115px;
      justify-content: space-between;

      &.with-institution-dropdown {
        height: 125px;
      }


      .toggle_button {
        @include v2__menu_toggle;
      }
    }

    &:not(:hover) {
      #menu .menu {
        overflow: auto;


        li.dropdown .menu-label {
          padding-top: 5px;
        }
      }
    }
  }
}


@mixin v2__menu_compact {
  width: 46px;

  #logout-btn .logout-btn em,
  .v2__sidebar_menu_content .sidebar-header .institution-current span,
  .v2__sidebar_menu_content #menu .menu li em,
  .v2__sidebar_menu_content #menu .menu li .badge-container {
    display: none;
  }

  .v2__sidebar_menu_content {
    &:not(:hover) {
      .sidebar-header {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 11px 0 20px 0;

        img {
          width: 24px;
          height: 24px;
          margin: 0 0 5px 0;
        }

      }

      .v2__menu_mobile_toggle {
        display: none;
      }

      .toggle_button {
        @include v2__menu_toggle_compact;
      }

      #menu .menu {
        overflow: hidden;

        li.dropdown .menu-label {
          padding-top: 7px;
          padding-left: 15px;
        }
      }

    }
  }
}

/****************/
/* SIDEBAR_MENU */
/****************/
.navbar-toggle {
  display: block;

  .icon-bar {
    background-color: var(--color-gray-800);
  }
}

.v2__sidebar_menu_close {
  @include v2__sidebar_menu_close;
  display: none;
}

.v2__sidebar_menu {
  display: none;
  z-index: 9000;

  .v2__sidebar_menu_content {
    @include transition-all;
    display: flex;
    padding: 0 12px;
    flex-direction: column;
    border-right: 1px solid var(--color-gray-300);

    .separator {
      opacity: 1;
      margin-left: 6px;
      margin-right: 6px;
      width: auto;
      border-bottom: 1px solid var(--color-gray-900-opactity-08);
    }

    .fa, .material-icons, .svg {
      color: var(--color-gray-300);
      font-weight: var(--font-bold);
    }

    .fa {
      font-size: var(--font-size-xs);
      line-height: var(--line-height-xs);
    }

    .material-icons {
      font-size: var(--font-size-base);
      line-height: var(--line-height-base);
    }

    .sidebar-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 25px;

      .logo-link {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      img {
        max-width: 47px;
        margin: 0;
      }

      .institution-current {
        color: var(--color-white);
        font-weight: var(--font-medium);
        @extend .text-xs;
        margin: 0;
        text-align: center;
        white-space: nowrap;
        display: flex;
        gap: 8px;
        flex-direction: row;
        align-items: center;

        i.fa, i.material-icons {
          color: var(--color-gray-300);
        }


      }

      .institution-switcher {
        position: relative;
        width: 100%;
        text-align: center;

        .action-icon {
          color: var(--color-gray-300);
          display: inline-block;
        }

        .dropdown-menu {
          @extend .v2__dropdown_menu;
          left: 0;
          right: auto;
          padding: 0;

          a.dropdown-item {
            padding: 5px 10px !important;
            @extend .text-xs;

            .fa {
              margin-right: 5px;
            }
          }
        }

        .dropdown.open {
          .action-icon {
            transform: rotate(180deg);
          }

          & ~ .dropdown-menu {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .v2__menu_mobile_toggle,
      .toggle_button {
        @include v2__sidebar_menu_close;
        left: 181px;
      }

      .toggle_button {
        display: none;
      }
    }

    #menu {
      @extend .text-sm;
      background-color: transparent;
      color: var(--color-gray-300);
      font-family: var(--font-family);
      width: auto;
      position: initial;
      height: initial;
      z-index: 0;
      display: flex;
      flex-grow: 1;


      .fa, .material-icons {
        color: var(--color-gray-900);
      }

      em {
        display: initial;
        font-size: var(--font-size-xs);
        line-height: var(--line-height-xs);
        white-space: nowrap;
      }


      /*******************/
      /*    MENU_MAIN    */
      /*******************/
      .menu {
        li {
          background-color: transparent;
          border: 0;
          margin: 15px 0;
          padding: 8px;
          font-weight: var(--font-semibold);
          line-height: var(--line-height-lg);

          &:not(.dropdown) {
            &:hover, &.selected {
              color: var(--color-white);
              background-color: var(--color-gray-900-opactity-08);
              border-radius: var(--rounded-md);

              em {
                font-weight: var(--font-semibold);
                opacity: 1;
              }

              .fa, .material-icons {
                color: var(--color-white);
              }
            }
          }


          .menu-label {
            display: flex;
            align-items: center;
            float: initial;
            margin: 0;

            em {
              align-items: center;
              flex-grow: 1;

              .badge {
                display: none !important;
              }
            }

            i.fa, i.material-icons {
              margin: 0 8px 0 0;
            }

            .badge {
              margin: 0;
              padding: 0;
              color: var(--color-white);
              background-color: var(--color-danger-500);
              width: 15px;
              height: 15px;
              font-size: 10px;
              line-height: 15px;
              display: block !important;
              text-align: center;
              border-radius: 50%;
              margin-right: 5px;
            }
          }

          .badge-container {
            margin: 0;
          }

          .fa, .material-icons, svg {
            margin-right: 8px;
          }

          .material-icons {
            line-height: 12px !important;
          }

          em {
            padding: 0;
            margin: 0;
            font-weight: var(--font-medium);
          }
        }
      }

      /*******************/
      /*  MENU_SUB_MAIN  */
      /*******************/
      ul li.dropdown {
        padding: 0;

        &, &:hover, &.selected {
          background-color: transparent;

          li.selected {
            background-color: var(--color-gray-900-opactity-08);
            border-radius: var(--rounded-md);

            em {
              color: var(--color-white);
              opacity: 1;
            }
          }
        }

        .menu-label {
          display: flex;
          padding: 5px 0 5px 10px;

          > i {
            display: none;
          }

          em {
            @extend .text-xs;
            font-weight: var(--font-weight);
            text-transform: uppercase;
            flex-grow: 1;
            color: var(--color-gray-300);
          }

          .far {
            line-height: initial;
            margin-left: 5px;
            color: var(--color-gray-300);
          }
        }

        .submenu {
          margin: 0;
          width: auto;
          display: block;

          li {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 2px 0;
            padding-left: 10px;

            em {
              display: flex;
              flex-grow: 1;
            }

            .material-icons {
              color: var(--color-gray-300);
            }

            .badge {
              margin: 0;
              padding: 1px 3px;
              color: var(--color-white);
              background-color: var(--color-danger-500);
              min-width: 15px;
              height: 15px;
              font-size: 10px;
              line-height: 13px;
              display: block !important;
              text-align: center;
              border-radius: 9999px;
              margin-right: 15px;
            }


            &:hover, &.selected {
              background-color: var(--color-gray-900-opactity-08);
              border-radius: var(--rounded-md);

              em {
                font-weight: var(--font-semibold);
                opacity: 1;
              }

              .fa, .material-icons, svg {
                color: var(--color-white);
              }
            }
          }
        }

        &:not(.selected) .menu-label .far {
          transform: rotate(90deg);
        }

        &.submenu {
          display: block !important;
        }

        &.hide-submenu {
          .menu-label .far {
            transform: rotate(0);
          }

          .submenu {
            display: none !important;
          }
        }
      }

      ul li.dropdown.selected span.menu-label span.far {
        transform: rotate(90deg);
      }

      ul li.dropdown.hide-submenu span.menu-label span.far {
        transform: rotate(0);
      }

      .bottom div:not(.separator) {
        border: 0;
      }

      #logout-btn {
        display: block;
        text-decoration: none;
        top: initial !important;
        border: 0;
        margin-top: 10px;

        &, em {
          font-style: normal;
        }

        .logout-btn, .logout-loader {
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 5px 10px;
          border-radius: var(--rounded-lg);
          font-size: var(--font-size-xs);
          line-height: var(--line-height-xs);
          display: flex;
          align-items: center;
        }

        .logout-loader {
          justify-content: center;
          display: none;
        }


        &:hover {
          .logout-btn, .logout-loader {
            background-color: var(--color-white);
            color: var(--color-gray-300);
          }

          em, .fa, .material-icons, .svg {
            color: var(--color-gray-900);
          }
        }

        .fa, .material-icons, .svg {
          color: var(--color-gray-300);
          margin-right: 7px;
        }
      }
    }
  }
}


/*****************/
/*   RESPONSIVE  */
/*****************/
@include md {
  .container {
    .page-content header .navbar-toggle {
      display: none;
    }

    .v2__sidebar_menu {
      display: block !important;

      .v2__sidebar_menu_content {
        .sidebar-header {
          .v2__menu_mobile_toggle {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .container {
    label[for='v2__sidebar_menu_toggle'] {
      display: none !important;
    }

    .page-content {
      margin-left: 46px;
    }

    .v2__sidebar_menu {
      @include v2__menu_compact;

      .v2__sidebar_menu_content .sidebar-header {
        padding-top: 5px;

        .toggle_button {
          @include v2__menu_toggle_compact;
          display: block;
        }
      }

      &:hover {
        @include v2__menu_big;

        .sidebar-header {
          .toggle_button {
            display: none;
          }
        }
      }
    }

    #v2__sidebar_menu_toggle_md:checked ~ {
      & .v2__sidebar_menu_close {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .material-icons {
          display: none;
        }

        &:before {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      & .v2__sidebar_menu {
        @include v2__menu_big;

        .v2__sidebar_menu_content .sidebar-header {

          .toggle_button {
            @include v2__sidebar_menu_close;

            .toggle_button_close {
              display: block;
            }

            .toggle_button_open {
              display: none;
            }
          }
        }
      }
    }
  }


  .container .v2__sidebar_menu:not(:hover) {
    .v2__sidebar_menu_content:not(:hover) #menu .menu li.dropdown .menu-label {
      padding-left: 0;
    }

    .v2__sidebar_menu_content #menu ul li.dropdown .submenu li {
      padding-left: 0;
    }

    .v2__sidebar_menu_content #menu .menu li .menu-label .badge {
      display: none !important;
    }

    .v2__sidebar_menu_content #menu .menu li .menu-label em,
    .v2__sidebar_menu_content #menu ul li.dropdown .submenu li em {
      display: none;
    }

    .v2__sidebar_menu_content #menu .menu li {
      padding-left: 0;
      padding-right: 0;
      margin-right: auto;
      margin-left: auto;
      font-size: 14px;
      text-align: center;
      display: block;

      svg, .material-icons {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 1024px) {
  .container {
    label[for='v2__sidebar_menu_toggle_md'] {
      display: none !important;
    }

    .v2__sidebar_menu {
      @include v2__menu_big;
      position: sticky;
      max-height: 100vh;

      .sidebar-header {
        &, &:hover {
          .toggle_button {
            display: none;
          }
        }
      }
    }

    #v2__sidebar_menu_toggle {
      &:not(:checked) {
        & ~ .v2__sidebar_menu_close {
          display: flex;
        }

        & ~ .v2__sidebar_menu {
          z-index: 0;
        }
      }

      &:checked {
        & ~ .v2__sidebar_menu_close {
          display: none;
        }

        & ~ .v2__sidebar_menu {
          @include v2__menu_compact;

          &:not(:hover) {
            .v2__sidebar_menu_content {
              padding-left: 0;
              padding-right: 0;


              li svg, li .material-icons {
                display: block;
                margin: 0 auto;
              }

              li.dropdown .submenu li .badge {
                display: none !important;
              }

            }
          }

          .sidebar-header .toggle_button {
            display: block;
          }


          &:hover {
            @include v2__menu_big;

            .v2__sidebar_menu_content .sidebar-header .toggle_button {
              @include v2__sidebar_menu_close;
              left: 190px !important;
            }
          }
        }
      }
    }
  }
}
// also usefull for sidebar
#profile a:hover {
  text-decoration: none;
}

header {
  display: flex;
  padding: 20px 10px;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  border: 0;
  box-shadow: none;

  .dropdown-menu {
    left: initial;
    right: -25px;
  }


  .sidebar_icons {
    width: 100%;
  }

  .new {
    background: var(--color-primary-500);
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    position: relative;

    &:after {
      content: "Nouveau";
      position: absolute;
      display: block;
      bottom: -7px;
      font-size: 8px;
      font-weight: bold;
      background: var(--color-warning-500);
      height: 10px;
      color: #fff !important;
      z-index: 9;
      overflow: visible;
      width: auto;
      line-height: 7px;
      padding: 2px;
      text-align: center;
      left: -2px;
      right: -2px;
    }
  }

  .institution-logo {
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    height: 60px;
    width: 120px;
  }

  .profile {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 10px;
    margin-left: 0;
    gap: 15px;

    #profile {
      margin: 0;
      padding: 0;

      span {
        display: none;
      }

      img {
        margin: 0;
        &, &:hover {
          box-shadow: none;
        }
      }
    }

    .separator {
      height: 32px;
      display: block;
      width: 1px;
      background-color: var(--color-gray-200);
    }


    .menu-top {
      border: 0;
      @extend .text-base;
      height: auto;
      margin: 0 15px 0 0;
      padding: 0;
      display: flex;
      flex-direction: row-reverse;
      gap: 24px;

      .sidebar-icon {
        color: var(--color-gray-500);
        position: relative;

        .notification-dot {
          position: absolute;
          right: -8px;
          top: -6px;
          padding: 0;
          display: block !important;
          border: 3px solid var(--color-white);
          width: 18px;
          height: 18px;
          font-size: 7px;
        }
      }

      .push {
        display: none !important;
      }
    }
  }

  > strong,
  > button,
  > .logo {
    display: none;
  }
}

@include md {
  .container {
    header {
      button {
        display: none;
      }

      .profile {
        display: none;
      }
    }
  }
}

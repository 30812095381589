/**
 *  SUMMARY
 *    - GENERICS
 *    - SPECIAL_CASE
 *      - CONSULT_EDIT
 *      - MEDICAL_DATA
 *    - FORM_SWITCHER
 *    - PHONE_FIELD
 *    - V2__FORM
 *    - PATIENT DOUBLONS
 *    - DATEPICKER
 *    - MEDIC PROFILE
 *    - RESPONSIVE
 */

/*****************/
/*    GENERICS   */
/*****************/
label, input, select, p, textarea {
  @extend .text-xs;
  font-family: var(--font-family);
}

textarea {
  min-height: var(--text-4xl);
}

label {
  color: var(--color-gray-900);
  font-weight: var(--font-semibold);
}

.form-control {
  @extend .p-3;
  box-shadow: none;
}

.error-input {
  border: 1px solid var(--color-danger-500);
}

#document_visibility.input-full {
  width: 100%;
}

input,
.bootstrap-tagsinput,
select,
textarea,
canvas.signature {
  background-color: var(--color-white);
  font-weight: var(--font-medium);
  border-radius: var(--rounded);
  border: 1px solid var(--color-gray-100);
  color: var(--color-gray-900);

  @extend .outline-none;
  @extend .p-3;

  &.with-error {
    @extend .error-input;
  }
}

.with-error .bootstrap-tagsinput {
  @extend .error-input;
}


select:not([multiple]), .v2-autocomplete.with-arrow > .bootstrap-tagsinput {
  /* Hack: fix select */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAMFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABaPxwLAAAAEHRSTlMAAQoRExQ0NT+0tbe44ePrLjlV8AAAAEdJREFUCNdjYCATMEIoASDuKwCx2F8ACevrIGbtYSDBtB8ozP5HASQQDRTOPQrWABSGCjIweF+vPQo1jmk/TJCBwXMpA1UAAE7cEIzI0Wd/AAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: right 11px center;
  background-size: 11px 11px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 30px !important;
}

select:not([multiple]), .v2-autocomplete.with-arrow > .bootstrap-tagsinput {
  /* Hack: fix select */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAMFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABaPxwLAAAAEHRSTlMAAQoRExQ0NT+0tbe44ePrLjlV8AAAAEdJREFUCNdjYCATMEIoASDuKwCx2F8ACevrIGbtYSDBtB8ozP5HASQQDRTOPQrWABSGCjIweF+vPQo1jmk/TJCBwXMpA1UAAE7cEIzI0Wd/AAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: right 11px center;
  background-size: 11px 11px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 30px !important;
}

input, select:not([multiple]) {
  height: var(--input-height);
}

select:not([multiple]) {
  padding: 0 5px;
}

input, select, textarea {
  &:focus, &:active, &:hover {
    border-color: var(--color-primary-500);
    box-shadow: none;
  }
}

input[type="checkbox"], input[type="radio"] {
  height: auto;
  margin-top: 5px;
}

label.form-checkbox-label, .form-check label {
  display: flex;
  align-items: flex-start !important;
  gap: 5px;
  cursor: pointer;

  input[type="checkbox"], input[type="radio"] {
    margin: 0;
  }

  span.content_check {
    input {
      display: none;
    }

    input + span {
      display: block;
      line-height: 16px;
    }

    input + span::before {
      content: 'check_box_outline_blank';
      font-family: var(--font-family-icon);
      font-size: var(--font-size-base);
      color: var(--color-gray-900);
    }

    input:checked + span::before {
      content: 'check_box';
      color: var(--color-primary-500);
    }

    &.type_radio {
      input + span::before {
        content: 'radio_button_unchecked';
      }

      input:checked + span::before {
        content: 'radio_button_checked';
      }
    }
  }
}

select {
  &, &.form-control {
    position: relative;
    min-height: var(--input-height);
    padding: var(--p-2);
  }
}

.form_label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  label {
    flex-grow: 1;
  }

  .form_label_actions {
    flex-shrink: 0;
    margin: 0;


    &, > *:not(.dropdown-menu), .material-icons {
      @extend .text-sm;
      height: var(--font-size-base);
      display: inline-block;
    }

    .material-icons {
      color: var(--color-gray-300);
    }

    .dropdown-toggle {
      border: 0;
      background: transparent;
      padding: 0;
      display: flex;
      align-items: end;
    }

    .dropdown-menu {
      @extend .v2__dropdown_menu;
      top: 19px;
      min-width: 250px !important;
      display: none;
    }

    &.open .dropdown-menu {
      display: block;
    }
  }
}

.form-col {
  [class^="col-sm-"],
  [class^="col-md-"] {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    .suffix {
      margin-right: 15px;
      bottom: 13px;
      font-family: var(--font-family);
      @extend .text-xs;
      font-weight: var(--font-medium);
      color: var(--color-gray-900);
    }
  }

  .field-group .row,
  .form-group.row {
    flex-direction: column;
  }
}

.v2__form,
.form-col,
.form-row {
  display: flex;
  flex-direction: column;
  padding: 15px;

  .field-group .row,
  .form-group.row {
    > * {
      padding: 0;
    }
  }

  &.height_limit {
    max-height: 75vh;
    overflow: auto;
  }

  &.form_header {
    border-bottom: 1px solid var(--color-gray-50);
  }

  &.form_footer {
    border-top: 1px solid var(--color-gray-50);
  }

  .m-l--15 {
    margin-left: 0;
  }

  .invalid-feedback {
    .form-error-message {
      @extend .text-xs;
      color: var(--color-danger-500);
      font-family: var(--font-family);
      font-weight: var(--font-normal);
    }
  }

  #actions-container.actions,
  .actions {
    display: flex;
    justify-content: stretch;
    gap: 10px;
    margin-top: 20px;

    button {
      width: 100%;
      text-align: center;
      height: initial;
      display: block;
      @extend .field-large;
    }
  }

  .flex-row {
    display: flex;
    justify-content: space-around;
  }

  .field-group .row,
  .form-group.row {
    display: flex;
    margin-bottom: var(--form-block-margin-b);

    @media screen and (max-width: 772px) {
      flex-direction: column;
    }

  }

  .field-group {
    margin: 0;

    select {
      width: 100%;
    }

    .row {
      margin-bottom: var(--form-block-margin-b);
    }

    .input-full select {
      width: auto;
    }
  }

  .field-group {
    margin: 0;

    select {
      width: 100%;
    }

    .input-full select {
      width: auto;
    }
  }

  h2 {
    font-size: var(--p-4);
  }

  .col-form-label {
    margin-bottom: 8px;
  }

  .input-group {
    display: flex;
    align-items: center;

    input {
      flex-grow: 1;
    }

    .input-group-append {
      flex-shrink: 0;
    }
  }
}

.v2__form__bs {
  .v2__form_compact {
    display: flex;
    width: 100%;

    .v2__form_left {
      flex: 2 2 auto;
      max-width: 100%;

      .box {
        padding-right: 0;
        margin-right: 0;
      }

      .form-row {
        padding: 0;
        padding-right: var(--box-padding);

        .form-group {
          flex-direction: column;

          [class^="col-sm-"],
          [class^="col-md-"] {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

@include md {
  .v2__form__bs {
    .v2__form_compact {
      .v2__form_left {
        .form-row {
          .form-group {
            flex-direction: row;

            label {
              display: flex;
              margin: 0;
              align-items: center;
            }

          }

          &.not-flex {
            .form-group label {
              display: block;
              align-items: initial;
            }

          }

        }
      }
    }
  }
}

.v2__block + .form-validate {
  margin-top: 20px;
}

.form-validate {
  margin-bottom: 30px;

  .col-sm-6 {
    width: 100%;
    padding: 0;
  }

  button {
    @extend .field-large;
    width: 100%;
    display: inline-block !important;
  }

  button.btn-w-auto, a.btn-w-auto {
    width: auto;
  }
}

h1.with_action_bt {
  display: flex;

  div {
    flex: 1 1 auto;
  }

  a {
    flex: 0 0 auto;

    &:hover {
      text-decoration: none;
    }
  }

  form {
    display: contents;
  }

  .dropdown-menu {
    @extend .v2__dropdown_menu;
  }

}

#template_form .v2__block {
  overflow: visible;
}

.preview-letter {
  box-shadow: var(--shadow-box);
}

#template_variable_choices {
  min-height: 80px;
}


@include md {

  .v2__row_grid_forms {
    justify-content: space-between;
    grid-template-rows: auto;
    grid-gap: 15px;
    display: grid;
    grid-template-columns: 100%;
  }

  .v2__row_grid_forms-4 {
    @extend .v2__row_grid_forms;
    grid-template-columns: repeat(auto-fill, minmax(var(--form-field-min-width-4), 1fr));
  }

  .v2__row_grid_forms-6 {
    @extend .v2__row_grid_forms;
    grid-template-columns: repeat(auto-fill, minmax(var(--form-field-min-width-6), 1fr));
  }

  .v2__row_grid_forms-3 {
    @extend .v2__row_grid_forms;
    grid-template-columns: repeat(auto-fill, minmax(var(--form-field-min-width-4), 1fr));
  }

  .v2__row_grid_forms-2 {
    @extend .v2__row_grid_forms;
    grid-template-columns: repeat(auto-fill, minmax(var(--form-field-min-width-2), 1fr));
  }
}


@include xxl {
  .v2__row_grid_forms-3 {
    grid-template-columns: repeat(auto-fill, minmax(var(--form-field-min-width-3), 1fr));
  }
}

.v2_grid_form {
  display: flex;
  flex-direction: column;


  &.span-2 {
    grid-column: span 2;
  }

  &.span-3 {
    grid-column: span 3;
  }
}

.v2_two_column_form.col_1_2 {
  display: flex;
  gap: 15px;
  flex-direction: column;

  .box {
    margin: 0;
  }

  .v2_column_left {
    flex-grow: 0;
    flex-shrink: 1;
  }

  .v2_column_right {
    flex: 1 1 auto;
  }


}

@include xl {
  .v2_two_column_form.col_1_2 {
    flex-direction: row;

    .v2_column_left {
      max-width: 500px;
      min-width: 420px;
    }
  }
}

/*******************/
/*   SPECIAL_CASE  */
/*******************/
.compact-form {
  .v2__form,
  .form-row,
  .form-col {
    input:not([type='textarea']) {
      &.form-control, &.nice-input {
        height: var(--input-height);
        margin-bottom: 0;
      }
    }

    .col-form-label {
      @extend .text-xs;
      min-height: auto;
    }
  }
}

.v2__form,
.form-row,
.form-col {
  .removable-blocks {
    .multifieldElement {
      position: relative;

      .remove-link {
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .signature {
    width: 302px;
    height: 152px;

    & + button {
      width: 302px;
    }

  }

  .multifieldElement {
    border: 0;
    padding-left: 0;

    .form-group {
      margin-bottom: 0;
    }

    .col-form-label {
      padding-left: 0;
    }

    .remove-link {
      @extend .remove-link;
    }
  }

  .form-collection-list {
    .form-group {
      margin-bottom: var(--form-block-margin-b);
    }
  }

  .content-profil-signature {
    .reinit {
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .dateAndHour, .full-date, .year-only {
    .form-inline, .dateOfBirthSelect {
      border: 1px solid var(--color-gray-100);
      border-radius: var(--rounded);
      height: var(--input-height);
      overflow: hidden;

      select {
        border: 0;
        box-shadow: none;
        margin: 0 5px 0 0;
        min-width: 65px;
        background-color: transparent;
        width: auto;
        display: inline-block;
        padding-left: 16px;
      }

      select ~ select {
        margin-left: 5px;
      }

      select:last-child {
        margin-right: 0;
      }
    }
  }

  .v2-autocomplete {
    .bootstrap-tagsinput {
      min-height: var(--input-height);
      padding: 0 10px;
      margin-bottom: 5px;

      input {
        border: 0;
        height: 38px;
        width: auto;
      }
    }

    .input-loader {
      bottom: 11px;
    }

    &.with-help-txt .input-loader {
      bottom: auto;
      top: 6px;
    }

    &.params_admin {
      .tag {
        margin-top: 8px;
      }
    }
  }

  .contentSaveMsg {
    display: block;
  }

  .profilSubmitChangePwd {
    display: flex;
  }

  .custom-file-input,
  input[type="file"] {
    padding: 9px !important;
  }


  .profilPasswordForm .fa {
    line-height: calc(var(--input-height) / 2);
  }

  .delete-img {
    background: transparent;
    color: var(--color-danger-500);

    i {
      @extend .text-sm;
    }
  }

  // transactions -> filter
  .with-help-txt p.small {
    margin-top: 5px;
  }

}

.form-col.manageIban {
  .col-md-6, .col-sm-12 {
    padding-left: 0;
  }

  #idCard {
    @extend .text-xs;
  }
}

.v2__block + .form-validate {
  margin-top: 20px;
}

.form-validate {
  margin-bottom: 30px;

  .col-sm-6, .col-sm-12 {
    width: 100%;
    padding: 0;
  }

  button, input[type="submit"] {
    @extend .field-large;
    width: 100%;
    display: inline-block !important;
  }
}

.password-checked {
  padding-top: 5px;
  font-weight: bold;
}

.edit-profile-action, .multiple_button_form {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: stretch;
  gap: 5px;
}

.notification_settings .row label {
  font-weight: normal;
}

.notification_settings_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;

  > .box {
    width: 100%;
    margin-bottom: 0;

    .visibility-hidden {
      height: 1px;
    }
  }
}

.filter-form {
  overflow: visible !important;

  .filterButton {
    margin-right: 0;
  }
}

@include md {
  .notification_settings_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    > .box {
      max-width: 32%;
      min-width: none;

      .visibility-hidden {
        height: 1px;
      }
    }
  }
}

@include xl {
  .notification_settings_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    > .box {
      max-width: 20%;
      min-width: 290px;

      .visibility-hidden {
        height: 1px;
      }
    }
  }
}

/***************************/
/*     CONSULT_EDIT        */
/***************************/
.consult_edit.questionnaire {
  label.required {
    .required_symbol {
      display: inline-block !important;
      color: var(--color-danger-500);
    }
  }

  .form_section {
    margin-bottom: var(--font-size-base) !important;

    h2 {
      @extend .text-sm;
      padding: 10px;
    }
  }

  .question_container {
    .form-group .row .col-sm-6 {
      padding-left: 0;
    }

    .see-more {
      padding-left: 0;
      padding-right: 0;
      right: 0px;
    }
  }
}

/***************************/
/*     MEDICAL_DATA        */
/***************************/
.medical_data .question_container {
  label.required {
    .required_symbol {
      display: inline-block !important;
      color: var(--color-danger-500);
    }

    &:after {
      display: none;
    }
  }

  .form-group .row .col-sm-6 {
    padding-left: 0;
    padding-right: 0;
  }
}

/*****************/
/* FORM_SWITCHER */
/*****************/
.form-switcher {
  border: 1px solid var(--color-gray-100);
  border-radius: var(--rounded);
  height: 32px;

  display: flex;
  justify-items: stretch;
  align-items: stretch;
  overflow: hidden;


  label {
    margin: 0;

    span {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--color-gray-300);
      background-color: var(--color-white);
      @extend .text-2xl;

      &:hover {
        background-color: var(--color-primary-100);
      }
    }

    input:checked + span {
      background-color: var(--color-gray-900);
      color: var(--color-white);
    }
  }
}

/*****************/
/*  PHONE_FIELD  */
/*****************/
.iti {
  display: block;

  input {
    padding-left: 47px;
  }
}

/*****************/
/*   V2__FORM    */
/*****************/
.v2__form {
  .form-group {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }

  .v2__form_row {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    margin-bottom: 15px;
    gap: 15px;

    .form-group {
      margin-bottom: 0;
      width: 100%;
    }

    p.small {
      margin-bottom: var(--p-1);
    }
  }

  .actions {
    margin-top: 0;
    justify-content: space-between;
    align-items: flex-end;

    button.btn {
      @extend .p-2;
      height: var(--input-height);
      min-height: var(--input-height);
      margin: 0 !important;
    }
  }

  .v2-autocomplete,
  .bootstrap-tagsinput {
    margin: 0;
  }
}


.form_small_info, .help {
  @extend .text-xs;
  color: var(--color-gray-600);
  font-style: normal;
  position: relative;
  margin-bottom: 5px;
}

select[multiple] {
  margin-bottom: 0 !important;
}

.v2_connect_card {
  .actions {
    flex-direction: row;
    align-items: end;
    justify-content: end;
  }
}

/***************************/
/*     PATIENT DOUBLONS    */
/***************************/
.manage_duplicate {
  .duplicate_container {
    padding-left: 0;

    button {
      margin-right: 0;
    }
  }

  .manual_form {
    padding-right: 0;

    .description {
      padding: 15px;
      margin: 0;
    }

    .row:last-child {
      margin-top: 5px;
      margin-bottom: 0;

      button {
        margin: 0;
      }
    }
  }
}

/*****************/
/*  MEDIC PROFILE   */
/*****************/

.v2_grid_form {

  .form-group {
    width: 100%;

    p.form-group {
      margin: 0;
    }

    input[type="file"], .form-inline {
      width: 100%;
    }

    input[type="file"] {
      text-align: center;
      vertical-align: middle;
      padding-top: 9px;
    }

    .dateOfBirthSelect select {
      width: 29%;
      display: inline-block;
      margin: 0 2% 0 2%;

      &:nth-child(2) {
        margin: 0 2% 0 0;
      }

      &:last-child {
        margin: 0 0 0 2%;
      }

    }

    #app_medic_step_one_calendarMinHour, #app_medic_step_one_calendarMaxHour,
    #app_entity_nurseProfile_calendarMinHour, #app_entity_nurseProfile_calendarMaxHour,
    {
      select {
        width: 47%;

        // There is a stupid sr-only elem
        &:nth-child(2) {
          margin-right: 1%;
        }

        &:last-child {
          margin-left: 1%;
        }
      }
    }

  }

  .row {
    display: flex;
    flex-direction: column;
  }

  *[class^="col"], label {
    padding: 0;
    width: 100%;
  }

}

.form-col.no-padding {
  padding: 0 !important;
}

.multifieldElement {

  border: none;
  border-bottom: 1px solid var(--color-gray-50);

  .remove-link {
    cursor: pointer;
  }

  div[id^="app_medic_step_one_user_phones"], div[id^="app_entity_nurseProfile_user_phones"] {
    display: flex;
    flex-direction: column;
    width: 100%;

    .form-group {
      display: flex;
      flex-direction: column;

      label {
        width: 100%;
      }

      input[type="tel"] {
        width: 100%;
      }

      .col-sm-6 {
        padding: 0;
        width: auto;
      }

    }
  }
}


/*****************/
/*  DATEPICKER   */
/*****************/
.ui-datepicker {
  @extend .v2__box_shadow;

  .ui-datepicker-header {
    .ui-datepicker-next, .ui-datepicker-prev {
      cursor: pointer;
      color: var(--color-gray-700);

      &:hover {
        color: var(--color-primary-500);
      }
    }

    .ui-datepicker-next:before {
      @extend .material-icons;
      content: 'chevron_right';
    }

    .ui-datepicker-prev:before {
      @extend .material-icons;
      content: 'chevron_left';
    }

    .ui-datepicker-month {
      @extend .text-sm;
      font-weight: var(--font-semibold);
      font-family: var(--font-family);
    }

    .ui-datepicker-year {
      height: var(--input-height-xs);
      min-height: var(--input-height-xs);
      padding: var(--p-2);
    }
  }

  .ui-datepicker-calendar {
    th {
      @extend .text-sm;
      font-weight: var(--font-semibold);
    }

    td {
      a {
        @extend .text-sm;
        color: var(--color-gray-700);

        &:hover {
          text-decoration: none;
        }
      }

      &.ui-datepicker-current-day {
        background-color: var(--color-primary-500);

        &:hover {
          background-color: var(--color-primary-400);
        }

        a {
          color: var(--color-white);
        }
      }
    }
  }
}

.filters_form {
  display: flex;
  gap: 10px;
  flex-direction: column;

  .filters_fields {
    flex: 2 2 auto;
    display: inline-flex;
    gap: 10px;
    flex-wrap: wrap;

    > div {
      flex: 1 1 auto;
    }
  }

  > div:last-child {
    flex: 1 1 auto;
    margin-bottom: 5px;
    align-self: end;
  }
}

.two_factor_container {
  display: flex;
  gap: 10px;
  flex-direction: column;

  > .box {
    flex: 1 1 auto;
    margin-bottom: 0;

    > .form-col {
      height: 100%;

      > .row {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        justify-content: space-between;

        div:first-child {
          flex: 1 1 auto;
        }

        div:last-child {
          align-self: end;
          width: 100%;
        }
      }
    }
  }

  .green--dark {
    font-weight: var(--font-semibold);
    color: var(--color-success-500);
  }

  // Ouais ca a pa de sens je sais !
  .green {
    font-weight: var(--font-semibold);
    color: var(--color-warning-500);
  }

  .orange {
    font-weight: var(--font-semibold);
    color: var(--color-danger-500);
  }
}

.radioLabel {
  display: inline-flex;
  align-items: end;
  gap: 5px;
}

#create-card-form {
  .form-validate {
    margin-right: 15px;
  }
}

@include lg {
  .filters_form {
    flex-direction: row;

    .filters_fields {
      > div {
        flex: 0 0 200px;

        &.growing {
          flex: 1 1 40%;
          max-width: 40%;
        }

      }
    }
  }
}

/*****************/
/*  RESPONSIVE   */
/*****************/

@include lg {
  .v2__form {
    .v2__form_row {
      flex-direction: row;
      align-items: center;

      .form-group {
        width: 49%;
      }
    }
  }

  .two_factor_container {
    flex-direction: row;

    > .box {
      flex: 1 1 0px;
    }
  }
}

form[name=app_medical_background] {
  label[for*=Alive] + .col-sm-6 .form-check,
  label[for*=TakenCareOf] + .col-sm-6 .form-check {
    display: flex;
    flex-direction: row;

    .form-check-label {
      display: block;
      padding: 4px;
    }
  }

  .familyMemberTypeBlock {
    display: flex;
    flex-wrap: wrap;

    .form-group, .full-date {
      flex: 1 1 100%;

      label {
        width: 100%;
      }
    }

    .full-date {
      flex: 0 0 auto;
    }
  }
}

fieldset.form-group {
  margin-bottom: 0;

  .row {
    label.form-checkbox-label {
      padding-top: 0;
    }
  }
}


@include md {
  .form-col {
    .content-profil-signature {
      display: flex;
      flex-direction: column;

    }
  }


  .multifieldElement {
    div[id^="app_medic_step_one_user_phones"], div[id^="app_entity_nurseProfile_user_phones"] {
      flex-direction: row;
      width: auto;

      .form-group {
        flex-direction: row;

        label {
          width: auto;
        }

        .col-sm-6 {
          padding: 0 0 0 15px;
        }
      }
    }
  }

  form[name=app_medical_background] {
    .familyMemberTypeBlock {
      flex-wrap: nowrap;
      gap: 10px;

      .form-group, .full-date {
        flex: 1 1 auto;
        width: 100%;
      }

      .full-date {
        flex: 1 1 auto;
        width: 100%;
      }
    }

    .family-member-date-of-death {
      display: flex;
      flex: 1 1 auto;
      width: 100%;
    }

  }
}


.v2_form_admin_update_profil {
  display: flex;
  gap: 10px;
  margin: 15px 0;
  flex-direction: column;

  button {
    float: right;
  }

  .box {
    margin: 0;
  }

  .v2_form_admin_update_profil_left {
    flex: 1 1 auto;

    .small {
      margin-bottom: 5px;
    }

    .bootstrap-tagsinput {
      min-height: 40px;
      padding: 3px 5px;

      input {
        height: 22px;
        border: 0;
        margin: 0;
        padding: 0;
      }
    }

    .force_small_height_space {
      .form-group.row {
        margin: 0;

        label {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .v2_form_admin_update_profil_right {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .stripe_infos_container {
      font-size: var(--font-size-sm);

      > div {
        display: flex;
        margin-bottom: 5px;

        strong {
          font-weight: var(--font-semibold);
          font-size: var(--font-size-sm);
          color: var(--color-gray-900);
          min-width: 110px;
        }
      }

    }

    .show_stripe_profil {
      margin-bottom: 15px;
      text-align: right;
      margin-right: 15px;

      a {
        width: auto;
      }
    }
  }
}


@include xl {
  .v2_form_admin_update_profil {
    flex-direction: row;

    .v2_form_admin_update_profil_left {
      flex: 1 1 auto;
    }

    .v2_form_admin_update_profil_right {
      flex: 0 0 auto;

      max-width: 450px;
    }
  }
}

.v2_manage_page {
  .v2_column_right {
    ul > li.multifieldElement, .form-group > li.multifieldElement {
      margin-top: 25px;
      background-color: var(--color-primary-50);
      position: relative;

      &:first-child {
        border: 0;
        margin-top: 0;
      }
    }

    .form-group li.multifieldElement.form-group {
      padding: 15px;
    }

    li {
      button.add_link {
        @extend .v2__button_secondary;
      }

      button.remove-link {
        background-color: transparent;
        border: 0;
        padding: 0 !important;
        margin: 0;
        position: absolute;
        top: 0px;
        right: 10px;
        z-index: 10;
      }
    }
  }
}

.v2_paginate {
  text-align: right;

  input, select {
    margin: 0 5px;
  }
}

.admin_share_list .filters_form .filters_fields > div {
  flex: 0 0 300px;
}


.v2_institution_container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  .box {
    margin: 0;
    min-width: 300px;
    max-width: 350px;
    padding: 0;
    padding-bottom: 15px;

    .image_container {
      background-size: cover;
      height: 80px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: var(--color-gray-900);
    }

    .text_container {
      padding: 15px;
      text-align: center;
      font-weight: var(--font-semibold);
      color: var(--color-gray-900);
      @extend .text-xl;
    }
  }
}

.collection-form-row {
  margin: 15px 0;
  padding: 15px;
  // If you are wondering why we use a color here and only here ? Ask Luv Valecha
  //  background: var(--color-gray-50);
  background: var(--color-gray-luv);
  margin: 15px -15px;

  .actions {
    position: absolute;
    top: 5px;
    margin: 0;
    right: 5px;

    .remove-link {
      margin: 0;
    }
  }
}

.collection-form-rows {
  min-height: 50px;
}


.form-flex-row {
  display: flex;
  gap: 15px;
}


.multi-field {
  flex-direction: column;
  display: flex;
  gap: 15px;

  button {
    display: flex;
    width: fit-content;
    align-self: end;
  }

}

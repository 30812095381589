/**
 *  SUMMARY
 *    - MIXIN
 *    - GLOBAL
 *      - BLOCKS_ICONS
 *    - DASHBOARD_HOME
 *    - DASHBOARD
 *      - TASK_LIST
 *    - BLOCKS
 *      - EVENT
 *      - CONSULT_EDIT
 *      - SELF_EVAL
 *      - TRANSACTIONS
 *    - GRID_EDITOR
 **/

/***************************/
/*          MIXIN          */
/***************************/
@mixin block_li {
  .v2__block {
    .banner.dashboard {
      ul li {
        @content;
      }
    }
  }
}

/***************************/
/*         GLOBAL          */
/***************************/
.v2__box_dashboard_size {
  max-height: 500px;
  min-height: 250px;
  height: 50vh;
}

.v2__row_grid_blocks {

  display: flex;
  flex-direction: column;

  @include sm {
    display: grid;
  }
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;

  font-family: var(--font-family) !important;

  .row, .col-sm-6, .col-sm-12 {
    margin: 0;
    padding: 0;
    width: auto;
  }
}

.v2__block {
  @extend .v2__box_shadow;
  display: flex;
  flex-direction: column;

  &.box {
    padding: 0;
  }

  &.clearfix {
    &:before, &:after {
      content: none;
    }
  }

  h2, h4 {
    @extend .text-base;
    color: var(--color-gray-900);
    margin-bottom: 0;
    padding: 14px;

    &.with_icon {
      display: flex;
      align-items: center;

      .h_content {
        flex-grow: 1;
      }

      .material-icons {
        flex-shrink: 0;
        color: var(--color-success-500);
      }
    }

    &:first-child {
      border-bottom: 1px solid var(--color-gray-50);
    }

    .box {
      border: 0;
      padding: 0;
      margin: 0;
    }

    &, strong {
      font-weight: var(--font-semibold);
    }
  }

  .v2__block_header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-50);
    height: var(--box-header-height);
    flex-shrink: 0;
    padding: 0 14px;

    h2, h4 {
      flex-grow: 1;
      border: 0;
      padding: 0;
    }

    .actions {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .toggle_button {
        display: inline-block;
      }

      .material-icons {
        @extend .text-lg;
      }

      .close-tab, .material-icons {
        color: var(--color-gray-500);

        &:hover {
          color: var(--color-gray-700);
        }
      }
    }
  }

  label.v2__block_header {
    cursor: pointer;
    margin: 0;
  }

  .v2__block_header.popin-header {
    h2, h4 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .box:not(.file-item) {
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  /***************************/
  /*        DASHBOARD        */
  /***************************/
  .banner.dashboard {
    margin: 0;
    padding: 0;
    background-color: var(--color-white);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @extend .v2__box_dashboard_size;

    ul {
      overflow: auto;
      flex-grow: 1;
    }

    a:not(.btn) {
      &, &:hover {
        text-decoration: none;
      }
    }

    &, &.blue, &.red {
      border: 0;
    }

    &.empty {
      min-height: 150px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      p {
        flex-grow: 1;
        padding: 20px 15px;
      }
    }

    .footer-link {
      flex-shrink: 0;
      border-top: 1px solid var(--color-gray-50);
      text-align: center;
      height: 45px;
      display: flex;
      align-items: center;

      a {
        width: 100%;
        text-align: center;
        padding: 12px;
        font-weight: var(--font-semibold) !important;

        &:hover {
          text-decoration: underline;
          color: var(--color-primary-500);
        }
      }
    }

    .footer-button {
      flex-shrink: 0;
      display: flex;
      justify-content: end;
      padding: 10px;

      a, .btn {
        width: auto;
      }
    }

    ul {
      overflow: auto;
    }

    .title {
      @extend .text-sm;
      font-weight: var(--font-semibold);
      color: var(--color-gray-900);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .subtitle {
      @extend .text-xs;
      color: var(--color-gray-400);
    }
  }
}


/********************/
/*   BLOCKS_ICONS   */
/********************/
.iconColorDanger {
  color: var(--color-danger-500);
}

.iconColorSuccess {
  color: var(--color-success-500);
}

.iconColorWarning {
  color: var(--color-warning-500);
}

.iconColorYellow {
  color: var(--color-yellow-500);
}

.iconColorPrimary {
  color: var(--color-primary-500);
}


.patient-tabs-container .v2__block .v2__block_header,
.patient-tabs-container .v2__patient-edit,
.v2__block .v2__block_header.with_icon {
  h4 {
    display: flex;
    align-items: center;
    gap: 5px;
    height: var(--box-header-height);
    justify-content: space-between;
    padding-right: 5px;

    .h_title {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }

    .h_content {
      flex-grow: 1;
    }

    .h_icon {
      height: 24px;
      overflow: hidden;

      &, &:before {
        @extend .material-icons;
        @extend .text-lg;
        @extend .iconColorPrimary;
        flex-shrink: 0;
        display: flex;
        align-items: center;
      }

      &.primary {
        @extend .iconColorPrimary;
      }

      &.yellow {
        @extend .iconColorYellow;
      }

      &.success {
        @extend .iconColorSuccess;
      }

      &.warning {
        @extend .iconColorDanger;
      }

      &.danger {
        @extend .iconColorDanger;
      }
    }
  }
}

/***************************/
/*    DASHBOARD_HOME       */
/***************************/
.dashboard-home {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding-bottom: 20px;

  .v2__block {
    @extend .v2__box_dashboard_size;

    .v2__list_dot {
      max-height: max-content;
    }
  }
}

@include xl {
  .dashboard-home {
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr);
  }
}

/***************************/
/*        TASK_LIST        */
/***************************/
.banner.dashboard {
  &.task-list .task-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: 1px solid var(--color-gray-50);

    &:last-child {
      border-bottom: 0;
    }

    .subtitle {
      margin: 5px;
    }
  }

  .v2__list_dot .v2__list_item,
  &.task-list .task-container {
    .t-loader {
      text-align: center;
    }

    .choices {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;

      > a {
        height: var(--input-height-xs);
      }
    }
  }
}

/***************************/
/*        BLOCKS         */
/***************************/
@include block_li {
  @extend .text-base;
  font-weight: var(--font-semibold);
  font-family: var(--font-family);
  padding: 20px 24px;
  color: var(--color-gray-900);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-50);
  overflow: hidden;

  &:hover {
    background-color: var(--color-primary-50);
  }

  &.with-link {
    padding: 0;
    overflow: hidden;
  }
}

/***************************/
/*          EVENT          */
/***************************/
.banner.dashboard {
  .print-events {
    flex-grow: 1;
    padding: 5px 12px;
    @extend .flex-col;
    gap: 5px;

    .actions {
      display: flex;
      justify-content: end;

      .close-box {
        @extend .text-sm;
        cursor: pointer;
        color: var(--color-gray-700);
      }
    }

    .form-group {
      margin-bottom: 5px;
      padding: 0 !important;
    }

    input {
      @extend .p-2;
    }

    button {
      margin-left: 12px;
      align-self: flex-end;
      margin: 0;
    }
  }
}

@include block_li {
  &.event {
    display: flex;
    padding: 10px;

    > div:last-child {
      display: flex;
      flex-direction: column;
    }

    .event-content {
      > div {
        &.notes, &.participants {
          margin-top: 5px;
        }
      }
    }

    .date {
      @extend .text-sm;
      margin-left: 10px;
      margin-right: 10px;
    }

    .title {
      display: flex;
      flex-direction: column;
      color: var(--color-gray-900);

      .name {
        @extend .text-sm;
        font-weight: var(--font-semibold);
      }

      .detail {
        @extend .text-xs;
        font-weight: var(--font-normal);
      }
    }
  }
}

.dashboard_event {
  .v2__block_header {
    .actions .material-icons {
      @extend .text-base;
      color: var(--color-primary-500);

      &:hover {
        color: var(--color-primary-400);
      }
    }
  }

  .banner.dashboard .print-events {
    display: none;
  }

  .toggle_input:checked ~ {
    label {
      .toggle_button_close {
        display: block;
      }

      .toggle_button_open {
        display: none;
      }
    }

    .dashboard.banner {
      .print-events {
        display: flex;
      }

      .v2__list_dot, &.empty p {
        display: none;
      }
    }
  }
}

/***************************/
/*     CONSULT_EDIT        */
/***************************/
.v2__block.consult_edit {
  .box {
    padding: 5px 0;

    h4 {
      padding: 0;
    }
  }
}

/***************************/
/*      TRANSACTIONS       */
/***************************/
.doctor-notifications {
  .v2__block ul {
    li {
      @extend .text-sm;
      color: var(--color-gray-900);

      strong {
        font-weight: var(--font-semibold);
      }
    }
  }
}

/***************************/
/*       GRID_EDITOR       */
/***************************/
.grid_editor {
  display: flex;

  .editor {
    flex-grow: 1;
  }

  .options {
    flex-shrink: 0;
  }
}


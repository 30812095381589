/**
 * SUMMARY
 *   - HEADER
 *   - TABS
 *   - BLOCKS_CONTAINER
 *   - BLOCKS_ICONS
 *   - PATIENT_EDIT
 *     - CONSULTATIONS
 *   - BLOCKS_GENERAL
 *     - PP_ACTIONS
 *     - NOTE
 *     - MEDIC_TEAM
 *   - LIST_ITEMS
 *   - SUPERVISION_LIST
 */

/**************/
/*   HEADER   */
/**************/
.profile-header {
  display: flex;
  align-items: center;

  .profile-img {
    @extend .avatar_xxl

  }

  .v2_rounded_avatar, .profile-img {
    margin: 20px 0 20px 0;
    flex-shrink: 0;
  }

  .profile-header__data {
    flex-grow: 1;
    font-family: var(--font-family);
    font-weight: var(--font-normal);
    color: var(--color-gray-500);
    @extend .text-xs;
    display: flex;
    flex-flow: row nowrap;
    margin-left: 10px;
    gap: 10px;
    position: relative;
    margin-top: 10px;
    justify-content: space-between;

    .main-synopsis {

      @media (max-width: 767px) {
        flex-wrap: wrap;
        overflow: scroll;
        max-height: 300px;
      }

      span.ia-suggestion {
        color: var(--color-ai-900);
        font-weight: bold;
        position: relative;
        margin-top: 2px;
        margin-bottom: 2px;

        .ai-actions {
          display: none;
        }

        &:hover {
          .ai-actions {
            display: block;
            position: absolute;
            bottom: -8px;
            z-index: 10;
            background: var(--color-white);
            border: 1px solid var(--color-gray-100);
            padding: 2px 5px 0 5px;
            right: 0;
            box-shadow: 0 0 2px;
            border-radius: 6px;

            button {
              background: none;
              border: none;
              padding: 2px;
              i {
                font-size: 12px;
              }
            }
          }
        }
      }

      display: flex;
      flex-flow: row nowrap;
      margin-left: 10px;
      gap: 10px;
      width: 100%;
      position: relative;

      .semibold {
        font-weight: var(--font-semibold);
      }

      > div:not(.dropdown-menu) {
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        min-width: 15%;
        flex-grow: 1;
        overflow: hidden;

        &:hover {
          overflow: visible;
        }

        > div {
          margin-bottom: 5px;
        }

        &.synopsis-text {
          flex-shrink: 1;

          .synopsis {
            @include sm {
              max-height: 170px;
              overflow: scroll;
            }
          }

        }

      }
    }

    .main {
      color: var(--color-gray-900);
    }

    .title {
      font-weight: var(--font-bold);
    }
  }

  .dropdown_patient_actions {
    width: 32px !important;
    display: block;
    min-width: 32px !important;

    .dropdown-toggle {
      @extend .v2__drodown_toggle;
    }

    .dropdown-menu {
      @extend .v2__dropdown_menu;
      top: 35px;
      min-width: 250px !important;
    }
  }
}

/**************/
/*    TABS    */
/**************/

#section-consultations {
  background: transparent;
}

.v2__content-tabs {
  border-bottom: 1px solid var(--color-gray-50);
  display: flex;
  min-height: 60px;
  position: relative;

  ul {
    scroll-behavior: smooth;
  }

  .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
    cursor: pointer;
    background: var(--color-gray-50);

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

  }

  .actions {
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &.full {
      justify-content: space-between;
    }

    .btn {
      margin: 0;
      padding: 0 0.75rem !important;

      display: flex;
      gap: 5px;

      .material-icons {
        @extend .text-base;
      }

      span {
        @extend .text-sm;
      }
    }


    .pull-right {
      float: initial !important;
    }
  }

  .nav-tabs {
    &:before, &:after {
      content: none;
      display: none;
    }

    .nav-item {
      margin: 0;

      .nav-link {
        @extend .text-base;
        @extend .transition-colors;
        font-weight: var(--font-semibold) !important;
        color: var(--color-gray-900);
        border-radius: 0 !important;
        background-color: transparent;
        margin: 0;
        border: 0;
        position: relative;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        border-bottom-color: transparent;
        padding: 5px 15px 16px 15px;
        white-space: nowrap;

        &:hover, &.active {
          text-decoration: none;
          color: var(--color-gray-900);
          border-bottom-color: var(--color-gray-900);
        }

        .new {
          position: absolute;
          top: -5px;
          right: -6px;
          font-size: 10px;
          color: #fff;
          background: var(--color-warning-500);
          padding: 2px;
          line-height: initial;
          transform: rotate(19deg);
        }

      }
    }
  }
}

/************************/
/*   BLOCKS_CONTAINER   */
/************************/
$blockMinWidth: 300px;
.patient-tabs-container {
  display: flex;
  margin-bottom: 15px;
  gap: 15px;
  min-height: 400px;
  position: relative;

  .tab, .tab-small {
    padding: 0;


    &.show {
      display: flex !important;
      flex-grow: 1;

      .v2__block.default-block {
        .v2__block_header .actions {
          display: none;
        }
      }

    }
  }

  .v2__row_grid_blocks {
    margin-top: 0;
    flex-grow: 1;
    grid-template-columns: repeat(auto-fill, minmax($blockMinWidth, 1fr));
    align-self: start;
  }


  .span-1 {
    grid-column: span 1;
  }

  .span-2 {
    grid-column: span 2;
  }

  .span-3 {
    grid-column: span 3;
  }

  .span-4 {
    grid-column: span 4;
  }

  .span-5 {
    grid-column: span 5;
  }

  .span-6 {
    grid-column: span 6;
  }

  .main-tab {
    position: relative;

    &, &.show {
      flex-grow: 1;
      width: 100%;

      &.form-editing .v2__row_grid_blocks {
        display: block;
      }
    }

    &.hidden {
      flex-grow: 0;
      width: auto;
    }

    &.hidden {
      flex-grow: initial;

      .v2__row_grid_blocks {
        @include sm {
          width: $blockMinWidth;
        }
        flex-grow: initial;
        flex-shrink: 0;
      }
    }
  }

  .v2__block {
    min-height: 300px;
  }
}

@include lg {
  .patient-tabs-container {
    .main-tab {
      &, &.show {
        display: flex !important;

        &.form-editing .v2__row_grid_blocks {
          display: flex;
        }
      }

      .v2__block .v2__block_header {
        cursor: default;

        .actions {
          display: none;
        }
      }

      &.hidden {
        .v2__row_grid_blocks {
          display: flex;
          flex-direction: column;
        }

        .default-block .text-content {
          min-height: 200px;
        }


        .v2__block {
          min-height: auto;

          .v2__block_header {
            cursor: pointer;

            .actions {
              display: block;
            }
          }

          .toggle_input:checked ~ .v2__block_header {
            border-color: transparent;

            .toggle_button_open {
              display: none;
            }

            .toggle_button_close {
              display: block;
            }

            & + div {
              display: none;
            }

          }
        }
      }
    }
  }
}

/********************/
/*   PATIENT_EDIT   */
/********************/
.v2__patient-edit {
  &.text-content h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .actions {
      button {
        height: 32px;
        padding: 0 var(--p-2) !important;

        span {
          @extend .text-sm;
        }
      }
    }
  }

  .v2__patient-edit__filter {
    padding: 15px;
    border-bottom: 1px solid var(--color-gray-50);

    &, form {
      display: flex;
      align-items: center;
      gap: 5px;

      select, input {
        min-height: 32px;
      }
    }

    .form-group {
      margin: 0;
    }


    .as-filter {
      position: relative;

      &.filter-active {
        border: 1px solid var(--color-gray-900-opactity-08);

        span {
          display: block;
          cursor: pointer;
          position: absolute;
          top: 11px;
          right: 8px;
          z-index: 20;
          font-size: 17px;
          background: #fff;
          color: var(--color-gray-900-opactity-08);
        }
      }
    }


    .form-label, .form-control {
      @extend .text-xs;
      padding: 0 var(--p-2);
    }
  }

  /********************/
  /*  CONSULTATIONS   */
  /********************/

  .consultations.supervision-menu {
    overflow-y: scroll;
    max-height: 100vh;
  }

  .consultations-container {
    position: relative;
    min-height: 200px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .consultations {
      background-color: transparent;
      border: 0;
      box-shadow: none;


      li.data {
        margin-bottom: 15px;
        padding: 5px;
        background-color: var(--color-white);
        border-radius: var(--rounded-lg);
        position: relative;

        .supervision-item {
          border-bottom: 0;
        }

        .supervision_item_header {
          padding-bottom: 0 !important;
        }

        .timeline-heading {
          display: flex;
          flex-direction: column;
        }

        .supervision_item_banner_actions {
          .remove {
            border: 1px solid var(--color-gray-50);
            color: var(--color-gray-400);
            border-radius: var(--rounded-md);
            text-align: center;
            padding: 5px 10px !important;
          }
        }

        .title {
          font-size: var(--font-size-lg);
        }

        .timeline-body {
          border-top: 1px solid var(--color-gray-50);
          padding-top: 20px;

          &.noButton {
            border: 0;
            padding: 0;

            .note {
              display: none;
            }
          }
        }
      }

      .data {
        br {
          display: none;
        }

        .content.view-mode {
          br {
            display: block;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--color-gray-50);
        }


        h4 {
          border-bottom: 0;
          padding: 0;
          margin-bottom: 4px;

          strong {
            @extend .text-base;
            font-family: var(--font-family);
            font-weight: var(--font-semibold);
          }

          &.warning strong {
            color: var(--color-warning-500);
          }
        }

        p strong small {
          @extend .text-xs;
          font-weight: var(--font-normal);
          color: var(--color-gray-400);
        }

        .supervision_item_banner_actions {
          min-width: auto;
        }

        .timeline-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          height: auto;
          gap: 15px;
          justify-content: space-between;
          width: 100%;

          .title-container {
            display: flex;
            gap: 15px;
            align-items: center;
          }

          .cancel-edit {
            position: absolute;
            top: -25px;
            right: 0;
          }

          .remove {
            align-self: flex-end;
          }

          .cancel-edit, .remove {
            color: var(--color-danger-300);
            align-self: flex-end;

            &:hover {
              color: var(--color-danger-500);
            }
          }
        }

        .timeline-badge {
          display: none;
        }

        .timeline-body {
          a.btn {
            margin: 2.5px;


            &.new {
              @extend .btn-primary;
              position: relative;

              &:after {
                content: "Nouveau";
                position: absolute;
                display: block;
                bottom: -5px;
                font-size: 8px;
                font-weight: bold;
                background: var(--color-warning-500);
                height: 10px;
                color: #fff !important;
                z-index: 9;
                overflow: visible;
                width: auto;
                line-height: 7px;
                padding: 2px;
                text-align: center;
                right: -15px;
                transform: rotate(340deg);
              }

            }

          }

          a.btn:first-child {
            margin-left: 0;
          }

          .content_link {
            margin-bottom: 15px;
          }

          .note {
            p {
              @extend .text-sm;
              color: var(--color-gray-900);
            }

            input, textarea {
              @extend .text-sm;
            }

            .titleNote {
              display: block;
              margin: 0 0 10px;
              @extend .text-lg;
              color: var(--color-gray-600);
              font-weight: var(--font-semibold);
            }


            .edit-mode {
              margin-bottom: 15px;
              display: flex;
              justify-content: space-between;

              .note-inputs {
                margin: 0;
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
              }
            }

            .cancel-edit {
              margin: 0 15px 0 0;
            }

            .content_del_button {
              position: relative;
              margin: 0;
              display: flex;
              align-items: end;
              flex-direction: column;
            }

            .note_delete {
              .material-icons {
                color: var(--color-gray-400);
                font-size: var(--font-size-xl);
              }

              &:hover {
                .material-icons {
                  color: var(--color-danger-500);
                }
              }
            }
          }
        }

        .btn-group.actions {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          border-top: 1px solid var(--color-gray-50);
          padding-top: 20px;
          gap: 5px;

          .consultation-tag {
            flex: 1 1 415px;
            max-width: 415px;


            .file-item {
              border: 0;
              padding: 0;
              display: inline-flex;
              gap: 10px;
              border-radius: var(--rounded-md);

              a.file-item__link {
                @extend .text-sm;
                border: 1px solid var(--color-gray-50);
                color: var(--color-gray-900);
                display: flex;
                gap: 10px;
                align-items: center;
                padding: 7px 15px;
                border-radius: var(--rounded-md);
                width: 283px;

                &:hover {
                  text-decoration: none;
                  background-color: var(--color-primary-50);
                }

                .material-icons {
                  color: var(--color-primary-500);
                  font-size: var(--font-size-sm);
                }

                .name {
                  @extend .v2__ellipsis;
                }
              }

              .file-item__actions {
                a {
                  .material-icons {
                    color: var(--color-gray-300);
                    font-size: var(--font-size-base);
                  }

                  &:hover {
                    .material-icons {
                      color: var(--color-gray-900);
                    }
                  }
                }
              }

              &:hover {
                text-decoration: none;
                background-color: transparent;
              }
            }
          }

          .consultation-action {
            flex: 1 1 100%;
            padding: 0;
            display: inline-block;
            border: 0;
            border-radius: var(--rounded-md);

            &:hover {
              background-color: transparent;
            }

            label.file-item__action {
              margin: 0;
              cursor: pointer;
              color: var(--color-gray-300);
              display: flex;
              align-items: center;
              gap: 10px;
              padding: 7px 15px;
              width: 283px;
              border: 1px solid var(--color-gray-50);
              border-radius: var(--rounded-md);

              .material-icons {
                color: var(--color-primary-500);
              }

              &:hover {
                color: var(--color-gray-400);
                background-color: var(--color-primary-50);
              }

              span {
                color: var(--color-gray-900);
                font-weight: var(--font-normal);
                @extend .text-sm;
              }
            }
          }
        }
      }

      &.empty {
        .not-found {
          h3 {
            @extend .text-sm;
            font-weight: var(--font-normal);
            color: var(--color-gray-500);
            margin: 20px;
            text-align: center;
          }
        }
      }

      .invoice-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;
      }

      .invoice-item {
        margin: 0 0 5px 0;
        padding: 5px 0;
        width: 100%;
        display: flex;
        gap: 10px;
        color: var(--color-gray-900);
        border-radius: var(--rounded);
      }
    }
  }
}

/**********************/
/*   BLOCKS_GENERAL   */
/**********************/
.patient-blocks {

  .default-block {

    .blocks-overflow {
      max-height: 400px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }


    .address-item {
      .subtitle span {
        display: block;
      }
    }

    .block-content,
    .list-content,
    .text-content {
      padding: 15px;
      flex-grow: 1;
      margin: 0;
      overflow: auto;
      // min-height: 200px;

      &.half_size {
        max-height: 230px;
      }


      .repeater-field-value {
        flex-direction: column;

        ul {
          margin: 0;
          display: flex;
          gap: 10px;
          flex-direction: column;
          align-self: flex-start;
          flex-grow: 1;
          width: 100%;

          li {
            span.repeater-block {
              width: 100%;
              display: flex;
              gap: 5px;
              justify-content: space-between;
              margin-bottom: 5px;
            }

          }
        }
      }


      h4 {
        @extend .text-xs;
        font-weight: var(--font-bold);
        color: var(--color-gray-900);
        padding: 0;
        margin: 0 !important;
        border: none;
      }

      h5 {
        @extend .text-xs;
        font-weight: var(--font-normal);
        color: var(--color-gray-500);
        padding: 0;
        margin: 10px 0 !important;
        border: none;

        &.mt-0 {
          margin-top: 0 !important;
        }
      }

      .content-item {
        margin-top: 10px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--color-gray-50);
        }

        &, strong, p {
          font-family: var(--font-family);
          @extend .text-xs;
        }

        strong {
          display: block;
          font-weight: var(--font-bold);
          color: var(--color-gray-900);
          margin-bottom: 10px;
        }

        p {
          font-weight: var(--font-normal);
          color: var(--color-gray-500);
          margin-bottom: 10px;
        }
      }

      .danger {
        strong, span {
          color: var(--color-danger-500) !important;
        }
      }

      &.aligned-right {
        span {
          text-align: right;
        }
      }

      span {
        font-family: var(--font-family);
        @extend .text-xs;

        &.separator {
          border: none;
        }

        &.medium {
          font-weight: var(--font-medium);
          color: var(--color-gray-900);

          strong {
            font-family: var(--font-family);
            font-weight: var(--font-bold);
            color: inherit;
          }
        }

        &.list {
          position: relative;
          padding-left: 10px;
          margin-left: 0;

          &:before {
            content: '•';
            position: absolute;
            left: 1px;
          }
        }
      }
    }

    .section_title {
      font-weight: var(--font-semibold) !important;
      font-family: var(--font-family) !important;
      color: var(--color-gray-900) !important;
      font-size: var(--font-size-xs);
      line-height: var(--line-height-xs);
      margin: 0;
    }

    .block-content,
    .text-content {

      .fields {
        display: flex;
        flex-direction: column;
        max-height: 450px;
        overflow: auto;
        gap: 10px;
        padding-right: 5px;
        margin-right: -5px;
      }

      > div, .fields > div {
        @extend .text-xs;
        gap: 5px;

        &.field {
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          justify-content: space-between;
          width: 100%;

          &.repeater-field-value {
            flex-direction: column;
          }

          &.ia-suggestion {
            position: relative;
            strong {
              color: var(--color-ai-900);
            }

            span {
              color: var(--color-ai-900);
              font-weight: bold;
            }
          }

        }

        strong {
          color: var(--color-gray-400);
          font-weight: var(--font-normal);
          font-family: var(--font-family);
          flex-shrink: 0;
        }

        span {
          color: var(--color-gray-900);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .ai-actions {
            display: none;
          }

          &:hover {
            .ai-actions {
              display: block;
              position: absolute;
              bottom: -28px;
              z-index: 10;
              background: var(--color-white);
              border: 1px solid var(--color-gray-100);
              padding: 2px 5px 0 5px;
              right: 0;
              box-shadow: 0 0 2px;
              border-radius: 6px;
            }
          }

          button {
            border: none;
            padding: 2px;
            background: transparent;
          }

          &.material-icons {
            font-family: "Material Symbols Outlined";
          }
        }

        &.column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }
      }
    }

    .text-content,
    .list-content,
    .block-content,
    .blocks-container {
      .actions {
        flex-grow: 1;
        display: flex;
        flex-direction: row-reverse;
        justify-content: stretch;
        align-items: flex-end;
        gap: 10px;
        margin-top: 20px;

        a, input, label {
          @extend .text-xs;
          display: flex;
          justify-content: center;
          margin: 0;
          flex-grow: 1;
          text-align: center;
          height: 32px;
          float: initial !important;

          &:hover, & {
            text-decoration: none;
          }
        }
      }
    }

    .text-content,
    .block-content {
      .clearfix, &.clearfix {
        align-self: center;

        &:before, &:after {
          content: none;
        }
      }
    }

    .text-content {
      hr {
        display: none;
      }

      > div {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    }

    .blocks-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 15px;
      gap: 10px;

      .text-content {
        padding: 0;
      }

      h3 {
        @extend .text-xs;
        font-weight: var(--font-normal);
        color: var(--color-gray-500);
      }
    }

    .list-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      flex-grow: 1;

      h5 {
        padding: 10px 15px;
        margin: 0 !important;
        border-bottom: 1px solid var(--color-primary-50);
      }

      p {
        padding: 10px 15px;
      }

      .actions {
        margin: 15px;
        flex-grow: 1;
      }
    }


    /******************/
    /*    PP_ACTIONS  */
    /******************/
    .pp-actions {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 15px;

      button, a {
        @extend .text-xs;
        height: 32px;
        width: 100%;
        justify-content: center;
      }
    }

    /******************/
    /*      NOTE      */
    /******************/
    .note-content {
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-50);
        border-color: var(--color-gray-50);
      }

      padding: 0;

      &, strong, p {
        font-family: var(--font-family);
        @extend .text-xs;
      }

      strong {
        display: block;
        font-weight: var(--font-bold);
        color: var(--color-gray-900);
        margin-bottom: 0;
      }

      p {
        font-weight: var(--font-normal);
        color: var(--color-gray-500);
        margin-bottom: 10px;
      }
    }

    /******************/
    /*   MEDIC_TEAM   */
    /******************/
    .patient-medic-team {
      .text-content p {
        font-family: var(--font-family);
        display: flex;
        margin-bottom: 20px !important;
      }

      img {
        margin: 0;
        @extend .avatar_default;
      }

      .details {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }

      .title {
        @extend .text-sm;
        font-weight: var(--font-semibold);
        color: var(--color-gray-900);
      }


      .small, .person-of-trust span:not(.unfilled) {
        @extend .text-xs;
        font-weight: var(--font-normal);
        color: var(--color-gray-500);

        &.bold {
          font-weight: var(--font-semibold);
        }

        &.title {
          @extend .text-sm;
          font-weight: var(--font-semibold);
          color: var(--color-gray-900);
        }
      }

      .person-of-trust {
        align-items: flex-start;

        .title > span {
          font-weight: var(--font-bold);
          color: var(--color-gray-900);
        }
      }
    }
  }
}

/*************************/
/*    SUPERVISION_LIST   */
/*************************/
.supervision-menu, .supervision-menu {
  &.supervision-menu-questionnaire {
    br {
      display: none;
    }

    .form-row {
      padding: 0;
    }
  }

  &:not(.no-shadow) {
    @extend .v2__box_shadow;
  }

  .supervision_header {
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-gray-50);
    position: sticky;
    top: 0;
    background-color: var(--color-white);
  }

  .supervision-item {
    border-bottom: 1px solid var(--color-gray-50);

    .banner {
      padding: 0;
      border: 0;
      display: flex;
      align-items: flex-start;
      background-color: var(--color-white);
    }

    .supervision_item_header {
      padding: 20px 0 25px 0;
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      margin: 0;
      background-color: var(--color-white);
      width: 80%;

      &, > span {
        cursor: pointer;
      }

      .supervision_item_header_title {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        overflow: hidden;

        .main {
          @extend .text-sm;
          color: var(--color-gray-900);
          font-weight: var(--font-bold);
          align-items: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          width: calc(100% - 15px);
          overflow: hidden;

          .material-icons {
            margin-right: 10px;
          }


          .badge {
            margin-right: var(--p-1);
          }

        }
      }

      .material-icons {
        @extend .text-base;
        color: var(--color-gray-400);
        font-weight: var(--font-normal);
      }

      .supervision_item_header_title .subtitle,
      .supervision_item_header_toggle,
      .supervision_item_header_date {
        @extend .text-xs;
        color: var(--color-gray-400);
        font-weight: var(--font-normal);
        flex-shrink: 0;
      }

      .supervision_item_header_date {
        flex-direction: column;
        margin-top: 3px;
        display: flex;
        align-items: end;
        gap: 5px;
      }

      .supervision_item_header_toggle {
        margin-left: 15px;
        margin-right: 10px;
      }
    }

    .supervision_item_banner_actions {
      padding-top: 15px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row-reverse;
      gap: 5px;
      min-width: 70px;
      margin-right: 15px;
      flex-shrink: 0;

      a, button {
        padding: 0 !important;
        margin: 0;
        height: auto;

        &, &:active, &:focus {
          border: 0;
          background: transparent;

          .material-icons {
            @extend .text-lg;
            color: var(--color-gray-400);
          }
        }

        &:hover {
          .material-icons {
            color: var(--color-primary-500);
          }
        }
      }
    }

    .supervision_item_header_toggle {
      .toggle_button_open {
        display: none;
      }
    }

    .supervision_item_content {
      display: none;
      padding: 0 15px 10px 40px;
      gap: 5px;

      .content-separator {
        margin-top: -20px;
        padding-bottom: 10px;
      }

      .text-content {
        margin: 0;
        display: flex;
        flex-direction: column;
        color: var(--color-black);
        font-size: var(--font-size-sm);
      }
    }

    .supervision_item_content_inner {
      display: flex;
      flex-direction: row;

      .text-content {
        flex: 1;
      }

      .family_member_data {
        padding-left: 20px;
      }

      .family_member_data__list {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 250px;

        li {
          display: flex;
          flex-direction: row;

          .family_member_data__label,
          .family_member_data__value {
            flex: 1
          }
        }
      }

      .family_member_data__value {
        color: var(--color-black);
        text-align: right;
      }

      > div {
        display: flex;

        &, & > * {
          @extend .text-xs;
          color: var(--color-gray-900);
        }
      }
    }

    .toggle_input:checked ~ {
      .banner {
        .supervision_item_header {
          padding-bottom: 20px;
        }

        .supervision_item_header_toggle {
          .toggle_button_close {
            display: none;
          }

          .toggle_button_open {
            display: block;
          }
        }
      }

      .supervision_item_content {
        display: block;
      }
    }
  }
}

#app_patientquestionnaire_questionnaires {
  min-height: 200px;
}

.text-content .family-member {
  margin-top: 10px;
}


.treatment-field {
  .input-loader {
    bottom: initial !important;
    top: 7px;
  }
}
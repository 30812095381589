/**
 *  SUMMARY
 *   - GENERICS
 *   - LAYOUT
 *     - VIDEO_CONFERENCE
 *   - TOGGLES
 *   - NOTES
 *   - ALERT_CALL
 *   - MEDIA_CONTROLS
 */

/*****************/
/*    GENERICS   */
/*****************/
#profile {
  margin-right: 0;
}

.video-conference-container {
  max-height: 560px;

  .media-normal {
    align-self: center;
  }

  .media-miniature {
    background-color: transparent;
    width: 90px;
    min-height: auto;
  }

  .media-containers {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .media-container {
      border: 0;
      .media-box {
        background: transparent;
      }
    }
  }
}

/*****************/
/*     LAYOUT    */
/*****************/
.visio_container {
  position: relative;
  .visio_video_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 870px;
    margin: auto;

    #root {
      display:flex;
    }
  }

  .split-lesser-part, .split-bigger-part {
    width: 100%;
  }

  .split-lesser-part {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 0;

    .edit-profile-action {
      flex-grow: 1;
      align-items: flex-end;
    }
  }

  input {
    background: none;
  }

  /**********************/
  /*  VIDEO_CONFERENCE  */
  /**********************/
  .video-conference-container {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .chatfile-container {
      @extend .v2__block;
      position: initial;
      display: flex;
      flex-direction: column;
      width: 590px;

      &:before {
        content: 'Chat';
        display: block;
        @extend .text-base;
        font-weight: var(--font-semibold);
        width: 100%;
        height: var(--box-header-height);
        padding-right: 15px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--color-gray-50);
      }

      .chatfile {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
        margin: 0;
      }

      .chatfile-list {
        display: flex;
        flex-direction: column;
        max-height: 275px;
        flex-grow: 1;

        &:before {
          content: ' ';
          flex-grow: 1;
        }

        .media-message {
          @extend .v2__box_shadow;
          @extend .text-sm;
          padding: 10px 15px;
          color: var(--color-gray-900);
          margin-bottom: 5px;
          overflow: initial;
        }

        .media-message-local {
          align-self: flex-end;
          margin-right: 15px;
          background-color: var(--color-primary-50);
        }

        .media-message-remote {
          margin-left: 15px;
          align-self: flex-start;
        }
      }

      .chatfile-form {
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 10px 15px;
        border-top: 1px solid var(--color-gray-50);

        input {
          @extend .p-2;
          height: var(--input-height-xs);
          margin: 0;
        }

        .media-message-input {
          width: auto;
          flex-grow: 1;
        }

        .media-message-send {
          @extend .v2__button_primary;
          @extend .text-xs;
          flex-shrink: 0;
          width: 70px;
          height: var(--input-height-xs);
          padding: 0 var(--p-2) !important;
          margin-right: 0;
        }
      }
    }
  }
}

@include xxl {
  .visio_container:not(.patient) {
    margin: auto;
    display: flex;
    gap: 5px;
    justify-content: center;

    .split-bigger-part {
      width: auto;
    }

    .split-lesser-part {
      display: inline-flex;
      flex-direction: column;
      width: auto;
      min-width: 300px;
      max-width: 400px;
    }

    .visio_video_container {
      display: inline-flex;
      margin: 0;
    }
  }
}

.visio_container {
  /*****************/
  /*    TOGGLES    */
  /*****************/
  .split-bigger-part {
    .toggle_button {
      position: absolute;
      right: 0;
      top: 0;
      display: none;
    }
  }

  .toggle_input:checked ~ {
    .v2__block_header {
      border-bottom: 0;

      .toggle_button_close {
        display: block;
      }

      .toggle_button_open {
        display: none;
      }
    }

    .v2__block_content, .form-col {
      display: none;
    }
  }
}

/******************/
/*      NOTES     */
/******************/
.note-list {
  min-height: auto;
  height: auto;
  max-height: 270px;
  border-bottom: 1px solid var(--color-gray-50);

  &:empty {
    border: 0;
  }

  &.v2__list_dot .v2__list_item .title {
    white-space: break-spaces;
  }
}

/******************/
/*   ALERT_CALL   */
/******************/

.alert-call {
  background-color: var(--color-success-100);
  border: 1px solid var(--color-success-100);
  border-radius: var(--rounded-lg);

  &, a {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  span {
    margin: 0;
  }

  span.content {
    border: 0;
    font-weight: var(--font-medium);
    flex-grow: 1;
    width: auto;
    height: auto;
    color: var(--color-success-500);
    text-align: left;
  }

  a:hover {
    text-decoration: none;
  }

  &:hover {
    border-color: var(--color-success-500);
    background: transparent;

    .content {
      color: var(--color-success-500);
    }

    .answer {
      background: var(--color-success-500);
      color: var(--color-white);
    }

    .reject {
      background: var(--color-danger-500);
      color: var(--color-white);
    }
  }

  span.answer {
    &, &:hover {
      background-color: transparent;
      color: var(--color-success-500);
      border-color: var(--color-success-500);
    }
  }

  span.reject {
    &, &:hover {
      background: transparent;
      border-color: var(--color-danger-500);
      color: var(--color-danger-500);
    }
  }
}

/******************/
/* MEDIA_CONTROLS */
/******************/
.media-controls {
  .media-controls-group {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: auto;
  }

  .control {
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    border-radius: 100%;
    height: 28px;
    width: 28px;

    &:before {
      @extend .material-icons;
      font-size: var(--font-size-lg);
      line-height: var(--line-height-lg);
      color: var(--color-primary-500);
    }

    &:first-child, &:last-child {
      height: 30px;
      width: 30px;

      &:before {
        font-size: var(--font-size-2xl);
        line-height: var(--line-height-2xl);
      }
    }
  }

  .connexion-off:before,
  .connexion-on:before,
  .connexion-no:before {
    content: 'refresh';
    color: var(--color-success-500);
  }

  .mute-audio:before {
    content: 'mic';
  }

  .unmute-audio:before {
    content: 'mic_off';
  }

  .mute-video:before {
    content: 'videocam';
  }

  .unmute-video:before {
    content: 'videocam_off'
  }

  .exit-video-conference:before {
    content: 'power_settings_new';
  }

  .zoom-in, .zoom-out {
    background-color: transparent;
  }

  .zoom-in:before {
    color: var(--color-white);
    content: 'crop_free';
  }

  .zoom-out:before {
    content: 'zoom_in_map';
    color: var(--color-gray-500);
  }
}

.app-store {
  display: flex;

  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
  flex-wrap: wrap;

  gap: 15px;

  a {
    display: flex;

    &:hover {
      background: none;
      box-shadow: none;
    }


    img {
      width: 250px;
      height: auto;
    }

  }

}

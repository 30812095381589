.drawer {
  width: 0;
  display: none;
  position: fixed;
  top: 0;
  border: none;
  right: 0;
  z-index: 30;
  bottom: 0;
  height: 100%;
  background: var(--color-white);
  box-shadow: 0 0 0 100vw rgb(0 0 0 / 40%);
  flex-direction: column;
  pointer-events: auto;

  .close-box {
    color: var(--color-gray-300);
    cursor: pointer;
    top: 10px;
    left: 10px;
    position: absolute;

    @include sm {
      top: 15px;
      left: 15px;
    }

  }


  h2, h3, h4 {
    font-weight: bold;
  }

  .popin_alerts {
    @extend .v2__alerts;
    margin: 0;
    padding: 0 15px;
  }

  .drawer-header {

    @extend .v2__block_header;

    position: relative;
    border-bottom: 1px solid var(--color-gray-50);

    padding: 10px 40px 10px 40px;

    @include sm {
      padding: 40px;
      min-height: 110px;
    }

    h2 {
      font-size: 18px;
      border-bottom: none;
      margin: 0;

      &.with_actions {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      @include sm {
        font-size: 22px;
      }

    }

    .drawer-subtitle {
      color: var(--color-black);
      margin-bottom: 10px;
    }

  }

  .drawer-content {

    border-top: none;

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    padding-bottom: 40px;
    height: calc(100% - 110px);
    overflow-y: auto;
    box-shadow: none;
    border-radius: 0;

    h3 {
      font-size: 16px;
    }

    h4 {
      font-size: 13px;
    }

    .bootstrap-tagsinput input {
      border: none;
    }


    .supervision_item_banner_actions {
      position: relative;

      .check {
        color: var(--color-success-500);
        background: var(--color-success-50);
      }

      .error {
        color: var(--color-danger-500);
        background: var(--color-danger-50);
      }

      .drawer-icon-info {
        border-radius: 50%;
        padding: 5px;
        display: none;
        font-size: var(--font-size-xl);
      }

      .loader {
        display: none;
        position: relative;

        .loading-spinner {
          position: relative;
          left: 0;
          width: 25px;
          top: -20px;
        }

      }

    }

    .supervision_item_content {
      padding: 0 20px 10px 40px;
    }

    .supervision-item-actions {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      gap: 15px;
      align-content: center;
      align-items: center;
      justify-content: end;

      a {
        font-size: 14px;
      }
    }

    &.list {
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

  }


  .drawer-footer {
    padding: 40px;
    display: flex;
    flex-direction: row;
    gap: 15px;

    .btn {
      flex-grow: 1;
      margin: 0;
    }
  }


  .small {
    font-size: var(--font-size-xs);
  }

  .update-form {
    display: flex;
    //  flex-grow: 1;
    justify-content: space-between;
    flex-direction: column;

    .fields {
      padding: 15px 40px;
      max-height: calc(100vh - 120px);
      flex-shrink: 1;
      overflow: scroll;
      min-height: 100px;
      position: relative;

      &.no-overflow-x {
        overflow-x: hidden;
      }

    }

    @include sm {
      max-height: calc(100vh - 110px);
    }

    .form-error {
      text-align: left;
    }

    .form-group {
      flex-grow: 1;
    }

    .repeater-row:not(:last-child) {
      border-bottom: 1px solid var(--color-gray-100);
    }

    .repeater-row {
      flex-wrap: wrap;

      @media screen and (max-width: 772px) {
        position: relative;
        padding-top: 20px;
      }

      .remove-link {
        margin: 0;
        align-self: flex-start;


        @media screen and (max-width: 772px) {
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }

      .repeater-row-fields-container {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-shrink: 1;
        width: 90%;
        align-self: flex-start;
        align-items: self-start;
        justify-content: start;
        column-gap: 15px;
        flex-wrap: wrap;

        .form-group {
          flex-grow: 1;
          flex-shrink: 1;
          width: 100%;
        }

      }
    }

    [data-field="user_phones"] {
      .repeater-row .repeater-row-fields-container .form-group {
        max-width: 48%;
      }
    }


    .multi-field {
      border: 1px solid var(--color-gray-100);
      padding: 15px;


    }


    input + .suffix, .v2-autocomplete--drawer + .suffix {
      position: absolute;
      right: 10px;
      background: var(--color-white);
      font-size: 12px;
      font-weight: initial;
    }

    input + .suffix {
      bottom: 7px;
    }

    input:disabled + .suffix {
      background: var(--color-gray-50) !important;
    }


    .v2-autocomplete--drawer + .suffix {
      bottom: 12px;
    }

    .radio-values {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 9px;

      &.col-radio {
        flex-direction: column;
        gap: 0;


        label {
          width: 100%;
        }

      }

      input {
        position: relative;
        width: 1px;
        height: 1px;
        margin: 0;
        padding: 0;
        font-size: 1px;
      }

      label {
        padding: 10px;
        border: 1px solid var(--color-gray-100);
        border-radius: 5px;
        min-width: 38px;
        min-height: 38px;
        cursor: pointer;
        background-color: var(--color-white);

        &:hover {
          background-color: var(--color-primary-100);
        }
      }


      input:disabled:not(:checked) + label {
        background-color: var(--color-gray-50);
      }

      input:checked + label {
        color: var(--color-white);
        background-color: var(--color-primary-500);
      }


      input:checked.with-error + label {
        color: var(--color-white);
        background-color: var(--color-danger-500);
      }

    }

    h4.section-title {
      margin-bottom: 5px;
      color: var(--color-black);
      font-weight: var(--font-semibold);
    }

    .questionnaire-field, .form-group {

      h4 {
        margin-bottom: 5px;
        color: var(--color-black);
        font-weight: var(--font-semibold);
      }

      hr {
        margin-top: 0;
      }
    }

  }

  .scroll-button {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background: #fff;
    border: 1px solid var(--color-gray-100);
    box-shadow: 0 0 11px 0 rgb(0 0 0 / 20%);
    color: var(--color-primary-500);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    text-align: center;
    margin-right: -21px;
    cursor: pointer;
    padding: 5px;
    margin-left: auto;
    right: -30px;
    font-size: 18px;
  }


  .drawer--footer {

    border-top: 1px solid var(--color-gray-100);
    padding: 15px 40px;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
    display: flex;
    flex-wrap: wrap-reverse;
    min-height: 60px;

    button {
      margin: 0;
    }

    button {
      display: flex;
      flex-grow: 1;
    }

    @include sm {
      min-height: auto;
      // For intercom
      padding-bottom: 75px;
      button {
        display: block;
        flex-grow: 0;
      }
    }

  }


  .v2-autocomplete {
    margin-bottom: 15px;

    &.unique {
      height: auto;

      .input-loader {
        bottom: 12px;
      }

    }
  }

  .field-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }


}

/*********************/
/* PATIENT_SELECTION  */
/*********************/

#read-health-card-drawer {
  .health-card {
    transform: rotate(-5deg);
    padding: 15px;
    display: block;
    width: 150px;
    text-align: left;
    margin: 0 auto 15px auto;
  }

  .patient-selection, .patients {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }

  .patient-item {

    &:hover {
      text-decoration: none;
      background: var(--color-gray-50);
    }

    width: 100%;
    cursor: pointer;
    display: flex;
    border: 1px solid var(--color-gray-50);
    padding: 10px;
    border-radius: var(--rounded-lg);
    gap: 10px;
    align-items: center;

    h4 {
      padding: 0;
      margin-bottom: 6px;
      color: var(--color-gray-900);
      border-bottom: none !important;
    }

    p {
      margin: 0;
    }

    a {
      font-size: var(--font-size-xs);
    }

  }


  .patient-confirmation {

    label {
      display: flex;
      width: 100%;
      margin: 0;
    }

    input[type=radio]:checked + .patient-item {
      background-color: var(--color-gray-50);
    }
  }


}

.last-save-block {
  font-size: var(--font-size-xs);
  color: var(--color-gray-700);
  text-align: right;
  margin-top: 10px;
}

.no-pointer {
  pointer-events: none;
}

.popin-document-view {
  pointer-events: auto;
}
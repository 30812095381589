// # File used for PDF Generation

$default-color : #00B4FF !default;
$secondary-color : #33c3ff !default;
$success-color: #37DCB4 !default;
$link_color: #0090cc !default;
$orange : #f89923 !default;
$green : #37DCB4 !default;
$red: #F85346 !default;
$black: #4D4D4D !default;
$grey : #f1f1f1;
$dark-grey : #777;
$purple : #6a32f9;
$yellow: #fabe41;

$mobile-max : 768px;
$medium-screen : 1200px;

$font-bold : Gilroy-bold;
$font-semiBold : Gilroy-semiBold;
$font : Gilroy;


$dark-red: darken($red,15);

$dark-color : darken($default-color,10);
$pale-color: transparentize($default-color,0.3);

$light-secondary-color : lighten($secondary-color,10);

$darken-color: $dark-color;
$semi-dark-color: $dark-color;
$mid-blue : $default-color;

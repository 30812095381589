.switcher_container {
  display: flex;
  align-items: center;
  gap: 5px;

  .text_label {
    @extend .text-xs;
    color: var(--color-gray-900);
    margin-bottom: 0;
    cursor: pointer;
    font-weight: initial;
  }

  .switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 17px;
    padding: 3px;
    border-radius: var(--rounded-lg);
    cursor: pointer;

    .switch-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    .switch-label {
      position: relative;
      display: block;
      height: inherit;
      font-size: 8px;
      background: var(--color-gray-100);
      border-radius: inherit;
      box-shadow: var(--shadow-box);
      transition: left 0.15s ease-out;
      transition-property: opacity, background;

      &:before, &:after {
        position: absolute;
        top: 50%;
        margin-top: -.5em;
        line-height: 1;
        transition: inherit;
      }

      &:before {
        content: attr(data-off);
        right: 6px;
        color: var(--color-gray-700);
        margin-right: -3px;
      }

      &:after {
        content: attr(data-on);
        left: 4px;
        color: var(--color-white);
        opacity: 0;
      }

      & + .switch-input:checked {
        background: var(--color-primary-500);
        box-shadow: var(--shadow-box);
        &:before { opacity: 0; }
        &:after { opacity: 1; }
      }
    }

    .switch-input:checked ~ .switch-label {
      background: var(--color-primary-500);
      &:before { opacity: 0; }
      &:after { opacity: 1; }
    }


    .switch-handle {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 15px;
      height: 15px;
      background: white;
      border-radius: 10px;
      background: linear-gradient(to bottom, #f7f7f7, var(--color-white));
      transition: left 0.15s ease-out;
    }

    .switch-input:checked ~ .switch-handle {
      left: 21px;
    }
  }

  &.with_icon {
    .switch-label {
      font-family: var(--font-family-icon);
      font-size: var(--font-size-sm);

      &:after {
        margin-left: -1px;
      }
    }
  }
}

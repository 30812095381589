@media print {
  // Correct but with 299XX pages
  #main-container,.main-page-content {
    height: initial;
  }


  header,#back-to-admin,.sidebar-right,.v2__sidebar_menu,.no-print {
    display: none !important;
  }

  .box {
    background-color: transparent !important;
  }

  h2 {
    color: var(--color-black) !important;
  }

  body.v2-css .container .page-content {
    overflow: visible;
  }

}

@import "variables_without_font";

$font: Helvetica, Arial, sans-serif;

.document-editor {

  position: relative;
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  margin-bottom: 100px;

  /* Set vertical boundaries for the document editor. */
  max-height: 100vh;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;


  .document-editor__toolbar {
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;

    /* Create the illusion of the toolbar floating over the editable. */
    box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

    /* Use the CKEditor CSS variables to keep the UI consistent. */
    border-bottom: 1px solid var(--ck-color-toolbar-border);

    .ck-toolbar {
      border: 0;
      border-radius: 0;
    }
  }

  .document-editor__editable-container {
    padding: calc(2 * var(--ck-spacing-large));
    background: var(--ck-color-base-foreground);

    /* Make it possible to scroll the "page" of the edited content. */
    overflow-y: scroll;
    transition: height 0.2s ease;


  }

  .document-editor__editable-container > .ck-editor__editable {
    width: 21cm;
    padding: 0;
    margin: 0 auto;
    box-sizing: content-box;
  }


  .document-editor__editable-container > .ck-editor__editable .page-box {
    /* Set the dimensions of the "page". */
    width: 21cm;
    min-height: 29.7cm;
    overflow: hidden;

    // Break after every page
    page-break-after: always;

    /* Keep the "page" off the boundaries of the container. */
    padding: .5cm;

    border: 1px hsl(0, 0%, 82.7%) solid;
    border-radius: var(--ck-border-radius);
    background: white;
    background-size: contain;

    .ck-content .image.image_resized img {
      width: 100%;
    }

    .ck-content .image.image_resized > figcaption {
      display: block;
    }

    .ck-content .mention {
      color: $success-color;
      background-color: transparentize($success-color, 0.5);
    }

    /* The "page" should cast a slight shadow (3D illusion). */
    box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

    /* Center the "page". */
    margin: 10px auto 0 auto;

    &:nth-child(1) {
      margin-top: 0;
    }

  }

  /* Set the default font for the "page" of the content. */
  .ck-heading-dropdown .ck-list .ck-button__label, .table td {
    font-size: 12px;
    font-family: $font;
    line-height: 12px;
  }

  /* Adjust the headings dropdown to host some larger heading styles. */
  .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
    min-width: 6em;
  }

  /* Scale down all heading previews because they are way too big to be presented in the UI.
  Preserve the relative scale, though. */
  .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
    transform: scale(0.8);
    transform-origin: left;
  }

  .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
    color: var(--ck-color-list-button-on-text);
  }


  .col {
    border: 1px dotted $black !important;
  }

}

.invalid {
  .editor, .ck-editor, textarea, input {
    border: 2px solid $red !important;
  }
}

/* Set the styles for "Heading 1". */
.ck-content h2,
.page-box h2,
.ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 30px;
  font-weight: normal;
  line-height: 1;

}

/* Set the styles for "Heading 2". */
.ck-content h3,
.page-box h3,
.ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 24px;
  font-weight: normal;
  line-height: 1;

}

/* Set the styles for "Heading 3". */
.ck-content h4,
.page-box h4,
.ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;

}

.ck-content .structured-data {
  border: 1px dotted $black !important;
  // min-width: 50px;
  // display: inline-block;

  &[required=true] {
    border: 1px solid $red !important;
  }

}


body.v2-css .ck-content,
body.v2-css .document-editor .page-box,
.page-box,
.ck-content {
  box-sizing: border-box;

  .table table {
    td, th {
      padding: 2px;
      font-family: $font;
      font-weight: normal;
    }
  }

  color: #000;

  h3 {
    padding-top: 5px;
    margin-bottom: 2px;
    color: #000;
    font-family: $font;
    font-weight: var(--font-normal);
    margin-top: 5px;
  }

  h4 {
    line-height: var(--line-height-base);
    margin-top: 5px;
  }

  h2 {
    font-size: 30px;
    padding: 5px 0 0 0;
    margin-bottom: 2px;
    color: #000;
    font-family: $font;
    border-bottom: 0 !important;
    font-weight: var(--font-normal);
  }

  .image.image_resized {
    max-width: 100%;
  }

  .ck-widget_with-resizer {
    margin-left: 0 !important;
  }

  .image.image_resized {
    display: block;
    box-sizing: border-box;
    margin-left: 0;
  }

  p, li,ul, td, th,.table table p {
    font-size: 13px;
    padding-top: 5px;
    margin-bottom: 0;
    font-family: $font;
    margin-top: 0;
    line-height: 1.1rem;
  }

  strong {
    font-family: $font;
    font-weight: bold;
  }

  .text-huge {
    font-size: 26px;
    line-height: initial;
  }

  .text-big {
    font-size: 18px !important;
    line-height: initial;
  }

  .text-small {
    font-size: 10px !important;
  }

  .text-tiny {
    font-size: 8px !important;
  }

  h4 {
    color: #000;
    margin-bottom: 2px;
    font-family: $font;
    border-bottom: none !important;
    padding: 0 0 5px 0 !important;
  }

  span[bold="true"] {
    font-weight: bold;
  }

  span[italic="true"] {
    font-style: italic;
  }

  span[underline="true"] {
    text-decoration: underline;
  }

  span[strikethrough="true"] {
    text-decoration: line-through;
  }


  .structured-data {
    position: relative;
    min-height: 16px;

    &:hover, &.ck-widget_selected {

      &:before {
        top: -18px;
        font-size: 14px;
        background: rgba(77, 77, 77, 0.4);
        position: absolute;
        padding: 3px 4px 1px 4px;
        left: -1px;
        color: #fff;
        content: attr(title);
      }
    }

    &[fontsize="text-huge"]:before {
      top: -24px;
      padding: 5px;
    }

    &[fontsize="text-big"]:before {
      font-size: 18px;
      top: -19px;
    }

    &[fontsize="text-small"]:before {
      font-size: 10px;
      top: -19px;
    }

    &[fontsize="text-tiny"]:before {
      font-size: 8px;
      top: -15px;
      padding: 0 4px 0 4px;
    }


    &[bold="true"]:before {
      font-weight: bold;
    }

    &[italic="true"]:before {
      font-style: italic;
    }

    &[underline="true"]:before {
      text-decoration: underline;
    }

    &[strikethrough="true"]:before {
      text-decoration: line-through;
    }


    &:hover:before {
      background: #4D4D4D;
    }

    &:after {
      //    content: attr(title);
      font-size: 13px;
      color: #cccccc;
      position: absolute;
      bottom: 0;
      pointer-events: none;
    }

  }

  font-size: 14px;
  font-family: $font;
  line-height: 14px;
  background-size: contain;
  display: block;

  .image-style-side {
    max-width: 50%;
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  .image img {
    max-width: 100%;
  }

  & .image-style-side,
  & .image-style-align-left,
  & .image-style-align-center,
  & .image-style-align-right {
    max-width: 50%;
  }

  & .image-style-side {
    margin-right: auto;
    margin-left: auto;
  }

  & .image-style-align-left {
    margin-right: auto;
    margin-left: 0;
  }

  & .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  & .image-style-align-right {
    margin-right: 0;
    margin-left: auto;
  }

  .todo-list .todo-list__label > input[checked]:before {
    background: #000;
    border-color: #000;
  }

  .mention {
    background: transparentize($default-color, 0.9);
    color: $default-color;
  }

  .col {
    width: 40%;
    display: inline-block;
    padding: 0;
    margin: 0;
    border: 1px solid orange;
    box-sizing: border-box;
  }

}


.drugs p {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: block;

  i {
    display: block;
    margin-top: 5px;
    margin-left: 8px;
  }

  br {
    display: none;
  }

}


.box .ck-content {
  background: #fff;
  border: 1px solid #c4c4c4;
}

span.signature {
  display: inline-block;
  //text-align: right;
  // margin: 0 0 0 auto;
  img {
    display: inline-block;
  }
}


.min-150 {
  max-width: 21cm;
  margin: 0 auto;
  display: block;
}

.ck-content .table table, .ck-content .table {
  margin-left: 0 !important;
}

.ck-content .table {
  width: auto;
}

.ck-content {
  span[data-mention="{{authorProfile.signature|img}}"] {
    max-width: 300px;
    display: inline-block;
    text-align: center;
    max-height: 150px;
    min-width: 150px;
    min-height: 75px;
    line-height: 75px;
    // line-height: auto;
  }

  span[data-mention="{{authorProfile.businessStamp|img}}"] {
    max-width: 300px;
    display: inline-block;
    text-align: center;
    min-width: 200px;
    min-height: 100px;
    line-height: 100px;
    // line-height: auto;
  }

  span[data-mention$="barcode}}"] {
    width: 200px;
    display: inline-block;
    text-align: center;
    height: 70px;
    line-height: 70px;
  }

  span[data-mention$="barcode-small}}"] {
    width: 150px;
    display: inline-block;
    text-align: center;
    height: 60px;
    line-height: 60px;
  }
}


#fields .ck-content {
  max-width: 21cm;
  margin: 0 auto;
}

#fields .toolbar {
  max-width: 21cm;
  margin: 0 auto;
}


h2.title {
  color: $green;
  font-weight: bold;
}

h4.subtitle {
  color: $dark-color;
  font-weight: bold;
  font-size: 20px;
}

.question {
  color: $dark-color;
  font-weight: bold;
  width: 50%;
  display: inline-block;
  float: left;
}

.answer {
  color: $dark-color;
  width: 50%;
  display: inline-block;
}

.group {
  margin-bottom: 5px;
  margin-top: 10px;
}


section[type="medics"] {
  text-align: right;
}

section[key="medecins_correspondants_simple"] p {
  text-align: right;
}

.new-buttons {
  display: flex;
  justify-content: space-evenly;
}


.v1 {
  .ck-content, .page-box {

    h3 {
      letter-spacing: -0.4px;
      line-height: 0.95;
      margin-top: 1em;
    }

    h4 {
      margin-top: 1.33em;
      line-height: initial;
    }

    h2 {
      letter-spacing: -0.4px;
    }

    p, li {
      line-height: normal;
      letter-spacing: -0.4px;
    }

    .text-huge {
      line-height: 0.95;
    }

    .text-big {
      line-height: 0.95;
    }

    .text-small {
      line-height: 1;
    }

    .text-tiny {
      line-height: 1;
    }

  }
}


.dropzone-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparentize(#fff,0.1%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .dropzone-container {
    display: flex;
    flex-direction: column;
  }

  .icon {
    font-size: 100px;
    text-align: center;
    display: block;
  }

  h2 {
    text-align: center;
    border: none;
  }

  p {
    text-align: center;
    font-size: var(--font-size-lg);
  }

  &.follow-scroll {
    justify-content: start;
    padding-top: 100px;
  }

}



.patient-tabs-container {

  .consultations {
    .dropzone-hover {
      justify-content: center;
      padding-top: 0;
      flex-direction: row;
      gap: 15px;

      .dropzone-container {
        flex-direction: row;
      }

      .content {
        align-items: start;
        display: flex;
        flex-direction: column;
        h2 {
          padding-left: 0;
          border: none;
        }
      }

    }
  }

}




.loading-tip {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: var(--p-4);
  padding:var(--p-2);

  .loader {
    display: flex !important;
    position: relative;

    .loading-spinner {
      top: 0;
      left: 0;
      position: initial;
    }
  }

  .loading-tip__content {
    display: flex;
    flex-direction: column;

    h2 {
      color: var(--color-gray-800);
      font-size: var(--font-size-2xl);
      line-height: var(--line-height-2xl);
      border-bottom: none;
    }

    p {
      color: var(--color-gray-600);
      margin: 0;
      font-size: var(--font-size-sm);
      padding: 0 14px;
    }
  }
}

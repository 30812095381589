/**
 *  SUMMARY
 *    - FONTS
 *    - VARIABLES
 *    - MIXIN
 *    - MATERIAL_ICONS
 *    - TEXTS
 *    - BOXES
 *    - ALERTS
 *    - BUTTONS
 *    - BADGES
 *    - TAGS
 *    - AVATAR
 *    - OTHERS
 **/

@import 'material-symbols/outlined.scss';


@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: normal;
  src: url('../../../fonts/v2/Inter-Regular.woff2') format('woff2'),
       url('../../../fonts/v2/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('../../../fonts/v2/Inter-Medium.woff2') format('woff2'),
       url('../../../fonts/v2/Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('../../../fonts/v2/Inter-SemiBold.woff2') format('woff2'),
       url('../../../fonts/v2/Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: bold;
  src: url('../../../fonts/v2/Inter-Bold.woff2') format('woff2'),
       url('../../../fonts/v2/Inter-Bold.woff') format('woff');
}

/***************************/
/*       VARIABLES         */
/***************************/
:root {
  --color-current: currentColor;
  --color-black: #000;
  --color-white: #fff;

  // Je garde pour le moment, au cas ou..
  // --color-primary-50: #f6fafe;
  // --color-primary-100: #eef5fc;
  // --color-primary-200: #d3e7f8;
  // --color-primary-300: #b9d8f4;
  // --color-primary-400: #85bbec;
  // --color-primary-500: #509EE4;

  --color-primary-50: #f9fafb;
  --color-primary-100: #ebf2fb;
  --color-primary-200: #d7e5f7;
  --color-primary-300: #5e95de;
  --color-primary-400: #357ad5;
  --color-primary-500: #0259ca;

  --color-gray-luv: #f8f8f8;
  --color-gray-50: #E9EDF0; // gris clear - filets
  --color-gray-100: #d6dbde;
  --color-gray-200: #d7dbdf;
  --color-gray-300: #9CA7AF;
  --color-gray-400: #88969f; // texte niveau 2
  --color-gray-500: #84929b;
  --color-gray-600: #6a7b87;
  --color-gray-700: #5f717e;
  --color-gray-800: #384f5f;
  --color-gray-900: #072337;
  --color-gray-900-opactity-08: #243848;


  --color-danger-50: #fdf1f2;
  --color-danger-100: #fae2e4;
  --color-danger-300: #eb8b94;
  --color-danger-500: #df4958;

  --color-success-50: #f1fbf8;
  --color-success-100: #e3f7f0;
  --color-success-300: #8fe0c2;
  --color-success-500: #50cea0;

  --color-warning-50: #fff7f1;
  --color-warning-100: #feefe2;
  --color-warning-300: #fdc08c;
  --color-warning-500: #fb9c4b;

  --color-ai-50: #f7f5fb;
  --color-ai-100: #efeaf7;
  --color-ai-300: #c7b3e0;
  --color-ai-500: #a97fc8;
  --color-ai-900: #9351cc;

  --color-yellow-50: #fffbef;
  --color-yellow-100: #fff6de;
  --color-yellow-300: #ffdc7d;
  --color-yellow-500: #ffc833;

  --color-bg-body: #f3f6f9;
  --color-menu: #131635;
  --color-underline: #dcdde0;

  --font-size-2xs: 0.5rem;
  --line-height-2xs: 0.75rem;

  --font-size-xs: 0.75rem;
  --line-height-xs: 1rem;

  --font-size-sm: 0.875rem;
  --line-height-sm: 1.25rem;

  --font-size-base: 1rem;
  --line-height-base: 1.5rem;

  --font-size-lg: 1.125rem;
  --line-height-lg: 1.75rem;

  --font-size-xl: 1.25rem;
  --line-height-xl: 1.75rem;

  --font-size-2xl: 1.5rem;
  --line-height-2xl: 2rem;

  --font-size-3xl: 1.875rem;
  --line-height-3xl: 2.25rem;

  --font-family: 'Inter', Helvetica Neue,Helvetica,Arial,sans-serif;
  --font-family-icon: 'Material Symbols Outlined';

  --font-normal: normal;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: bold;

  --p-1: 0.25rem;
  --p-2: 0.5rem;
  --p-3: 0.75rem;
  --p-4: 1rem;

  --rounded-sm: 0.125rem;
  --rounded: 0.25rem;
  --rounded-md: 0.375rem;
  --rounded-lg: 0.5rem;

  --h-8: 2rem;
  --h-9: 2.25rem;
  --h-11: 2.75rem;

  --h-btn-small: var(--h-8);
  --h-btn-base: var(--h-9);
  --h-btn-large: var(--h-11);

  --input-height-xs: 32px;
  --input-height: 40px;
  --input-height-with-error: 55px;

  --shadow-box: 0 0 8px 0 rgba(227, 228, 230, 0.4);
  --box-header-height: 53px;
  --shadow-box-popup: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);

  --box-padding: 22px;

  --form-block-margin-b: 12px;
  --gap: 12px;


  --form-field-min-width-6 : 200px;
  --form-field-min-width-4 : 300px;
  --form-field-min-width-3 : 450px;
  --form-field-min-width-2 : 600px;


}

/****************/
/*     MIXIN    */
/****************/
@mixin sm {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1536px) {
    @content;
  }
}

@mixin editor {
  @media (min-width: 1310px) {
    @content;
  }
}

@mixin transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}


/***************************/
/*     MATERIAL_ICONS      */
/***************************/
.material-icons {
  @extend .material-symbols-outlined;
  font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 24;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}

/***************************/
/*       TEXTS             */
/***************************/
/**
 * use only for notification-dot..
 * if some others use please remove this comment
 */
.text-2xs {
  font-size: var(--font-size-2xs);
  line-height: var(--line-height-2xs);
}

.text-xs {
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
}

.text-sm {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.text-base {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  &.spaced {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.text-lg {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
}

.text-xl {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
}

.text-2xl {
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-2xl);
}

.text-3xl {
  font-size: var(--font-size-3xl);
  line-height: var(--line-height-3xl);
}

.p-1 {
  padding: var(--p-1);
}

.p-2 {
  padding: var(--p-2);
}

.px-2 {
  padding-left: var(--p-2);
  padding-right: var(--p-2);
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-6 {
  margin-top: 1.5rem;
}

.mt-6 {
  margin-bottom: 1.5rem;
}


.mr-3 {
  margin-right: var(--p-3);
}

.field-small {
  @extend .p-2;
  min-height: 40px;
}

.field-large {
  @extend .p-4;
  min-height: 44px;
}

.search-style {
  @font-family: var(--font-family);
  display: flex;
  align-items: center;
  background-color: var(--color-gray-50);
  border-radius: var(--rounded-lg);
  overflow: hidden;

  .material-icons {
    @extend .text-lg;
    margin-left: 7px;
  }

  input {
    height: var(--input-height-xs);
    width: auto;
    background-color: transparent;
    padding: 0 5px;

    &, &:focus, &:hover, &:active {
      border: 0;
      padding-left: 6px;
    }
  }
}

.highlight_style {
  background-color: var(--color-primary-500);
  color: var(--color-white);
  font-weight: var(--font-bold);
  border-radius: var(--rounded-sm);
}

/***************************/
/*         BOXES           */
/***************************/
.v2__box {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-50);
  border-radius: var(--rounded-lg);
  overflow: hidden;
}

.v2__box_shadow {
  @extend .v2__box;
  box-shadow: var(--shadow-box);
}

.v2__box_item {
  @extend .v2__box;

  &:hover {
    border-color: var(--color-gray-100);
    background-color: var(--color-primary-50);
  }
}

/***************************/
/*        ALERTS           */
/***************************/
.v2__alerts {
  .alert:first-child {
    margin-top: 15px;
  }

  .alert:last-child {
    margin-bottom: 15px;
  }

  .alert {
    font-family: var(--font-family) !important;
    @extend .text-xs;
    border-radius: var(--rounded-lg);
    padding: 15px;
    margin: 5px  0;
  }

  .alert-warning {
    background-color: var(--color-warning-50);
    color: var(--color-gray-900);
  }

  .alert-ai {
    background-color: var(--color-ai-50);
    color: var(--color-ai-900);
  }

  .alert-success {
    background-color: var(--color-success-50);
    color: var(--color-gray-900);
  }

  .alert-error {
    background-color: var(--color-danger-50);
    color: var(--color-gray-900);

    a {
      color: var(--color-gray-900);
    }
  }

  padding: var(--p-1) var(--p-2);
  margin: 10px 0;

  .close {
    color: inherit;
    opacity: 1;
    line-height: var(--line-height-xs);
  }
}

/***************************/
/*        BUTTONS          */
/***************************/
.v2__button_icon {
  -webkit-box-shadow: none;
  box-shadow: none;

  .material-icons {
    @extend .text-sm;

    & + .text {
      margin-left: 5px;
    }
  }

  &:active, &:focus {
    outline: 0;
    outline-offset: 0;
  }
}

.v2__button_xs {
  @extend .p-2;
  min-height: var(--input-height-xs);
  height: var(--input-height-xs);
}

.v2__button_primary {
  @extend .v2__button_icon;
  background-color: var(--color-gray-900);
  border-color: var(--color-gray-900);
  color: var(--color-white);

  &:hover, &:active {
    background-color: var(--color-white);
    border-color: var(--color-gray-900);
    color: var(--color-gray-900);
  }
}

.v2__button_secondary {
  @extend .v2__button_icon;
  background-color: var(--color-white);
  border-color: var(--color-gray-100);
  color: var(--color-gray-900);

  &:hover, &:active {
   background-color: var(--color-primary-50);
   border-color: var(--color-gray-900);
  }

  .material-icons.iconActive {
    display: none;
  }

  &.active {
    background-color: var(--color-gray-900);
    border-color: var(--color-gray-900);
    color: var(--color-white);

    .material-icons.iconActive {
      display: block;
    }
  }
}

.v2__button_secondary-success {
  @extend .v2__button_icon;
  background-color: var(--color-white);
  border-color: var(--color-gray-100);
  color: var(--color-success-500);

  &:hover, &:active {
   background-color: var(--color-success-50);
   border-color: var(--color-success-300);
  }

  .material-icons.iconActive {
    display: none;
  }

  &.active {
    background-color: var(--color-success-500);
    border-color: var(--color-success-500);
    color: var(--color-white);

    .material-icons.iconActive {
      display: block;
    }
  }
}

.v2__button_light {
  @extend .v2__button_icon;
  background-color: var(--color-white);
  border-color: var(--color-gray-100);
  color: var(--color-gray-800);

  &:hover, &:active {
   color: var(--color-primary-500);
   background-color: var(--color-primary-50);
   border-color: var(--color-primary-400);
  }
}

.v2__button_ai {
  @extend .v2__button_icon;
  background-color: var(--color-ai-900);
  border-color: var(--color-ai-900);
  color: var(--color-white);

  &:hover, &:active {
    color: var(--color-white);
    background-color: var(--color-ai-500);
    border-color: var(--color-ai-500);
  }
}

.link-ai {
    color: var(--color-ai-900);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: var(--color-ai-500);
    }
}

.v2__button_ai_light {
  @extend .v2__button_icon;
  background-color: var(--color-white);
  border-color: var(--color-gray-100);
  color: var(--color-ai-900);

  &:hover, &:active {
    color: var(--color-ai-900);
    background-color: var(--color-white);
    border-color: var(--color-ai-900);

  }
}

.text-ai {
  color: var(--color-ai-900) !important;
}

.v2__button_success {
  background-color: var(--color-success-500);
  border-color: var(--color-success-500);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-success-300);
    border-color: var(--color-success-300);
    color: var(--color-white);
  }
}

.v2__button_danger {
  @extend .v2__button_icon;
  background-color: var(--color-danger-500);
  border-color: var(--color-danger-500);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-danger-300);
    border-color: var(--color-danger-300);
    color: var(--color-white);
  }
}


.v2__button_danger_light {
  @extend .v2__button_icon;
  background-color: var(--color-white);
  border-color: var(--color-gray-100);
  color: var(--color-danger-500);

  &:hover {
    border-color: var(--color-danger-500);
  }
}

.v2__button_warning {
  @extend .v2__button_icon;
  background-color: var(--color-warning-500);
  border-color: var(--color-warning-500);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-warning-300);
    border-color: var(--color-warning-300);
    color: var(--color-white);
  }
}

.v2__button_hover-danger {
  @extend .v2__button_secondary;

  &:hover {
    background-color: var(--color-danger-500);
    border-color: var(--color-danger-500);
    color: var(--color-white);
  }
}

.v2__ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}


/***************************/
/*         BADGES          */
/***************************/
.v2__badge {
  @extend .text-2xs;
  font-weight: var(--font-bold);
  background-color: var(--color-danger-500);
  color: var(--color-white);
  width: auto;
  padding: 1px 3px 0 4px;
  min-width: 13px;
  text-align: center;

  &:empty {
    display: none;
  }
}



/***************************/
/*         TAGS            */
/***************************/
.v2__tag {
  @extend .px-2;
  @extend .text-sm;

  display: inline-flex;
  align-items: center;
  background-color: var(--color-primary-200);
  color: var(--color-gray-800);
  margin: 0.2rem;
  border-radius: var(--rounded);

  [data-role="remove"] {
    position: initial;
  }
}

/***************************/
/*       Dropdown          */
/***************************/
.v2__drodown_toggle {
  padding: 5px;
  border: 1px solid var(--color-gray-100);
  border-radius: var(--rounded-lg);
  background-color: transparent;
  color: var(--color-gray-300);
  display: flex;
  align-items: center;

  .material-icons {
    color: inherit;
    font-size: 20px;
    line-height: 20px;
  }

  &:hover {
    border: 1px solid var(--color-primary-500);
  }
}

.dropdown-backdrop {
  z-index: 7;
}

.v2__dropdown_menu {
  right: 0;
  left: auto;
  box-shadow: var(--shadow-box);
  border: 1px solid var(--color-gray-50);

  a, label, button {
    text-align: left;
    transition: background-color 0.3s ease;
    color: var(--color-gray-800);
    padding: 10px !important;
    width: 100%;
    border: 0;
    display: block;
    margin: 0;
    font-weight: var(--font-normal) !important;
    background-color: transparent;
    border-radius: 0;
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    cursor: pointer;

    &:hover {
      text-decoration: none;
      background-color: var(--color-primary-100);
    }

    &.dropdown-item-danger {
      color: var(--color-danger-500);

      &:hover {
        background-color: var(--color-danger-100);
      }
    }

  }

  span.separator {
    height: 1px;
    display: block;
    background-color: var(--color-primary-50);
    border: 0;
    margin: 5px 0;
    overflow: hidden;
  }
}


/***************************/
/*        AVATAR           */
/***************************/
.avatar_default {
  width: 32px;
  height: 32px;
  min-width: 32px;
  font-size: 14px;
}

.avatar_xs {
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 8px;
}

.avatar_xl {
  width: 48px;
  height: 48px;
  min-width: 48px;
  font-size: 20px;
}

.avatar_xxl {
  width: 64px;
  height: 64px;
  min-width: 64px;
  font-size: 30px;
}



/***************************/
/*        OTHERS           */
/***************************/
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}


.remove-link {
  margin-top: 30px;
  margin-left: 5px;
  color: var(--color-gray-800);
  cursor: pointer;
}

.flex-grow {
  flex-grow: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.align-self-start {
  align-self: start;
}
.align-self-end {
  align-self: end;
}

.justify-end {
  justify-content: flex-end;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.pd-15 {
  padding: 15px;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

::-webkit-scrollbar {
  color: var(--color-primary-100);
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: var(--rounded-lg);
  background-color: var(--color-gray-100);
  box-shadow: none;
}

.with-dot {
  position: relative;

  &:before {
    content: "•";
    color: var(--color-danger-500);
    width: 5px;
    position: absolute;
    left: 0;
  }

  &.with-times:before {
    content: "×";
  }

  &.primary:before {
    color: var(--color-primary-300);
  }
}

.container_loader {
  min-height: 150px;
}

.print-only {
  display: none;
}
@media print {
  .print-only {
    display: block;
  }
}

.text-danger {
    color: var(--color-danger-500) !important;
  &:before {
    color: var(--color-danger-500) !important;
  }

}

/// SY
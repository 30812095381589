.table-container {
  max-height: 600px;
}

.table-admin {
  @media screen and (max-width: 768px) {
    max-width: 100%;
    overflow: auto !important;
    display: block;

    th,.actions {
      white-space: nowrap;
    }
  }
}

table.documents, table.table-symptoms, .table-cards, .table-admin {

  background-color: var(--color-white);
  color: var(--color-gray-900);
  font-family: var(--font-family) !important;
  position: relative;

  border-collapse: separate;
  border: 1px solid var(--color-gray-50);
  border-top: 0;
  border-radius: var(--rounded-lg);
  box-shadow: var(--shadow-box);
  overflow: visible;

  thead {
    @extend .text-xs;

    color: var(--color-gray-300) !important;
    font-family: inherit;
    text-transform: uppercase;
    font-weight: var(--font-bold);

      tr:first-child {
        background-color: transparent;
      }

      th:first-child {
        border-top-left-radius: var(--rounded-lg);
      }

    th:last-child {
      border-top-right-radius: var(--rounded-lg);
    }

    th {
      background-color: var(--color-white);
      padding: inherit;
      height: 25px;
      line-height: 25px;
      position: -webkit-sticky;
      position: sticky !important;
      top: 0 !important;
      // Else it is under the dropdown
      z-index: 9;
      border-bottom: 1px solid var(--color-gray-50) !important;
      border-top: 1px solid var(--color-gray-50) !important;
    }

    th.sortable {
        padding-right: 10px;

        a {
          text-decoration: none;
          color: var(--color-gray-300) !important;
          font-weight: var(--font-bold) !important;
          @extend .text-xs;
          position: relative;

          &:after {
            text-transform: none;
            font-family: var(--font-family-icon);
            content: 'sort';
            margin-left: 10px;
            @extend .text-base;
            position: absolute;
            cursor: pointer;
          }
        }

        &.sorted {
          a {
            color: var(--color-primary-500) !important;
            &:after {
            color: var(--color-primary-500);
            }
          }
       }
      }
  }

  tr {
    border: 0 !important;
    padding: 10px 15px;
  }

  tr:nth-child(odd) {
    background-color: var(--color-primary-50);
  }

  tr.selected {
    background: var(--color-primary-100);
  }

  tr.loader-container {
    height: 150px;
  }

  tr.sub_header {
    background-color: var(--color-primary-200);
    padding: 5px 15px;
    font-weight: var(--font-semibold);

    td {
      @extend .text-xs;
    }
  }

  tbody tr {
    &.sub_header {
      td:first-child {
        text-align: right;
      }
    }

    &.sub_fields {
      td {
        @extend .text-xs;

        &.r-question {
          text-align: right;
        }
      }
    }

    td {
      @extend .text-sm;


      .list-icon {
        color: var(--color-gray-700);
        font-size: var(--font-size-sm);

        &.text-ai {
          color: var(--color-ai-900);
        }
      }


      padding: inherit;
      border: 0 !important;
      background-color: transparent;
      color: inherit;
      font-family: inherit;
      height: 25px;
      line-height: 25px;

      a:not(.btn,.dropdown-item), .r-answer {
        color: var(--color-gray-900) !important;
      }

      .r-answer {
        @extend .v2__ellipsis;
      }

      a.table-icon, button.table-icon {
        .material-icons {
          color: var(--color-gray-700);
          font-size: var(--font-size-lg);
        }
      }

      a.table-icon:hover, button.table-icon:hover {
        text-decoration: none !important;

        .material-icons {
          color: var(--color-gray-800);
        }
      }

      button.table-icon {
        background-color: transparent;
        border: 0;
        padding: 0 !important;
      }
    }

  }

  &.table-symptoms {
    border-collapse: collapse;
    border: 0;
    cursor: pointer;

    thead th {
      border: 0 !important;
    }

    th {
      border: 0;
      @extend .text-sm;
      font-weight: var(--font-semibold);
      font-family: var(--font-family);
      padding: 15px;
      position: relative !important;
      border-radius: 0;
      color: var(--color-gray-900) !important;

      &:first-child {
        border-top-left-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
      }

      &.text-left {
        font-weight: var(--font-semibold);
        color: var(--color-gray-900) !important;
        text-transform: none;
      }
    }

    .content_text {
      font-size: var(--font-size-sm) !important;
      display: block;
    }


    th.section_title {
      background-color: var(--color-primary-200);
      padding: 10px 15px;
    }

    th.blacked, td.blacked {
      background-color: var(--color-gray-50) !important;
      color: var(--color-gray-600) !important;
      opacity: 0.3;
      border: 0 !important;

      label {
        color: inherit;
      }
    }

    tr:nth-child(odd) {
      background-color: transparent;

      td {
        background-color: var(--color-primary-50);
      }
    }

    .free-text {
      .material-icons {
        font-size: var(--text-sm);
        display: inline-block;
        vertical-align: middle;
        text-align: center;
      }
    }
  }

  td.content_preview_doc {
    width: 100%;

    .doc-preview {
      left: 50%;
      top: 45%;
      position: fixed;
      z-index: 9000;
    }
  }

  .td_doc_checkbox {
    min-width: 47px;
  }

  .td_doc_date,.td_user_last_login {
    min-width: 110px;
  }

  .td_doc_type {
    min-width: 150px;
  }
  .td_medic_doc_type {
    min-width: 250px;
  }
  .td_user_mail {
    min-width: 120px;
  }

  .td_doc_source,.td_user_services {
    min-width: 180px;
  }

  .td_user_name {
    min-width: 200px;
  }

  .td_user_status {
    min-width: 80px;
  }

  .td_doc_status,.td_user_phone {
    min-width: 120px;
  }

  .td_doc_actions {
    min-width: 20px;
    // For pop-up
    overflow: visible;
  }

  .td_user_actions {
    min-width: 190px;
    max-width: 190px;
  }

  .td_doc_actions_var {
    min-width: 190px;
  }

  .td_user_avatar {
    min-width: 32px;
    padding-right: 0;
    max-width: 32px !important;
    width: 32px;
    box-sizing: content-box;
  }

  .thumb {
    border-radius: 100%;
    overflow: hidden;
    width: 100%;

  }

  .dropdown-toggle {
    margin: 0 auto;
    @extend .v2__drodown_toggle;
  }

  .dropdown-menu {
    z-index: 8;
    @extend .v2__dropdown_menu;
  }

}

.V2__faq {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;

  > .box {
    flex: 0 0 auto;
    margin: 0;

    #TheTexte, .text-content, .answers_content {
      color: var(--color-gray-900);
      p, span, div {
        color: var(--color-gray-900);
      }
    }
  }

  .answers_list {
    .answers, .v2_tips {
      border-bottom: 1px solid var(--color-gray-50);
      display: flex;
      flex-direction: row-reverse;
    }

    .answers_actions {
      padding: 15px;
      flex: 1 1 80px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      .material-icons {
        @extend .text-xl;
        color: var(--color-gray-700);

        &:hover {
          color: var(--color-primary-500);
          background-color: transparent;
        }
      }

      button {
        &:hover {
          background-color: transparent;
        }
        margin: 0;
        padding: 0 !important;
      }
    }

    .answers_content {
      background-color: transparent;
      padding: 15px;
      align-self: center;
      @extend .text-sm;
    }

  }

  .tips_list.supervision-menu {
    border: 0;
    box-shadow: none;
  }
}

.faq_head {
  margin-top: 10px;
}

.supervision_header {
  .faq-search {
    .search-style {
      @extend .search-style;
    }
  }
}

#faq {
    .supervision-menu {
      max-height: calc(70vh);
      .supervision-item .banner {
        border: 0;
      }
    }

    .empty {
      color: var(--color-gray-300);
      font-style: italic;
    }

    #TheTexte, .text-content {
      p, span, div {
        color: var(--color-gray-900);
      }
    }
}



@include lg {
  .V2__faq {
    flex-direction: row;
    > .box {
      flex: 1 1 45%;
    }
  }
}

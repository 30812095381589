.questionnaire {
  .pass {
    margin-top: 15px;
    margin-right: 0;
  }

  label.required::after {
    content: none;
  }

  .tab.small-tab {
    padding: 0;
  }

  .form_section {
    margin-top: 15px;
    margin-bottom: 0 !important;
  }

  .help_text {
    padding: 15px;
    margin-bottom: 0;
  }

  .section_answer {
    display: flex;
    border-bottom: 1px solid var(--color-primary-100);
    padding: 15px 0;

    &:last-child {
      border: 0;
    }

    .form-group {
      margin-bottom: 0;
    }

    .counter {
      flex: 0 0 auto;
      @extend .text-2xl;
      color: var(--color-primary-500);
      font-weight: var(--font-bold);
      text-align: center;
      padding: 5px 35px;
      font-size: 50px;
      opacity: 0.1;
      padding-left: 20px;
      width: 90px;
    }

    .question_container {
      flex: 1 1 auto;

      input, textarea, select {
        width: calc( 100% - 15px);
      }

      textarea {
        margin-top:15px;
      }

      .row {
        .col-form-label {
          padding-top: 0;
          padding-right: 0;
          margin: 0;
          min-height: auto;

          &.required {
            .required_symbol {
              display: inline-block !important;
              color: var(--color-danger-500);
            }
          }
        }
      }
    }

    .see-more {
      width: 30px;
      text-align: right;
      position: absolute;
      right: -15px;
      font-size: 20px;

      &:hover {
        text-decoration: none;
      }
    }

    .col-sm-7 {
      padding-left: 0;
      height: 100%;
    }
  }

  .form-with-sep .form-group:nth-child(2n) {
    background-color: transparent;
  }

  .form-validate {
    margin-right: 0;
    padding-right: 0;
    margin-left: 0;
    padding-left: 0;

    button {
      margin:0;
    }
  }
}

.report_form_info {
  padding-top: 10px;
  margin-bottom: 5px;
}

.medical_data .question_container .row,
.questionnaire .section_answer .question_container .row {
  .radio-scale-container {
    &.v2-scale {
      .radio-scale {
        margin-bottom: 1px;
      }

      label {
        padding-left: 15px !important;
      }
    }

    &:not(.v2-scale) {
      .radio-scale {
        margin-bottom: 10px;

        label {
          padding: 10px;
        }
      }
    }

    .radio-scale {
      border: 1px solid var(--color-gray-100);
      border-radius: var(--rounded-md);

      .form-check {
        label {
          width: 100%;
          margin: 0;
          height: 100%;
          display: block;
          border: 0;
        }

        input[type="radio"]:checked + label {
          background-color: var(--color-primary-500);
          color: var(--color-white);
          border-radius: var(--rounded-md);
        }
      }
    }
  }
}

/*** Admin ***/
.v2_admin_questionnaire {
  .notice {
    .material-icons {
      vertical-align: middle;
      font-size: var(--font-size-sm);
    }
  }

  form {
    display: flex;
    gap: 15px;
    flex-direction: column;

    .small {
      display: inline-block;
    }

    .step-1 {
      flex: 0 0 500px;

      > .box {
        overflow: visible;
      }
    }

    .step-2 {
      flex: 1 1 auto;
      min-width: 0;
    }
  }

  .btn.add-question, .btn.add-section {
    width: 200px;
    align-self: end;
    margin: 0;
  }

  .btn.add-section {
    margin-bottom: 15px;
  }


  .sc, .qs {
    padding: 0;
  }

  .questions {
    background-color: yellow;
  }

  .qs:not(.expanded) .qs-expandable {
    display: block;
  }

  .qs-expandable {
    .v2__row_grid_forms-4 {
      margin-bottom: 10px;
      flex-wrap: wrap;
      display: flex;
      gap: 10px;

      .v2_grid_form {
        flex: 1 1 100%;
      }
    }
  }

  .supervision-menu-questionnaire.section {
    box-shadow: none;
    margin-top: 15px;
    border: 0;
    border-top: 1px solid var(--color-gray-50);
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;

    .supervision_item_header_title {
       .main {
         display: flex;
         flex-direction: row;
         gap: 5px;
         align-items: start;


         .material-icons {
           color: var(--color-primary-500);
           margin-right: 0;
         }

         .title_zone {
           display: flex;
           flex-direction: row;
           gap: 5px;
           align-items: start;
           padding-top: 2px;


           .question_label {
              color: var(--color-success-500);
           }

            .section_label {
              color: var(--color-primary-500);
            }

           .question_label, .section_label {
              flex: 0 0 auto;
           }
         }


       }
    }
  }

  .supervision-menu-questionnaire.questions {
    padding: 0;
    margin-top: 2px;
    box-shadow: none;

    .supervision_item_header_title {
       .main {
         .material-icons {
           color: var(--color-success-500);
         }
       }
    }

    .supervision_item_content {
        background-color: var(--color-white);
    }

    .qs:last-child .supervision-item, .sc:last-child .supervision-item {
      border-bottom: 0;
    }
  }

  .supervision_item_banner_actions {
    flex: 0 0 120px;
  }

  .supervision_item_header_title {
    .main {
      .title_zone {
        overflow: hidden;

        .question_title, .section_title {
          flex: 1 1 auto;
           @extend .v2__ellipsis;
        }
      }
    }
  }

  .qs:not(.expanded), .sc:not(.expanded) {
    opacity: 1;
  }

  .qs-list {
    min-height: auto;
  }

  // alertes
  .alerts {
    .priority-1, .priority-2, .priority-3, .priority-4 {
      background-color: transparent;
    }

    .priority-1 {
      background-color: tranparent;
      color: var(--color-success-500);
    }

    .priority-2 {
      color: var(--color-primary-500);
    }

    .priority-3 {
      color: var(--color-warning-500);
    }

    .priority-4 {
      color: var(--color-danger-500);
    }

    .remove-alert, .remove-condition {
        position: absolute;
        right: 5px;
        padding: 5px;
        top: 5px;
        color: var(--color-gray-500);

        .material-icons {
          font-size: var(--font-size-xs);
        }
    }

    .remove-alert {
      top: 0px;
    }

    .v2_condition_child {
      display: flex;
      gap: 10px;

      .v2_grid_form {
        flex: 1 1 auto;
      }
    }

    .alrt {
      background-color: var(--color-primary-50);
      padding: 10px;
      margin-bottom: 15px;
      display: flex;
      gap: 10px;
      position: relative;

      .counter_content {
        color: var(--color-warning-500);
      }

      .v2_alert_container {
        flex: 1 1 auto;
      }

    }

    .subtitle {
      margin: 10px 0;
      font-size: var(--font-size-xs);
      font-weight: var(--font-semibold);
      position: relative;
    }

    .conditions {
      position: relative;

      .v2_condition_child {
        background-color: var(--color-white);
        padding: 10px;
        margin-bottom: 10px;
        position: relative;
      }

      .v2_condition_child:first-child {
        .remove-condition {
          display: none;
        }
      }
    }
  }
}

.questionnaire {
  form.full {
    .edit-profile-action {
      margin-top: 15px;
    }
  }
}

@include md {
  .v2_admin_questionnaire {
    .qs-expandable {
      .v2__row_grid_forms-4 {
        display: flex;
        flex-wrap: nowrap;

        .v2_grid_form {
          flex: 1 1 auto;
        }
      }
    }
  }
}

@include xl {
  .v2_admin_questionnaire {
    form {
      flex-direction: row;

      .step-1 {
        max-width: 500px;
      }
    }
  }
}

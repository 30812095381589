/**
 *  SUMMARY
 *   - PATIENT_LIST
 *     - HEADER
 *     - LIST_BOX
 *     - MODE_BOX
 *     - MODE_LIST
 *   - MEDICAL_LIST
 *   - DOT_LIST
 *     - ALERTS
 *   - ALERTS_LIST
 *
 **/
.v2__list {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: visible;

  // ///////////////
  //   PATIENT_LIST
  // ///////////////

  // ///////////////
  //   HEADER
  // ///////////////
  .list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-50);
    padding: 0 20px;
    height: var(--box-header-height);

    .list__header__search {
      @extend .search-style;

      &.patients__list {
        min-width: 250px;

        .patients__list__search,
        .patients__list__search > input {
          width: 100%;
        }
      }
    }

    .list__header__tabs {
      flex-grow: 1;
      .nav-tabs {
        margin: 0;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
      }

      .nav-link {
        font-family: var(--font-family);
        font-weight: var(--font-medium) !important;
        @extend .text-sm;
        color: var(--color-gray-500);
        background-color: var(--color-white);
        padding: 5px;
        border-radius: var(--rounded-lg) !important;
        padding: 6px 7px;
        margin: 0 3px;

        &, &:hover {
          border: 0;
          text-decoration: none;
        }

        &:hover {
          color: var(--color-gray-500);
          background-color: var(--color-gray-100);
        }

        &.active {
          color: var(--color-gray-700);
          background-color: var(--color-gray-100);

          &:hover {
            color: var(--color-gray-900);
          }
        }
      }
    }

    .list__header__actions {
      display: flex;

      .list__header__fields {
        margin-right: 10px;
      }

      select {
        @extend .p-2;
        height: var(--input-height-xs);
        min-height: var(--input-height-xs);
      }
    }

    h2 {
      margin-bottom: 0;
      font-size: var(--font-size-base);
      line-height: var(--line-height-base);
    }

    button {
      height: 32px;
    }
  }

  // ///////////////
  //   LIST_BOX
  // ///////////////
  .list__content  {
    .tab.tab-users, .col-sm-12 {
      padding: 0;
      overflow: auto;
      min-height: auto;
    }

    #list-content {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
      grid-gap: 15px;
      padding: 15px;

      &.user_calendar_list {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      }

      &.empty {
        display: block;
      }

      p.not-found {
        width: 100%;
        text-align: center;
        padding-top: 20px;
      }

      .loader {
        margin-top: 30px;
      }

      &.template-list {
        padding-left: 0;
        padding-right: 0;
      }

      > * {
        height: 100%;
      }

      .thumb {
        border-radius: 100%;
        overflow: hidden;

        &:hover, img:hover {
          box-shadow: none;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .label {
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        font-family: var(--font-family);
        font-weight: var(--font-semibold);
        color: var(--color-gray-300);
        @extend .text-xs;
      }

      .list__content__header {
        display: none;
      }

      span.profile-status {
        top: 15px;
        right: 15px;

        .status-1 {
          background-color: var(--color-success-500) !important;
        }

        .status-2, .status-4 {
          background-color: var(--color-warning-500) !important;
        }

        .status-3, .status-5 {
          background-color: var(--color-danger-500) !important;
        }

        .status-6 {
          background-color: var(--color-gray-500) !important;
        }
      }
    }

    // ///////////////
    //   MODE_BOX
    // ///////////////
    .patient-box {
      padding: 0;
      margin: 0;
      @extend .transition-colors;


      &.blurred .box {
        filter: blur(2px);
        opacity: 0.8;
        pointer-events: none;
      }

      &.active .box{
        border: 1px solid black;
      }

      &:hover {
        text-decoration: none;
        border-color: var(--color-gray-100);
        background-color: var(--color-primary-50);
      }

      a, .box-flex {
        display: flex;
        padding: 10px 20px;
        margin: 0;

        &:hover {
          text-decoration: none;
        }

        &, span.data {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        > span {
          margin-bottom: 20px;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;

        .thumb {
          width: 48px;
          height: 48px;
        }

        .flags {
          display: flex;
          justify-content: flex-end;

          > span {
            position: initial;
            top: initial;
            right: initial;
            width: 15px;
            height: 15px;

            &.text-danger {
              color: var(--color-danger-500);
            }

            &.text-info {
              color: var(--color-gray-500);
            }

            &.profile-status {
              padding: 2px;

              span {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }

      span {
        font-weight: var(--font-normal);
        color: var(--color-gray-900);
        @extend .text-xs;
      }

      .title {
        &, span {
          font-weight: var(--font-semibold);
          color: var(--color-gray-900);
          @extend .text-base;
        }
      }

      .data-email {
        @extend .v2__ellipsis;
      }

      &.user_calendar_box {

        a.patient-link {
          flex-direction: row;
          padding: 10px;
          display: inline-flex;
          gap: 10px;
          width: 100%;
          max-width: 100%;

          .header {
            flex: 0 0 auto;
            margin-bottom: 0;
          }

          .user_infos {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            max-width: calc( 100% - 58px);


            .title {
              @extend .v2__ellipsis;
              flex: 0 0 auto;
              width: 100%;
            }
          }
        }
      }
    }


    // ///////////////
    //   MODE_LIST
    // ///////////////
    #list-content.template-list {
      display: table;
      border-collapse: collapse;
      overflow: visible;

      .patient-list {
        display: table-row-group;
      }

      .list__content__header,
      .patient-link {
        display: table-row;
        & > span {
          display: table-cell;
          height: 50px;
          > span {
              @extend .v2__ellipsis;
          }
        }
      }

      .list__content__header {
        & > span {
          height: 45px;
        }
        border-bottom: 1px solid var(--color-gray-50);
        background-color: var(--color-white);
        position: sticky !important;
        top: 0px !important;
        box-shadow: var(--shadow-box);

        span.label {
          vertical-align: middle;
        }

        .thumb {
          min-width: 52px;
        }
      }

      &.empty .list__content__header {
        display: none;
      }
    }

    .patient-list:nth-child(odd) {
      background-color: var(--color-primary-50);
    }

    .patient-list {
      .patient-link > span {
        padding: 0;
        margin: 0;
        text-align: left;
        vertical-align: middle;
      }

      .actions {
        width: 20px;
      }

      span {
        color: var(--color-gray-700) !important;
      }

      &:hover {
        span {
          @extend .transition-colors;
          color: var(--color-gray-900) !important;
        }
      }

      span.thumb {
        border-radius: 0 !important;
        height: 100%;
        float: none;
        padding-left: 10px;
        min-width: 52px;
        width: 53px;

        > img {
          width: 32px !important;
          height: 32px !important;
          vertical-align: middle;
        }
      }

      .patient-link:hover {
        text-decoration: none;
      }

      span {
        color: var(--color-gray-900);
        @extend .text-sm;
      }

      .title {
        max-width: 350px;
        &, &.span {
          font-weight: var(--font-semibold);
        }
      }

      .actions {
        min-width: 35px;
        text-align: center !important;
      }
    }
  }

  // ///////////////
  //   MEDICAL_LIST
  // ///////////////

  #doctor-list {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 15px;
    padding: 15px;

    .box {
      margin: 0;
    }

    .patient-box .box {
      height: 100%;
    }
  }

  #requests-list, #emergency-list {
    padding: 15px 0;

    .patient-img {
      margin-right: 10px;
    }
    .patient-box {
      width: 100%;

      .box {
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }

  #doctor-list, #requests-list, #emergency-list {
    .patient-box {

      .name {
        font-weight: var(--font-semibold);
        color: var(--color-gray-900);
      }

      .box {
        overflow: visible;
      }

      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;


        .avatar {
          width: 48px;
          height: 48px;
        }
      }

      .contentAvatar {
        margin-right: 10px;
      }

      span {
        padding: 0;
        margin: 0;
        text-align: left;
        display: block;
      }

      span.data {
        margin-bottom: 20px;

        span:not(.label) {
          font-weight: var(--font-normal);
          color: var(--color-gray-900);
          @extend .text-xs;
        }
      }

      span.label {
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        font-family: var(--font-family);
        font-weight: var(--font-semibold);
        color: var(--color-gray-300);
        @extend .text-xs;
      }

      .title {
        &, &.span {
          font-weight: var(--font-semibold);
        }

        & a {
          font-weight: var(--font-semibold) !important;
          color: var(--color-gray-900) !important;
        }

        margin-bottom: 20px;
      }
    }
  }

  #doctor-list .update-team,
  #doctor-list .medic-toggle,
  #requests-list .update-invite,
  #emergency-list #toggle_contact {
    @extend .v2__drodown_toggle;
  }

  #doctor-list .dropdown-menu,
  #requests-list .dropdown-menu,
  #emergency-list .dropdown-menu,
  .list__header .dropdown-menu {
    @extend .v2__dropdown_menu;
  }

  #emergency-list .emergency_idCard {
    flex: 1 1 auto;
  }

  .report_select_questionnaire {
    padding: 15px 0;
    padding-top: 0;

    label {
      flex: 0 0 auto;
      margin-right: 15px;
      align-self: center;
    }
  }
}

#invitations-list {
  margin-top: 15px;

  .alert_invit {
    background-color: var(--color-primary-50) !important;
    color: #000 !important;
    padding: 15px;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    border: 1px solid gray !important;

    .user-icon {
      margin-right: 5px;
      background-color: rgba(162, 179, 242, 0.5);
      padding: 12px;
      color: rgb(2, 89, 202);
      border-radius: 50px;
    }

    .choices {
      display: block;

      a {
        height: 30px;
      }
    }
  }
}



/******************/
/*    DOT_LIST    */
/******************/
.v2__list_dot {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
  padding: 0;
  flex-grow: 1;
  max-height: 300px;
  overflow: auto;

  &.height_full {
    max-height: max-content;
  }

  &.half-size {
    max-height: 150px;
  }

  h3 {
    padding: 10px 15px 5px 15px;
    font-weight: var(--font-semibold) !important;
    font-family: var(--font-family) !important;
    color: var(--color-gray-900) !important;
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
    margin: 0;
  }

  .v2__list_item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 15px 10px 15px;
    gap: 5px;


    &.ia-suggestion {
      .val {
        font-weight: bold;
        color: var(--color-ai-900);
      }

      .ai-actions {
        display: none;
      }

      &:hover {
        .ai-actions {
          display: block;
          position: absolute;
          bottom: -8px;
          z-index: 10;
          background: var(--color-white);
          border: 1px solid var(--color-gray-100);
          padding: 2px 5px 0 5px;
          right: 0;
          box-shadow: 0 0 2px;
          border-radius: 6px;

          button {
            background: none;
            border: none;
            padding: 2px;
            i {
              font-size: 12px;
            }
          }
        }
      }

    }

    &.flex-row {
      flex-direction: row;
    }

    .avatar {
      @extend .avatar_default;
    }

    .avatar,.v2_rounded_avatar {
      margin-right: 5px;
      border-radius: 100%;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-primary-50);
    }

    &:before {
      color: var(--color-gray-900);
      left: 10px;
      top: 11px;
      width: 10px;
      line-height: var(--line-height-xs);
    }

    &.danger:before {
      color: var(--color-danger-300);
    }

    &.success:before {
      color: var(--color-success-300);
    }

    &.warning:before {
      color: var(--color-warning-300);
    }

    &:hover {
      background-color: var(--color-primary-50);
      border-color: var(--color-gray-50);
    }

    &:hover {
      &, span {
        text-decoration: none;
      }
    }

    &.without-title {
      &:before {
        top: 9px;
      }
    }

    .v2__list_col {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: calc(100% - 40px);

      &.no-grow {
        flex-grow: 0;
        max-width: auto;
      }
    }

    &.flex-row {
      flex-wrap: nowrap;
    }

    .v2__list_row {
      display: flex;
      align-items: center;
    }

    .title {
      flex-grow: 1;
      @extend .v2__ellipsis;
    }

    .date, .subtitle {
      @extend .text-xs;
      color: var(--color-gray-400);
    }


    .title, .date.main,.val {
      @extend .text-sm;
      color: var(--color-gray-900);
    }

    .date {
      flex-shrink: 0;
      align-self: flex-end;

      &.main.hour {
        min-width: 39px;
      }
    }

    .subtitle {
      flex-grow: 1;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .title, .description, .tags {
        @extend .text-xs;
        font-weight: var(--font-normal);
      }

      .description {
        color: var(--color-gray-400);
      }

      .tags {
        font-weight: var(--font-medium);
        @extend .text-xs;

        .items {
          display: inline-block;
          @extend .px-2;
          align-items: center;
          background-color: var(--color-primary-200);
          color: var(--color-gray-800);
          border-radius: var(--rounded-lg);
          margin: 0.2rem;
          white-space: nowrap;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
    }

    &.with-dot {
      padding-left: 25px;
    }
  }
}

/*****************************/
/*        DISCUSSION         */
/*****************************/
#messages-box .v2__list_item {

  &.unread {
    background-color: var(--color-primary-100);
  }

}


/***************************/
/*         ALERTS          */
/***************************/
#alerts-box,#answersGroups-box,#recentPatients-box,#messages-box,#pendingMedicalInvoices-box {
  .empty {
    display: none;
  }

  &.empty .empty {
    display: block;
  }

  .v2__list_item {
    &::before, .title {
      color: var(--color-gray-800);
    }

    &.priority-1 {
      &::before, .title {
        color: var(--color-success-500);
      }

      &:hover {
        background-color: var(--color-success-50);
      }
    }

    &.priority-2 {
      &::before, .title {
        color: var(--color-primary-500);
      }

      &:hover {
        background-color: var(--color-primary-100);
      }
    }

    &.priority-3 {
      &::before, .title {
        color: var(--color-warning-500);
      }

      &:hover {
        background-color: var(--color-warning-50);
      }
    }

    &.priority-4 {
      &::before, .title {
        color: var(--color-danger-500);
      }

      &:hover {
        background-color: var(--color-danger-50);
      }
    }
  }
}

.rapport_auto_eval {
  .content_filter_button {
    align-self: end;
    padding-bottom: 5px;
  }
}

/******************/
/*  ALERTS_LIST   */
/******************/
#alert-list,#recentPatients-box {
  .empty-banner {
    width: 100%;
  }

  .v2__block {

    &.priority-1 {
      h2 {
        color: var(--color-success-500);
      }

      .v2__block_header {
        background-color: var(--color-success-50);
      }
    }

    &.priority-2 {
      h2 {
        color: var(--color-primary-500);
      }

      .v2__block_header {
        background-color: var(--color-primary-50);
      }
    }

    &.priority-3 {
      h2 {
        color: var(--color-warning-500);
      }

      .v2__block_header {
        background-color: var(--color-warning-50);
      }
    }

    &.priority-4 {
      h2 {
        color: var(--color-danger-500);
      }

      .v2__block_header {
        background-color: var(--color-danger-50);
      }
    }
  }

  .form_report {
    padding: 15px;
    display: flex;
    flex-direction: column;

    > div {
      > * {
        @extend .text-xs;
        font-family: var(--font-family);
      }

      strong {
        color: var(--color-gray-900);
        font-weight: var(-font-semibold);
      }

      > span {
        color: var(--color-gray-900);
        font-weight: var(--font-normal);
      }
    }
  }
}

.manage_duplicate {
  .duplicate_container_content {
    padding: 15px;

    .infoColor {
      font-weight: var(--font-semibold);
      color: var(--color-black);
    }

    .duplicate_list {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 15px;

      .duplicate_block:first-child {
          margin-left: 0;
      }

      .duplicate_block {
        padding: 10px;
        @extend .v2__box_shadow;
        display: flex;
        flex-direction: column;

        .duplicate_container_card {
            display: flex;
            flex-direction: row;
            height: 100%;

            .duplicate_card:first-child {
              border-right: 1px solid var(--color-gray-50);
              margin-right: 10px;
              padding-right: 10px;
            }

            .duplicate_card {
              display: flex;
              flex-direction: column;
              flex: 0 0 50%;
              max-width: 50%;
              overflow: hidden;

              span {
                display: block;
                margin: 10px 0;
              }

              span.name {
                font-weight: var(--font-semibold);
                color: var(--color-gray-900);
                overflow: hidden;
                text-overflow: ellipsis;
              }

              img.v2_user_avatar {
                @extend .avatar_default;
                border-radius: 100%;
              }
            }
        }

        .duplicate_button_container {
          margin-top: 15px;
          flex-grow: 1;
          align-items: end;
          display: flex;
        }
      }
    }
  }

  .manual_form {
    button {
      float: right;
    }
  }
}

.grid-2 {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 5px;
}
.grid-3 {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 5px;
}

@media screen and (max-width: 772px) {
  .grid-2,.grid-3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/**
 *  SUMMARY
 *    - GENERICS
 *    - CONFIRM_VISIO
 *    - CONFIRM_PHONE
 *    - UPLOAD
 *    - ONBOARDING
 *    - PAPIER_ENTETE
 *    - 2FA
 *    - POPIN_PHONE
 *    - LOGIN
 *    - DOCUMENTS
 *    - PATIENT_SELECTION
 */

/*********************/
/*     GENERICS      */
/*********************/

.popin-fullscreen {
  height: 93vh;
  max-height: 93vh;
}

.popin-action {
  border: 1px solid var(--color-gray-100);
  border-radius: var(--rounded-lg);
  padding: 0;
  box-shadow: var(--shadow-box-popup);
  pointer-events: all;

  &.popin-with-overlay {
    outline: none;
  }

  .v2__block {
    overflow: visible;
    height: auto;
    min-height: auto !important;
  }

  .popin-header {
    .close-box {
      color: var(--color-gray-300);

      font-size: var(--font-size-lg);
      line-height: var(--line-height-lg);

    }
  }

  .view, .popin-content, .popin_content {
    padding: 14px;
  }

  .popin_content {
    p.main {
      @extend .text-sm;
      font-weight: var(--font-semibold);
      color: var(--color-gray-900);
    }

    p.description {
      color: var(--color-gray-800);
      text-align: justify;
    }
  }

  .popin_alerts {
    @extend .v2__alerts;

    &, .alert {
      &, &:last-child, &:first-child {
        margin: 0;
      }
    }

    &:empty {
      display: none;
    }
  }

  .uploadDateContainer {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  &.popin-document-view {
    &.with-iframe {
      &.pdfviewver {
        height: 100%;

        iframe {
          height: calc(100% - 35px);
        }
      }

      .popin-header .actions .material-icons {
        @extend .text-lg;
      }
    }

    iframe {
      border: 0;
    }


    &.as-slider {
      opacity: 1;
      display: block;
      left: 5px;
      transform: none;
      top: 5px;
      bottom: 5px;
      max-height: calc(100% - 10px);

      @include xl {
         max-width: calc(100% - 610px);
      }

    }

  }


  .edit-profile-action {
    .btn, a, .label-inline {
      flex-grow: 1;

      .btn {
        width: 100%;
      }
    }
  }
}

/*********************/
/*   CONFIRM_VISIO   */
/*********************/
.popin-action.confirm_visio {
  p {
    @extend .text-sm;

    .red, .green {
      font-weight: var(--font-bold);
    }

    .red {
      color: var(--color-danger-500);
    }

    .green {
      color: var(--color-primary-500);
    }
  }
}

.popin-action .popin-content {
  /*********************/
  /*   CONFIRM_PHONE   */
  /*********************/
  .code-block {
    .h2 {
      @extend .text-sm;
      border: 0;
    }
  }

  /*********************/
  /*      UPLOAD       */
  /*********************/
  p,
  .field-group .row {
    margin-bottom: 12px;
  }

  .imgs {
    border-color: var(--color-gray-50);
    border-radius: var(--rounded);
    overflow: hidden;
  }

  .controls i {
    border-color: var(--color-gray-300);
    color: var(--color-gray-300);
  }
}


/*********************/
/*    ONBOARDING     */
/*********************/
.voile-onboarding {
  .onboarding--message {
    font-family: var(--font-family);
  }
}

.page_visio {
  .alert-call {
    display: none !important;
  }
}

/** Share **/
#share_modal {
  [class^="col-sm-"] {
    padding: 0;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .bootstrap-tagsinput {
    margin-bottom: 0;
  }

  #share_to_hospital.view, #share_to_doctor.view {
    padding-top: 6px;
  }

  #share_to_hospital {
    .form-col {
      padding: 0;
    }
  }

  #existing_medic {
    margin-bottom: 5px;

    .existing_user {
      color: var(--color-primary-500);
      display: flex;
      align-items: top;
      font-size: var(--font-size-xs);
      line-height: var(--line-height-xs);
      font-weight: var(--font-semibold);

      .material-icons {
        font-size: var(--font-size-sm);
        margin-right: 5px;
        margin-top: 3px;
      }
    }
  }

  .justify_checkbox {
    text-align: justify;
  }

  .btn {
    margin: 0;
  }

   .v2-autocomplete .input-loader {
    bottom: calc(50% - 14px);
  }

}

.popin-document {
  .popin-content {
    overflow: auto;
    max-height: calc(100vh - 100px);

    .imgs {
      overflow: auto;
      margin-bottom: 10px;
    }

    .controls {
      margin-top: -10px;
    }

    .upload-document {
      min-height: auto;
    }

    .form-validate {
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .patient-name {
      font-weight: var(--font-medium);
      @extend .text-xs;
    }
  }
}

.blur-background {
  filter: blur(10px);
  pointer-events: none;
}

/***********************/
/*    PAPIER_ENTETE    */
/***********************/
#letterhead-popin {
  overflow: hidden;

  .v2__block {
    height: 100%;
  }

  .header_actions {
    display: flex;
    gap: 15px;
    margin-right: 10px;

    .search_container {
      @extend .search-style;
    }

    .search_type_container {
      select {
        min-height: 32px !important;
        height: 32px;
      }
    }

    .back-to-list {
      position: relative;
      max-height: 32px;
    }
  }


  .letter-container {
    overflow: auto;
    height: calc(100% - 40px);

    .list {
      padding: 15px;
      gap: 5px;
      margin: 0;

      .letter-item {
        border: 0;
        background-color: var(--color-primary-100);
        border-radius: var(--rounded-lg);
        max-height: 350px;
        width: 200px;
        padding-top: 10px;
        align-items: center;

        &.currentTemplate {
          border: 1px solid var(--color-primary-500);
        }

        .add-icon {
          color: var(--color-primary-500);
        }

        .placeholder, img {
          border: 1px solid var(--color-white);
          border-radius: var(--rounded-lg);
        }

        .name {
          @extend .text-sm;
          @extend .v2__ellipsis;
          font-family: var(--font-family);
          font-weight: var(--font-semibold);
          padding: 5px;
        }

        .actions {
          justify-content: left;

          a {
            color: var(--color-gray-800);
            @extend .text-lg;
            width: 33%;

            .material-icons {
              font-size: inherit;
            }
          }
        }
      }
    }
  }

  .document_edit_left {
    margin: 0 !important;
    background-color: var(--color-primary-50);

    .mg-img {
      border: 1px solid var(--color-gray-50);
      overflow: visible;

      .action {
        background-color: var(--color-white);
        z-index: 20;
        padding: 0 15px;
        color: var(--color-gray-800);

        label, a {
          color: var(--color-gray-800);
        }
      }
    }

    .m-line {
      border-top: 1px solid var(--color-primary-500);
      border-left: 1px solid var(--color-primary-500);
    }

    .file_actions {
      position: absolute;
      display: inline-flex;
      gap: 15px;

      > * {
        display: block;
      }
    }

    .file-preview {
      background-color: white;
      z-index: 20;
    }

    .margin-editor {
      padding-top: 46px;
      padding-bottom: 46px;

      .margin-group {
        input {
          width: 60px;
          padding: 5px;
          height: 35px;
        }

        &.group_top {
          top: 5px;
        }

        &.group_bottom {
          bottom: 5px;
        }

        &.group_left {
          right: 530px;
        }

        &.group_right {
          left: 530px;
        }
      }

      .file-input {
        padding: 9px;
      }
    }

    .page_button {
      .btn-reverse {
        background-color: var(--color-white);
        color: var(--color-primary-500);
      }
    }
  }

  .document_edit_right {
    border: 0;
    padding: 0;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    > .form-group {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 10px;
      padding-top: 0;

      button {
        margin: 0 !important;
      }
    }

    .popin_alerts {
      margin: 0px -10px;
    }
  }
}

#add-patient--modal {
  .form-group {
    margin-bottom: 0;

    .small {
      padding-top: 5px;
      margin-bottom: 15px;
    }
  }
}

#vocal-note--modal {
  .waves {
    min-height: auto;
  }

  .form-col {
    padding: 0 15px;
  }

  .row {
    margin: 0;
  }
}

#modal-totp {
  #totp_code {
    width: 100%;
  }
}

#modal-email {
  .form-col {
    p {
      margin-bottom: 0;
    }

    #email_code {
      width: 100%;
    }
  }
}

/***********************/
/*    POPIN_PHONE      */
/***********************/

#popin-phone {
  .col-sm-12 {
    padding: 0;
  }

  .edit-profile-action input {
    margin: 0;
  }

  #validation_code {
    width: 100%;
  }
}


/***********************/
/*    LOGIN           */
/***********************/

.popin-login {
  input[type="text"] {
    width: 100%;
  }
  .pwd-icon {
    right: 10px;
  }

  z-index: 99999;

}


/*********************/
/*     DOCUMENT      */
/*********************/

.popin-document-view {
  .close-box {
    top: -5px;
    right: -1px;

    font-size: var(--font-size-2xl) !important;

  }
}


.popin-avatar {
  .close-box {
    top: -5px;
    right: -1px;
  }
}

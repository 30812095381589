/**
 *
 * - GLOBAL
 * - LAYOUT_CONTENT_IMAGE
 *  - SUPERVISION_MENNU
 *
 */

/*****************/
/*     GLOBAL    */
/*****************/
.container {

  .mw-50 {
    min-width: 50%;
  }

  width: auto;
  padding: 0;
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;
  min-height: 100vh;
  background-color: var(--color-bg-body);

  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;

    header {
      display: none;
      justify-content: start;
      .navbar-toggle {
        display: block;
      }

      @media (max-width: 768px) {
        display: flex;
      }

    }

    @media print {
      overflow: visible;
    }

  }

  .v2__sidebar_menu, .sidebar-right {
    flex-shrink: 0;
  }

  .v2__sidebar_menu {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    background-color: var(--color-menu);

    @media (max-width: 768px) {
      width: 240px;
    }

    .v2__sidebar_menu_content {
      height: calc(100vh);
    }

    #menu {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    ul.menu {
      overflow: auto;
      height: 100%;
      flex: 1 1 auto;
      scrollbar-color: var(--color-gray-700) transparent;

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-gray-700);
      }

      &::-webkit-scrollbar {
        width: 3px;
      }
    }

    .deconect_zone {
      flex: 0 0 auto;
    }

    .toggle {
      background-color: transparent;
    }
  }

  .main-page-content {
    margin: 0;
    padding: 0 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
  }

  #main-container, .main-container {
    position: initial;
    background-color: transparent;
    width: 100%;
    margin: 0;

    .v2__content {
      margin-top: 25px;
    }

    .v2__content-header {
      min-height: 60px;

      h1:first-child {
        margin-top: 20px;
        margin-bottom: 22px;

        &.with_actions {
          display: flex;
          align-items: center;
          gap: 5px;

          .h_content {
            flex-grow: 1;
          }

          .actions {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            gap: 5px;
            flex-flow: wrap;
          }
        }
      }

      .actions {
        a, input, button {
          @extend .text-sm;
          height: 36px;
          margin: 0;

          .material-icons {
            @extend .text-sm;
          }
        }
      }

      .underline {
        margin-bottom: 0;
      }
    }
  }
}

@include md {
  .page-content {
    margin-right: 56px;
  }

  .sidebar-right {
    width: 56px;
  }
}

/**************************/
/*  LAYOUT_CONTENT_IMAGE  */
/**************************/
.layout_content_image {
  display: flex;
  width: 100%;

  > div:first-child {
    flex: 2 2 auto;
    max-width: 100%;
  }

  &:after {
    flex: 1 1 auto;
    background-image: url('../../../images/v2/new_patient.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    opacity: 0.5;
    display: none;
    content: ' ';
    min-width: 40%;
    min-height: 500px;
  }

  &.call_medic {
    &:after {
      background-image: url('../../../images/v2/call_medic.svg');
      background-size: 300px;
      min-height: 600px;
    }

    &.small:after {
      min-height: 400px;
      background-size: 200px;
    }
  }

  &.empty {
    flex-direction: column-reverse;
    align-items: center;

    > div:first-child {
      @extend .text-base;
      color: var(--color-gray-700);
      text-align: center;
      margin-top: 20px;
    }

    &:after {
      min-height: 400px;
      background-image: url('../../../images/v2/woman_state.svg');
      background-size: 50%;
    }
  }

  /**************************/
  /*    SUPERVISION_MENNU   */
  /**************************/
  .supervision-menu {
    max-height: 77vh;
    overflow-y: auto;
  }
}

@include xl {
  .layout_content_image {
    &:not(.empty):after,
    &.empty:after {
      display: block;
    }
  }
}

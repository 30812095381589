.pricing {
  margin-bottom: 15px;

  .loader {
    top: 25px;
  }

  .subscription_toggle_container {
    display: flex;
    justify-content: center;

    .toggle {
      display: none;
    }

    label.toggler {
      border-radius: 9999px;
      margin: 0;
      margin-top: 10px;
      font-weight: var(--font-semibold);
      padding: 0.5rem 1.25rem;
      @extend .text-base;
      cursor: pointer;
      color: var(--color-gray-400);

      &.toggler--is-active {
        background-color: var(--color-primary-100);
        color: var(--color-primary-400);
      }
    }

  }

  #list {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    justify-content: center;

    .princing_card {
      flex: 0 0 280px;
      min-width: 256px;

      .card {
        border: 1px solid var(--color-gray-50);
        border-radius: var(--rounded-lg);
        opacity: 1;
        height: 100%;
        position: relative;
        background-color: var(--color-white);

        .card-recommended {
          width: 100%;
          text-align: center;
          position: absolute;
          display: none;
          justify-content: center;

          > span {
            background-color: var(--color-success-100);
            @extend .text-sm;
            color: var(--color-success-500);
            padding: 5px 15px;
            border-bottom-left-radius: var(--rounded-lg);
            border-bottom-right-radius: var(--rounded-lg);
            font-weight: var(--font-semibold);
          }
        }

        &.highlighted-product {
          .card-recommended {
            display: flex;
          }
        }

        .card-title {
          text-align: left;
          padding: 0 15px;
          color: var(--color-black);
          border-bottom: 0;
          height: auto;
          @extend .text-2xl;
          font-weight: var(--font-bold);
          padding-top: 40px;
          text-transform: lowercase;
        }

        .card-title:first-letter {
          text-transform: capitalize;
        }

        .card-body {
          hr {
            margin: 0 15px;
          }

          .prices {
            .amount {
              @extend .text-3xl;
              color: var(--color-gray-900);
              font-weight: var(--font-bold);
            }
          }

          .price-container {
            display: inline-flex;
            align-items: center;
            gap: 5px;

            .barred-price {
              @extend .text-lg;
              color: var(--color-gray-300);
              text-decoration: line-through;
            }
          }

          .non-binding {
            text-transform: lowercase;
            margin-top: 5px;
          }

          .button {
            height: 55px;

            .btn {
              margin-top: 0 !important;
              opacity: 1;
              padding: 20px !important;
              text-transform: none;
              letter-spacing: normal;
            }
          }
        }


        ul.features {
          text-align: left;
          @extend .text-sm;
          padding: 0 15px;
          color: var(--color-gray-900);

          li {
            display: flex;
            align-items: center;
            font-family: var(--font-family);
            font-weight: var(--font-normal);
          }

          li:before {
            content: "done";
            font-family: var(--font-family-icon);
            color: var(--color-success-500);
            margin-right: 10px;
            @extend .text-base;
          }
        }

        &.highlighted-product {
          .btn {
            @extend .v2__button_primary;
          }
        }
      }
    }
  }

  @include md {
    #list {
      flex-direction: row;
    }
  }
}


.subscription-options {

  .form-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
  }

  .subscription-option {

    p {
      margin: 0;
    }

    .partner-logo {
      display: flex;
      margin: 15px 0;

      img {
        max-height: 35px;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

}

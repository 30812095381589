@import "variables";

@import "./../../../node_modules/@yaireo/tagify/dist/tagify.css";

@import "../../../node_modules/video.js/src/css/video-js";
//@import "../../../node_modules/video.js/dist/video-js.min.css";

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}


.iblock {
  display: block !important;
}

.mg-auto {
  margin: auto !important;
}

.visibility {
  max-width: 60px;
  display: block;
  overflow: hidden;
  max-height: 20px;
  text-overflow: ellipsis;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

* {
  outline: none;
}

.table-white {
  background-color: #fff;
}

body {
  font-family: $font;
  color: $darken-color;

  a, .like-link {
    font-family: $font-bold;
    font-weight: bold;
    color: $darken-color;
    cursor: pointer;
  }
}

p {
  margin-bottom: 20px;

  strong {
    font-family: $font-bold;
  }
}

body a, body .like-link {
  &:hover, &:focus {
    text-decoration: none;
    color: $success-color;
  }

  img {
    &:hover, &:focus {
      -webkit-box-shadow: 1px 1px 8px #DFC5C5;
      box-shadow: 1px 1px 8px #DFC5C5;
    }
  }

  &.no-shadow img {
    &:hover, &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.clear {
  clear: both;
}

.main-page-content {
  min-height: 600px;
}

header {
  height: 100px;
  padding: 20px 40px 0 0;
  width: 100%;

  img:not(.icon) {
    height: 58px;
  }

  .logo {
    border-right: 1px solid $success-color;
    float: left;
    margin-right: 15px;
    padding-left: 0;
    width: 23%;
    text-align: right;
  }

  #profile img {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    margin-left: 7px;
  }

  .menu-top {
    border-right: 1px solid $success-color;
    padding: 0 15px;
    line-height: 50px;
    margin-right: 15px;
    height: 50px;
    position: relative;

    .sidebar-icon {
      font-size: 21px;
      position: relative;
    }

    .notification-dot {
      bottom: -3px;
      right: -4px;
    }

  }

  .notification--drawer {
    position: absolute;
    z-index: 999;
    right: 5px;
    width: 400px;
    top: 40px;
    line-height: initial;
    border-radius: 0 0 5px 5px;
    border: 1px solid $grey;
    background: #fff;
    max-height: 270px;

    .items {
      overflow: auto;
      max-height: 250px;
      border-bottom: 1px solid $grey;
    }

    box-shadow: 5px 5px 8px -9px black;

    .notification--clearer {
      height: 20px;
      display: block;
      line-height: 20px;
      font-family: $font;
      font-size: 12px;
      padding: 0 5px;
    }

    .notification--item {
      position: relative;
      padding: 5px;
      border-bottom: 1px solid $grey;

      .date {
        display: block;
      }

      &:hover {
        background-color: $grey;
      }

      &.n-unread {
        background-color: transparentize($default-color, 0.9);

        &:hover {
          background-color: transparentize($default-color, 0.8);
        }

      }

      .mark-read {
        position: absolute;
        right: 5px;
        bottom: 5px;
      }

      .n-title {

      }

      .n-content {
        color: $black;
        font-family: $font;
        padding: 0;
        font-size: 13px;
        font-weight: normal;
        display: block;
        margin-bottom: 5px;
      }

    }
  }
}

.js-badge-red {
  background: $red;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  font-size: 11px;
  margin: 0;
  color: #fff;
  line-height: 13px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  bottom: 3px;
  right: 3px;
  display: block;
}

.js-badge {
  display: none;
}


.alert {
  // font-style: italic;
  font-family: $font-bold;
  background-color: white;
  border: 0;
  padding: 5px 10px;
  margin-bottom: 0;

  &.new {
    position: relative;
    border: 2px solid var(--color-warning-500);
    overflow: visible;

    &:before {
      content: "Nouveau";
      position: absolute;
      display: block;
      bottom: -5px;
      font-size: 8px;
      font-weight: bold;
      background: var(--color-warning-500);
      height: 10px;
      color: #fff !important;
      z-index: 9;
      overflow: visible;
      width: auto;
      line-height: 7px;
      padding: 2px;
      text-align: center;
      left: -15px;
      top: -5px;
      transform: rotate(330deg);
    }
  }

}

.alert-error {
  color: $dark-red;
}

.alert-warning {
  color: $orange;
}

.alert-success {
  color: $success-color;
}

#fos_user_content-img-container {
  width: 35%;
  float: left;
  text-align: center;
  padding-left: 7%;

  img {
    width: 75%;
  }
}


a.btn, input[type="button"], button[type="submit"], input[type="submit"], button.btn, label.btn {
  color: white;
  font-family: $font-bold;
  border-radius: 50px;
  margin-right: 5px;
  text-decoration: none;
  border: 1px solid $success-color;
  background-color: $success-color;
  font-weight: 500;

  &:hover {
    background-color: white;
    color: darken($success-color, 10);
    border: 1px solid darken($success-color, 10);
  }

  &.btn-red, &.red-button, &.btn-danger, &[disabled] {
    background-color: $red;
    border: 1px solid $red;

    &:hover {
      background-color: white;
      border: 1px solid darken($red, 10);
      color: darken($red, 10);
    }


    &.btn-reverse {
      background: #fff;
      border-color: $red;
      color: $red;

      &:hover {
        background: darken($red, 15);
        color: #fff;
      }
    }

  }

  &.btn-warning {
    background-color: $orange;
    border: 1px solid $orange;

    &:hover {
      background-color: white;
      color: darken($orange, 10);
      border: 1px solid darken($orange, 10);
    }

    &.btn-reverse {
      background: #fff;
      border-color: $orange;
      color: $orange;

      &:hover {
        background: darken($orange, 15);
        color: #fff;
      }
    }
  }

  &.btn-grey {
    background-color: $dark-grey;
    border: 1px solid $dark-grey;

    &:hover {
      background-color: white;
      color: darken($dark-grey, 10);
      border: 1px solid darken($dark-grey, 10);
    }

    &.btn-reverse {
      background: #fff;
      border-color: $dark-grey;
      color: $dark-grey;

      &:hover {
        background: darken($dark-grey, 15);
        color: #fff;
      }
    }
  }

  &.btn-purple, &.btn-document {
    background-color: $purple;
    border: 1px solid $purple;

    &:hover {
      background-color: white;
      color: darken($purple, 10);
      border: 1px solid darken($purple, 10);
    }

    &.btn-reverse {
      background: #fff;
      border-color: $purple;
      color: $purple;

      &:hover {
        background: darken($purple, 15);
        color: #fff;
      }
    }
  }

  &.btn-primary, &.btn-prescription {
    background-color: $default-color;
    border: 1px solid $default-color;

    &:hover {
      background-color: white;
      color: darken($default-color, 10);
      border: 1px solid darken($default-color, 10);
    }

    &.btn-reverse {
      background: #fff;
      border-color: $default-color;
      color: $default-color;

      &:hover {
        background: darken($default-color, 15);
        color: #fff;
      }
    }

  }
}


#main-container {
  height: 100%;
  /*background-color: #F9F9F9;*/
  margin-bottom: 15px;
}


.container-fluid {
  #menu {
    margin-left: -15px;
  }
}

#menu {
  width: 22%;
  max-width: 250px;
  height: 100%;
  float: left;
  background-color: $default-color;
  color: white;
  @media screen and (min-width: $mobile-max) and (max-width: 992px) {
    width: 28%;
  }

  ul {
    margin: 0;
    width: 100%;
    padding: 0;

    li {
      width: 100%;
      list-style: none;
      line-height: 45px;
      padding-left: 25px;
      border-left: 5px solid $default-color;

      &.disabled {
        font-style: italic;
        font-size: 1em;
        color: #E8E8E8;

        &:hover, &:active {
          cursor: no-drop;
        }
      }

      @media screen and (min-width: $mobile-max) and (max-width: 1200px) {
        font-size: 12px;
      }

    }
  }
}

.prescription-row {

  i {
    margin-top: 33px;
    padding: 0;
  }
}

.synapse-prescription-row {
  padding: 5px;
  border-radius: 5px;
  label {
    margin: 0;
    &.small {
      font-size: 12px;
      font-weight: normal;
    }
  }
}

@media screen and (min-width: 1300px) {

  .container-fluid {
    .main-page-content {
      margin-left: 15%;
      width: 84%;
    }

    #menu {
      width: 15%;
    }
  }

}


#menu {
  @media screen and (max-width: $mobile-max) {
    overflow: hidden;
  }

  ul li {
    span {
      float: right;
      margin-right: 10px;

      &.far, &.fa {
        line-height: 45px;
      }
    }

    &:hover, &:focus {
      cursor: pointer;
      background-color: $secondary-color;
      border-left: 5px solid $secondary-color;
    }

    &.selected {
      border-left: 5px solid $secondary-color; //$light-secondary-color;
      background-color: $secondary-color;
    }

    ul.submenu {
      margin-left: -30px;
      width: 114%;

      li {
        width: 100%;
        padding-left: 47px;
        font-size: 0.9em;
        background-color: $default-color;

        &:hover, &:focus {
          background-color: $secondary-color;
          border: 0;
          padding-left: 55px;
        }

        a {
          color: $light-secondary-color;
        }

        &.selected {
          list-style: disc;
          background-color: $dark-color;
          border: 0;
          padding-left: 35px;
          list-style-position: inside;

          a {
            color: white;
            font-family: $font;
          }
        }
      }
    }
  }

  .bottom a {
    color: white;
    font-family: $font;
  }

  ul li a {
    color: white;
    font-family: $font;

    &:hover, &:focus {
      color: white;
      font-family: $font;
    }
  }

  .closed > ul {
    display: none;
  }

  .bottom {
    vertical-align: bottom;
    text-align: center;
    width: 100%;
    height: 45px;
    line-height: 45px;
    color: white;
    position: relative;
    bottom: 0;

    div {
      border-top: 1px solid $dark-color;
      //     background-color: $dark-color;
    }

    @media (max-width: $mobile-max) {
      top: initial !important;
    }
  }

  .rounded {
    float: right;
    margin-right: 10px;
    background-color: white;
    color: $secondary-color;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-top: 12px;

    span {
      position: absolute;
      margin-top: -13px;
      margin-left: -5px;
      font-size: 0.8em;
    }
  }
}

.main-page-content {
  margin-left: 22%;
  width: 78%;
  height: 100%;
  background-color: white;
  padding-left: 25px;
  @media screen and (min-width: $mobile-max) and (max-width: 992px) {
    margin-left: 28%;
    width: 72%;
  }
}

.gradiant-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FAFAFA), to(white));
  background-image: -webkit-linear-gradient(#FAFAFA, white);
  background-image: -o-linear-gradient(#FAFAFA, white);
  background-image: linear-gradient(#FAFAFA, white);
  width: 103%;
  height: 20px;
  margin-left: -25px;
}

h1, h6 {
  margin-top: 10px;
  font-family: $font-bold;
  font-size: 1.75em;
  color: $dark-color;
  line-height: 50px;
  margin-bottom: 0;
}

.underline {
  border-bottom: 5px solid;
  color: $dark-color;
  width: 45px;
  margin-bottom: 20px;
}

h2 {
  font-family: $font-bold;
  font-size: 1.2em;
  margin-top: 0;
  color: $success-color;
  margin-bottom: 15px;
}

h3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
  font-family: $font-bold;
}

.box h4, h4 {
  font-family: $font-bold;
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 25px;
  color: $darken-color;
}

.strip {
  background-color: $dark-color;
  background-repeat: no-repeat;
  background-size: 350px auto;
  background-position: 40px 60px;
  margin: 10px 0 30px -25px;
  padding: 20px;
  overflow: hidden;
  color: white;

  .text-content {
    margin-bottom: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }

  @media(max-width: $mobile-max) {
    background-image: none !important;
  }
}

#home-information {
  width: 100%;

  .left-part {
    width: 50%;
    float: left;
    @media screen and (max-width: $mobile-max) {
      width: 100%;
      float: none;
    }
  }

  .right-part {
    float: left;
    width: 45%;
    padding-right: 15px;
    padding-left: 20px;
    margin-left: 15px;
    border-left: 1px solid #EFEFEF;
    @media screen and (max-width: $mobile-max) {
      width: 100%;
      float: none;
      padding: 0;
      margin: 0;
    }
  }
}

.phonenumber {
  color: $success-color;
}

.text-content {
  margin-bottom: 20px;

  a:not(.btn) {
    text-decoration: underline;
    font-family: $font;
  }

  strong {
    font-family: $font-bold;
  }

  ul {
    margin-top: -15px;
  }
}

.supervision-content .text-content ul {
  margin-top: 1px;
}

.split-part {
  float: left;
  width: 50%;
  padding-right: 25px;
  margin-top: 15px;
  @media screen and (max-width: $mobile-max) {
    width: 100%;
    float: none;
    padding: 0;
  }
}

.split-bigger-part {
  float: left;
  width: 64%;
  padding-right: 25px;
  margin-top: 15px;

  @media screen and (max-width: $mobile-max) {
    float: none;
    width: 100%;
    padding-right: 0;
  }

}

.split-lesser-part {
  position: relative;
  float: left;
  width: 36%;
  padding-right: 25px;
  margin-top: 15px;
  @media screen and (max-width: $mobile-max) {
    float: none;
    width: 100%;
    padding-right: 0;
  }
}

.split-part-third {
  float: left;
  width: 30%;
  padding-right: 15px;
  margin-top: 15px;
  margin-right: 15px;
  border-right: 1px solid #FAFAFA;
  @media screen and (max-width: $mobile-max) {
    float: none;
    width: 100%;
  }

  &.last {
    border: 0;
  }

  a .box {
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
    font-family: $font;

    img {
      border-radius: 50px;
      width: 50px;
      height: 50px;
      margin-right: 5px;
      margin-left: 7px;
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;

  &.w-100 {
    width: 100%;
  }

  &.stretch {
    align-items: stretch;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.justify-between {
  justify-content: space-between;
}

.wd-100 {
  width: 100%;
}

.patient-box {

  .bold {
    font-family: $font-bold;
  }

  a .box {
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
    font-family: $font;
  }

  .sub-header {
    color: white;
    text-align: center;
    margin: -15px -15px 5px;
    min-height: 25px;
    border-top-right-radius: 0.46rem;
    border-top-left-radius: 0.46rem;
    border-top: 1px solid var(--color-gray-50);
    border-left: 1px solid var(--color-gray-50);
    border-right: 1px solid var(--color-gray-50);
    border-bottom: none;
    font-size: var(--font-size-sm);
  }

  .box {
    img {
      border-radius: 50px;
      width: 50px;
      height: 50px;
      margin-right: 5px;
    }

    &.install-call-flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 16px 8px;
    }

    .small {
      font-size: 11px;
      color: lighten($black, 10);
      display: flex;
    }

    @media screen and (max-width: $mobile-max) {
      margin-bottom: 10px
    }
  }
}

.patient-elem {
  .profile-status {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 12px;
    width: 12px;

    span {
      height: 12px;
      width: 12px;
      display: block;
      width: 100%;
      border-radius: 50%;
      overflow: hidden;
      color: transparent !important;

      &.status-1 {
        background-color: $green !important;
      }

      &.status-2, &.status-4 {
        background-color: $orange !important;
      }

      &.status-3, &.status-5 {
        background-color: $red !important;
      }

      &.status-6 {
        background-color: $black !important;
      }
    }
  }
}


h1 .status {
  float: right;
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &.status-1 {
    background: $green !important;
  }

  &.status-2, &.status-4 {
    background: $orange !important;
  }

  &.status-3, &.status-5 {
    background: $red !important;
  }

  &.status-6 {
    background-color: $black !important;
  }
}

.instant-call-name {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.patient-box {
  .box .instant-call-link {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 32px;
    flex: 0 0 32px;

    .call-icon {
      border: 1px solid $success-color;
      border-radius: 100%;
      padding: 0.75rem;
      background: $success-color;
      color: #fff;
    }

    &:hover .call-icon {
      color: $success-color;
      background: transparent;
    }
  }
}

.box {
  background-color: $grey;
  border-radius: 15px;
  padding: 22px;
  margin-bottom: 30px;
  color: #4D4D4D;


  &.small-box {
    padding: 15px;
  }


  select {
    max-width: 100%;
  }

}


@media(min-width: 992px) {
  html {
    min-height: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }
}

form {

  label {
    font: $font-bold;
  }

  label[data-help] {
    min-height: 45px;
  }
}

span.help {
  display: block;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  line-height: initial;
  position: absolute;
  left: 0;
  bottom: -5px;
}

form .label-inline label.col-form-label {
  padding-left: 0;
}

.box-time {
  float: left;
  border-radius: 8px;
  background-color: #E0E0E0;
  color: white;
  padding: 5px;
  margin-right: 10px;
  margin-top: -5px;

  .treatment {
    background-color: #FDE800;
  }
}

.box-footer {
  margin: 15px -21px -21px -21px;
  background-color: #F0F0F0;
  padding: 21px;
  border-radius: 0 0 15px 15px;

  strong {
    font-size: 1em;
    font-weight: bold;
    font-family: $font-bold;
  }
}

#thumb {
  min-height: 150px;
  @media screen and (max-width: $mobile-max) {
    min-height: auto;
  }

  img {
    float: left;
    border-radius: 50%;
    height: 100px;

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      height: 70px;
      width: auto;
    }
    @media screen and (min-width: $mobile-max) and (max-width: 991px) {
      height: auto;
      width: 20%;
    }

    @media screen and (max-width: $mobile-max) {
      height: 50px;
      width: 50px;
    }

  }

  .text-content {
    float: left;
    margin-left: 20px;
    border-left: 1px solid $success-color;
    padding-left: 20px;
    margin-top: 15px;
    color: $darken-color;
    @media screen and (max-width: 1200px) {
      margin-top: 0;
      margin-left: 5px;
      width: 75%;
      padding-left: 10px;
    }
  }
}

#cover {
  min-height: 170px;

  .figure {
    float: left;
    margin-top: 22px;
    margin-left: 10px;
  }
}

.bar {
  height: 75px;
  width: 85%;
  float: left;
  background-color: white;
  border: 1px solid lightgray;

  .bar-fill {
    height: 100%;
    width: 75%;
    background-color: $success-color;
  }
}

#illness h3 {
  width: 100px;
  float: left;
}

#therapy h3 {
  width: 120px;
  float: left;
}

/*#illness a,
#therapy a {
  color: $success-color;
}*/

#plain {
  margin-top: 35px;
  padding-bottom: 15px;
}

.page-navigation {
  position: absolute;
  top: 20px;
  right: 11%;
  font-size: 2.3em;
  color: lightgrey;

  .text-navigation {
    font-size: 0.7em;
  }

  a {
    color: $success-color;
  }
}

table.documents {
  background-color: #33C3FF;
  width: 100%;
  margin-bottom: 25px;
  border-spacing: 0px;
  border-radius: 13px;
  -moz-border-radius: 13px;
  -webkit-border-radius: 13px;

  thead {
    color: white;
    font-family: $font;

    a {
      color: white;
      font-family: $font;
    }
  }

  th, td {
    padding: 10px;
    @media (max-width: $mobile-max) {
      padding: 5px;
    }
  }

  tr th.last {
    min-width: 85px;
    text-align: right;
  }

  tbody tr {
    td {
      background-color: #fafafa;
      color: #4D4D4D;
      max-width: 30px;
      overflow: hidden;
    }

    &:last-child td {
      &:first-child {
        border-radius: 0 0 0 12px;
        -moz-border-radius: 0 0 0 12px;
        -webkit-border-radius: 0 0 0 12px;
      }

      &:last-child {
        border-radius: 0 0 12px 0;
        -moz-border-radius: 0 0 12px 0;
        -webkit-border-radius: 0 0 12px 0;
      }
    }
  }

  tr:nth-child(even) td {
    background-color: $grey;
  }

  tr.private {
    background-color: #fff;
  }

  tr.private td {
    background-color: transparentize($red, 0.9);
  }

  tbody tr td a {
    font-family: $font;
    color: $black;

    span.glyphicon {
      margin-left: 7px;
    }

    &:hover, &:focus {
      color: $success-color;
    }
  }

  .delete:hover {
    color: $red;
  }
}

.popin-action {
  position: absolute;
  z-index: 999;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 480px;
  background-color: white;
  border: 1px solid black;
  display: none;
  max-height: 750px;
  overflow: auto;


  .popin-header h2 {
    color: $dark-color;
  }

  &.popin-overflow {
    overflow: visible;
  }

  &.popin-scroll {
    overflow: auto;
    max-height: 80%;
  }


  &.popin-document, &.popin-document-view {
    position: fixed;
  }

  &.popin-action--large {
    max-width: 80%;
    max-height: 90%;
    width: auto;
    min-width: 50%;

    &.with-iframe {
      width: auto;
      height: auto;
      min-width: auto;
      padding: 15px;
      max-width: 95%;
    }

    img {
      max-height: 100%;
    }
  }

  &.popin-fixed {
    position: fixed;
    top: 10%;
  }

  @media (max-width: $mobile-max) {
    left: 10px;
    right: 10px;
    width: auto;
    top: 10%;
    position: fixed;
    transform: translate(0, 0);
  }

  input {
    &[type=text] {
      width: 55%;
    }

    &[type=submit] {
      height: 25px;
    }
  }

  .popin-header i {
    color: #F85346;

    &:hover {
      cursor: pointer;
    }
  }

  input.input-full {
    width: 100%;
  }

  .close-box {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  img {
    max-height: 200px;
    margin: 0 auto;
  }

  &.popin-with-overlay {
    outline: 9999px solid rgb(255 255 255 / 0.7);
  }

  .popin-footer-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }

}

.supervision-menu .supervision-item {
  .banner {
    background-color: #F9F9F9;
    min-height: 50px;
    padding-left: 25px;
    border-bottom: 1px solid #F0F0F0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    h4 {
      line-height: 20px;
      width: 90%;
      font-size: 1.2em;
      float: left;
      margin-bottom: 0;
    }

    .glyphicon {
      margin-right: 15px;
      color: $success-color;
      line-height: 50px;
      font-size: 1.2em;

      &.red {
        color: #F85346;
      }
    }
  }

  .supervision-content {
    background-color: #F0F0F0;
    padding: 15px;
    color: #4D4D4D;
  }
}

.headline {
  background-color: $success-color;
  color: white;
  font-family: $font-bold;
  padding: 15px;
  margin-bottom: -25px;
}

.video-thumb {
  width: 45%;
  display: block;
}

@media(min-width: $mobile-max) {
  .video-thumb {
    float: left;
    margin-right: 15px;

    h4 {
      width: 25%;
    }
  }

  .hidden-sm {
    display: none;
  }
}


#plain {
  iframe {
    max-width: 100%;
  }
}

#faq {
  /*border-right: 1px solid $success-color;*/
}

.faq-actions .box {
  width: 100%;
  display: inline-block;
  border-radius: 15px;
  margin-bottom: 20px;
  color: #4D4D4D;
  position: relative;
  padding: 15px;
  background-color: #F0F0F0;
  max-width: 220px;
}

.faq-search {
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    flex: 1 1 auto;
    width: 100px;
  }

  .glyphicon {
    flex-shrink: 0;
  }
}

.faq-actions {
  .box.headline {
    background-color: $success-color;
  }

  a.box.headline {
    &:hover, &:focus {
      color: white;
    }

    color: white;
  }

  .box {
    input {
      border: 0;
      background-color: #F0F0F0;
    }

    .glyphicon:hover {
      cursor: pointer;
    }
  }
}

#faq .supervision-menu .supervision-item {
  margin-bottom: 8px;

  .banner {
    border-left: 10px solid $success-color;
  }
}

#tips {
  ul {
    padding-left: 20px;

    li a {
      font-family: $font;
      text-decoration: underline;
      font-size: 15px;
    }
  }

  .box.headline .glyphicon {
    color: white;
  }
}

.nice-textarea {
  width: 100%;
  resize: none;
  border-radius: 10px;
  height: 100px;
  border: 1px solid #F0F0F0;
  padding: 15px;
  color: #4D4D4D;
  height: 200px;
}

.nice-input {
  width: 100%;
  resize: none;
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  padding: 15px;
  color: #4D4D4D;
  margin: 0 0 15px 0;

  &:hover {
    border: 1px solid $success-color;
  }
}

.nice-textarea:hover {
  border: 1px solid $success-color;
}

.actions-container {
  margin-top: 15px;

  .btn {
    margin-left: 15px;
  }
}

input[type=submit].btn {
  margin-top: 0;
}

.btn-danger {
  background-color: #F85346;
}

.btn-real-primary {
  background-color: $success-color;
  color: #fff;

  &:hover, &:focus {
    background-color: white;
    color: $success-color;
    border: 1px solid $success-color;
  }
}

.btn-danger {
  &:hover, &:focus {
    background-color: white;
    color: #F85346;
    border: 1px solid #F85346;
  }
}

.btn-round {
  border-radius: 50px;
}

.btn-lg {
  font-family: $font-bold;
  font-size: 14px;
  height: 45px;
  min-width: 100px;
  line-height: 45px;
  padding: 0;
}

.box.tip h3 a span {

  &.edit, &.validate {
    margin-left: 15px;
  }

  &.validate {
    color: $success-color;
  }

  &.unvalidate {
    color: $red;
  }
}

video.test {
  width: 100%;
  height: 5px;
  margin-bottom: 15px;
}

.bar.test-media {
  height: 25px;
  width: 100%;
  margin-bottom: 15px;
}

.banner.dashboard {
  background-color: #F9F9F9;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  border-bottom: 1px solid #F0F0F0;
  border-left: 10px solid $success-color;
  margin-bottom: 25px;

  p {
    margin: 0;
  }

  a:not(.btn) {
    font-family: $font;
    text-decoration: underline;
  }

  &.red, &.old {
    border-left: 10px solid $red;
  }

  &.orange, &.mid {
    border-left: 10px solid $orange;
  }

  &.new {
    border-left: 10px solid $green;
  }

  &.blue {
    border-left: 10px solid $default-color;
  }

  &.waiting-room {
    margin-bottom: 0;
    border-bottom: none;
  }

  strong {
    font-family: $font-bold;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .task-container {
    min-height: 30px;
    line-height: 30px;
  }

}

.historic-part-1 {
  margin-left: 60px;
}

.historic-part-2, .historic-part-3 {
  float: right;
  width: 150px;
  margin-right: 1rem;
  text-align: center;
}

.font-weight-bold {
  font-weight: 700;
}

.red {
  color: #F85346;
}

.orange {
  color: orange;
}

.ml-2 {
  margin-left: 0.5rem;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}


@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}


.alert-call {
  display: block;
  background: $success-color;
  border: 1px solid $success-color;
  border-radius: 10px;
  color: #fff;
  font-size: 1.75rem;
  -webkit-transition: all 100ms ease;
  -o-transition: all 100ms ease;
  transition: all 100ms ease;

  a {
    color: #fff;
    min-width: 80%;
    display: inline-block;
  }

  &:hover {
    background: transparent;
  }

  span {
    border: 2px solid white;
    border-radius: 100%;
    margin-right: 2rem;
    transition: all 0.3s ease;
    color: #fff;
    width: 42px;
    height: 42px;
    line-height: 42px;
    padding: 0;
    text-align: center;

    &.answer {
      -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 5 both;
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 5 both;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }

  &:hover {
    a {
      color: $success-color;
    }

    span.answer {
      border: 2px solid $success-color;
      color: $success-color;
    }

    span.reject {
      border: 2px solid $red;
      color: $red;
      background-color: #fff;
      cursor: pointer;

      &:hover {
        border-color: #fff;
        color: #fff;
        background-color: $red;
      }
    }
  }
}

.note-wrapper {
  // width: calc(100% - 25px);
  width: 100%;
  -webkit-box-shadow: 1px 1px 5px grey;
  box-shadow: 1px 1px 5px grey;
  //position: absolute;
  //top: 70px;
  position: relative;
  top: 0;
  background: white;
  padding: 0.5rem 1rem;
  margin-bottom: 5px;

  h3 {
    padding: 0.8rem;
    text-align: center;
  }
}

.note-list {
  list-style: none;
  padding: 0 1rem;
  height: 70px;
  overflow-y: scroll;
  margin-bottom: 0;
}

.note-item {
  background: rgb(240, 240, 240);
  margin-bottom: 1.6rem;
  border-radius: 1.6rem;
  position: relative;
}

.note-header {
  padding: 0.8rem 1.6rem;
  color: $success-color;
  font-weight: 700;
  font-size: 1.4rem;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 0.8rem;
}

.note-subtitle {
  font-size: 1.05rem;
}

.note-input {
  width: 100%;
  display: block;
  margin: 0 auto;
  resize: none;
}

.note-add {
  display: block;
  border-radius: 0 0 0.5rem 0.5rem !important;
  font-size: 1.6rem;
  width: 100%;
  margin: 0 auto;
}

.note-status {
  position: absolute;
  right: 0.8rem;
  top: 1rem;
}

.note-content {
  display: block;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
}

.note-toggle {
  margin: 0.8rem 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.documents-list {
  list-style: none;
  padding: 0 1rem;
  max-height: 150px;
  overflow-y: scroll;
  margin-bottom: 5px;
}


.green {
  color: $green;
}

.green--dark {
  color: darken($green, 10);
}

a.orange {
  color: orange !important;
}

.bold {
  font-weight: 700;
}

.black-text {
  color: $black;
}

.deepblack-text {
  color: #000;
}

.blue-text {
  color: $dark-color;
}

.blue-bg {
  background: $pale-color;
}

.pa-2 {
  padding: 2rem;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.f-16 {
  font-size: 16px;
}

.orange a {
  color: orange !important;
}

.overflow-y {
  overflow-y: auto;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: scroll;
  max-height: 700px;

  table {
    width: 100%;
    margin: auto;
    border-spacing: 0;
    border-collapse: separate;
  }
}

.table-wrap {
  position: relative;
}

.table-scroll {
  th {
    background: #fff;
    padding: 5px 10px;
    vertical-align: top;
  }

  td {
    padding: 5px 10px;
    vertical-align: top;
  }

  thead {
    th {
      background: #f0f0f0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }

    tr.secondary-head th {
      top: 58px;
    }
  }

  tfoot {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 4;

    th, td {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 4;
    }
  }

  th:first-child {
    position: -webkit-sticky;
    position: sticky;
    width: 220px;
    min-width: 220px;
    left: 0;
    z-index: 2;
  }

  thead th:first-child, tfoot th:first-child {
    z-index: 5;
  }
}

/* safari and ios need the tfoot itself to be position:sticky also */

.form_section {
  margin-top: 25px;
}

.menu li em {
  font-size: 1em;
  font-style: normal;
}

@media screen and (min-width: $mobile-max) {

  #menu {
    &.small {
      width: 50px;


      li {
        padding-left: 0;
        text-align: center;

        i {
          font-size: 16px;
        }

        em, span {
          display: none;
        }
      }
    }

    .bottom em {
      font-style: normal;
    }

    &.small .bottom em {
      display: none;
    }
  }

  .main-page-content.large {
    margin-left: 5%;
    width: 95%;
  }
}

.submit {
  margin-top: 20px;
}

.table-scroll .section_title {
  padding-top: 15px;
  border-bottom-width: 2px;
  background-color: #f3f3f3;
}

.treatment-content {
  display: none;
}

.table .new {
  background-color: #f1f1f1;

  td {
    background: rgba($green, 0.2) !important;
  }
}

.table .blacked {
  background: rgba(0, 0, 0, 0.8) !important;
  border-color: rgba(0, 0, 0, 0.8) !important;
}


input[type="submit"].btn-link {
  color: #2c84d1;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
  font-family: $font;
}

.thumb {
  float: left;
}

.patient-blacklisted {
  display: inline-block;
  background-color: #a94441;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}

.box {
  position: relative;

  span.top-right {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  span.bottom-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

.only-print {
  display: none;
}

@page {
  size: auto;
  margin: 0mm;
}

@media print {
  #menu, #profile, .sf-toolbar, .print-link {
    display: none !important;
  }
  .main-page-content {
    margin: 0
  }
  a[href]:after {
    content: none !important;
  }

  .scale {
    display: none;
  }

  .patient-list.box {
    max-width: 30%;
    margin-left: 1.5%;
  }

}


.pd-right-0 {
  padding-right: 0 !important;
}

.pd-top-15 {
  padding-top: 15px !important;
}

.pd-top-5 {
  padding-top: 5px !important;
}

.pd-top-10 {
  padding-top: 10px !important;
}

.pd-bottom-5 {
  padding-bottom: 5px !important;
}

.pd-bottom-10 {
  padding-bottom: 10px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pd-right-15 {
  padding-right: 15px !important;
}

.pd-left-15 {
  padding-left: 15px !important;
}

.pd-left-42 {
  padding-left: 42px !important;
}

.pd-bottom-0 {
  padding-bottom: 0 !important;
}

.pd-bottom-30 {
  padding-bottom: 30px !important;
}

.pd-top-15 {
  padding-top: 15px !important;
}

.pd-l-r-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media print {

  .only-print {
    display: block !important;
  }

  a.btn, button.btn, input[type="button"], a[href]:after {
    display: none !important;
  }

  .box {
    background-color: #e2e2e2 !important;
  }

  // @page { size: landscape; }


  .table-scroll table {
    width: auto;
    margin: 0;
    border: 2px solid black;
  }

  .container {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .main-page-content {
    margin: 0 !important;
    width: 100% !important;
  }
  .no-print, header, #menu {
    display: none !important;
  }

  .red {
    color: #F85346 !important;
  }
  table {
    color: $darken-color !important;
  }

  .green {
    color: #38D9B5 !important;
  }

  .green--dark {
    color: #128D72 !important;
  }

  .orange {
    color: orange !important;
  }

  h1 {
    color: $dark-color !important;
    font-size: 18px !important;
  }
  h2 {
    color: $success-color !important;
  }
  .sf-toolbar {
    display: none !important;
  }
  /*
  .table {
    display: table !important;
    border: none;
  }
  table {
    page-break-after: auto;
  }
  tr, td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  th {
    font-weight: bold;
  }
  .treatment-content {
    // display: block !important;
    page-break-inside: avoid;
  }

  */
  .blacked {
    display: none;
  }

  .mg-top-50 {
    margin-top: 0 !important;
  }


  .table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    padding: 2px 5px;
  }

  .table td {
    max-width: 80px;
  }

  .symptom-report {
    p {
      margin-bottom: 0;
    }

    .reply {
      width: 100%;
      padding: 10px 15px 10px 15px;
      page-break-inside: avoid;
      page-break-after: auto;
      margin-bottom: 15px;
    }
  }

}

.block {
  display: block;
}

.h-50 {
  height: 50px;
}

.h-30 {
  height: 30px;
}

@media(max-width: $mobile-max) {
  .h-xs-0 {
    height: 0;
  }

  .block-xs {
    display: block;
  }
}

.input-full {
  width: 100%;
}


.autocomplete, .autocompletor, .v2-autocomplete--drawer {
  position: absolute;
  background: #fff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
  z-index: 99;
  width: 100%;
  max-height: 250px;
  overflow: auto;

  span {
    display: block;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid lightgray;
    padding: 3px 5px;
  }

  .autocompleter-value {
    cursor: pointer;

    img {
      float: left;
      height: 30px;
      width: 30px;
      margin-right: 10px;

      &:not(.no-radius) {
        border-radius: 50%;
      }

      overflow: hidden;


      &.synapse {
        height: 10px;
        width: 10px !important;
        border-radius: 0;
        float: none;
        margin-right: 0;
        display: inline-block;
        margin-left: 5px;
        line-height: 1px;
        min-width: 0;
        margin-top: -2px;
      }
    }

    &.grouped {
      padding-left: 10px;
    }

    &.with-photo {
      min-height: 40px;
    }

    &:hover {
      background: $grey;
    }

    &:after {
      clear: both;
    }
  }

  &.with-group {
    .autocompleter-value {
      padding-left: 15px;
    }
  }

  .autocompleter-group {
    font-weight: bold;
  }
}

.box-flex {
  display: flex;
  align-items: center;

  &.box-col {
    flex-direction: column;
    align-items: inherit;
  }

}

.mg-0 {
  margin: 0;
}

.mg-top-25 {
  margin-top: 25px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-top-50 {
  margin-top: 50px;
}

.field-group {
  margin-bottom: 15px;
  margin-top: 15px;

  select {
    max-width: 100%;
  }

}

#menu.small ul li ul.submenu {
  margin: 0;
}

.nav-tabs {
  margin-top: 20px;
  min-height: 48px;
  border: none;

  .nav-link {
    padding: 13px 25px;

    &.active {
      background: #ddd;
      border-radius: 5px 5px 0 0;
      /*  color: #fff; */
    }
  }

  @media screen and (max-width: $mobile-max) {
    margin-top: 15px;

    &.with-legend {
      margin-top: 50px;
      position: relative;
    }

  }
}

.tab {
  padding: 15px;
  border: 1px solid #ddd;

  &.tab-users {
    max-height: calc(100vh - 250px);
    overflow: scroll;
    min-height: 200px;
  }

}

.table tr:not(.details) td {
  font-family: $font-bold;
}


.mg-top-15 {
  margin-top: 15px !important;
}

.compact-form select[multiple] {
  height: 70px;
  padding: 3px;
  margin-bottom: 20px;
}

.clear-right {
  height: 1px;
  clear: right;
}

.main-page-content {
  position: relative;
}

.legend {
  position: absolute;
  top: 55px;
  right: 0;
  pointer-events: none;
  @media screen and (max-width: $mobile-max) {
    position: relative;
    top: 0;
  }
}

.list-search {
  margin-top: 25px;
}


.only-mobile {
  display: none;
}

@media screen and (max-width: $mobile-max) {


  header {
    height: 55px;
    padding: 0;
    background-color: #fff;
    border-bottom: 2px solid $mid-blue;
    position: relative;
    z-index: 9;
    box-shadow: 0 0 5px 0 $dark-color;

    img:not(.icon) {
      height: 32px;
    }

    #hospitalInstitutions {
      margin-top: -5px;
    }

    .notification--drawer {
      width: 100%;
      position: fixed;
      top: 55px;
      right: 0;
      left: 0;
    }

    .navbar-toggle {
      margin-top: 10px;

      .icon-bar {
        background: $mid-blue;
      }
    }

    .logo-necker, .logo {
      display: none;
    }

    #profile {
      margin-top: 5px;
      padding-right: 5px;

      a {
        color: #fff;

        span {
          display: none;
        }
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .no-menu header #profile {
    display: none;
  }

  .container {
    padding: 0;
  }

  #menu {
    width: 80%;
    height: 100% !important;
    margin: 0;
    position: absolute;
    z-index: 99999;
    display: none;
  }

  .main-page-content {
    width: 100%;
    margin-left: 0;
    padding: 0 15px;
  }


  .nav li.list-search {
    float: none !important;
    position: absolute;
    top: -60px;

    input[type="text"] {
      max-width: 55%;
    }

  }

  .only-mobile {
    display: initial;
  }

  .dropdown-inline {
    margin-top: 10px;
  }

}

.width-30 {
  width: 30%;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.mg-top-50 {
  margin-top: 50px;
}

.mg-top-5 {
  margin-top: 5px;
}


#app_doctor_infos {
  list-style-type: none;
}


.well {
  padding: 25px;

  .close-box {
    font-size: 20px;
  }
}


.main-page-content .well .form-full {
  width: 100%;

  select {
    margin-right: 0;
    float: left;
    margin-bottom: 20px;
  }
}


.relative {
  position: relative;
}

.delete-img {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  background: black;
  line-height: 8px;
  text-align: center;
}


body .bootstrap-tagsinput {
  width: 100%;
  box-shadow: none;
  border: 1px solid #a8a8a8;
  border-radius: 0;
  padding: 0;
  line-height: 27px;
  min-height: 30px;
  text-align: left;

  .label {
    font-size: 100%;
  }
}


.label-information {
  float: right;
  text-align: left;
  font-size: 12px;
}


.span-2 {
  grid-column: span 2;
}

li.form-group {
  list-style-type: none;
}

form[name="app_doctorinfo"] {
  .multifieldElement .multifieldElement {
    border: none;
    padding: 0;
  }

  .remove-link {
    //   display: none;
  }

  .add_link {
    //   display: none;
  }

}

.btn-default-color {
  background-color: $default-color !important;
}

.form-collection-list {
  list-style-type: none;
  padding-left: 0;
}

.input-group-append {
  display: table-cell;
  vertical-align: middle;
  padding-left: 5px;
}

.radio-scale {
  padding: 0;

  input {
    display: none;
  }
}


.triangle-container {
  margin-top: 25px;
}

#triangle.triangle-bis {
  border-width: 0 0 550px 120px
}

#picture.picture-bis {
  height: 550px;
  padding-left: 60px;

  img {
    width: 75%;
  }
}

label.col-form-label {
  padding-top: 8px;
  padding-left: 0;
}

li label.col-form-label, .compact-form label.col-form-label {
  padding-left: 15px;
}

.logo-phone {
  display: none;
}

.logo-desktop {
  display: inline-block;
}

@media(max-width: 762px) {
  header {

    .logo-necker {
      text-align: right;
      padding-right: 15px;

      img {
        max-height: 70px;
        width: auto;
        text-align: center;
        margin-right: 0;
      }
    }


    .logo {
      width: auto !important;
      padding-left: 15px;
      border-right: none;
    }

    .logo-phone {
      display: block;
      height: 70px;
      width: auto;
    }

    .logo-desktop {
      display: none;
    }
  }

  .cgu {
    input[type="checkbox"] {
      height: 20px;
      width: 20px;
      float: left;
    }

    label {
      float: left;
      width: 90%;
      padding-left: 10px;
    }
  }
}

.btn-primary {
  border-color: $default-color;
  background-color: $default-color;
}

.dropdown-item.primary:hover {
  background-color: darken($default-color, 10);
  color: white;
  border: 1px solid darken($default-color, 10);
}

.slim {
  //font-weight: normal;
  font-family: $font;
}


.invalid-feedback {
  .form-error-icon {
    display: none;
  }

  .form-error-message {
    color: red;
  }
}

.mailbox-menu-item, .message .box {
  position: relative;

  .delete {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    display: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: #000;
    }
  }

  &:hover {
    .delete {
      display: block;
    }
  }

  .is-read {
    position: absolute;
    bottom: 0;
    right: 5px;
    font-size: 12px;
    font-style: italic;
    font-weight: bolder;
  }
}

.auto-save-band {
  background: #37DCB4;
  color: #fff;
  padding: 8px;
  font-weight: bold;
  text-align: center;
  display: none;
}

.radio-scale label.required:after {
  display: none;
}

.main-container {
  width: 100%;
  float: left;
}

.interveners {
  .add_link {
    display: block;
  }
}


form {
  .see-more {
    position: absolute;

    font-size: 17px;
    color: $success-color;
    position: absolute;
    top: -4px;
    right: -5px;
    padding: 10px;

    &:hover {
      color: darken($success-color, 10);
    }
  }

  .free-text {
    display: none;
    margin-bottom: 15px;
  }


  button.btn-normal {
    background: initial;
    border: initial;
    color: initial;
    margin: initial;
    border-radius: initial;

    &:hover {
      color: initial;
      background-color: initial;
      border: initial;
    }
  }
}

table {

  .odd {
    background: $grey;
    border: 1px solid #ddd;
  }

  .free-text {
    background-color: rgba($success-color, 0.1);
    position: relative;
    cursor: pointer;

    i {
      position: absolute;
      top: 2px;
      right: 3px;
      font-size: 12px;
    }
  }

  .free-text-value {
    display: none;
  }
}

button.btn label {
  display: inline;
}


.profile-img {
  border-radius: 100%;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 80px;
  background-size: cover;
  height: 80px;
  display: inline-block;
  background-position: center center;
  float: left;
}

form[name="app_patientgeneric"] {
  .see-more {
    display: none !important;
  }
}

.code {
  font-weight: bold;
  color: #56DB3A;
  background: #000;
  border-radius: 2px;
  padding: 2px;
  margin-left: 5px;
  font-family: "Courier New";
}


@media (max-width: $mobile-max) {

  .sf-toolbarreset {
    display: none !important;
  }

  .btn-patient-profile-top {
    float: none !important;
    margin: 15px;
  }

  .rtc {
    .split-lesser-part {
      width: 100%;
      float: none;
      margin-top: 0;
      z-index: 9;
    }

    #legend {
      width: 100%;
      padding: 0 15px;
      margin-top: 50px;
    }

    .no-wrap {
      white-space: nowrap;
      display: block;
      height: 45px;
      float: left;
      width: 50%;

      &:after {
        content: "";
        clear: both;
      }
    }

    .note-wrapper {
      width: 95%;
      position: relative;
      top: 0;
      margin: 0 auto;

      .note-list {
        height: 75px;
      }
    }


    .split-bigger-part {
      float: none;
      width: 100%;
      margin-top: 0;
      position: relative;
      z-index: 99;
    }

    #participant {
      width: 100%;
      position: relative;
      top: 0;
      z-index: 10;
    }

    .media-miniature {
      right: 0;
      bottom: 0;
      border: none;
      max-width: 35%;
      height: auto;
    }

    .media-controls, .volume-control {
      bottom: -45px;
      width: 100%;
      position: absolute;
      background: $default-color;
    }


    .chatfile {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      background: #fff;
      padding: 10px;
      box-shadow: 0 0 17px 2px rgb(146, 146, 146);
      margin: 0;
      max-height: 300px;
      display: none;

      .chatfile-list {
        max-height: 210px;
      }
    }

    .toggle-msg {
      position: absolute;
      top: 15px;
      right: 15px;
      border-radius: 100%;
    }
  }
}


.form-switcher {
  label {
    font-size: 25px;
    cursor: pointer;

    input[type="radio"] {
      display: none;
    }

    i {
      margin-right: 10px;
    }

    input:checked + i {
      color: $success-color;
    }
  }
}

.mg-right-15 {
  margin-right: 15px;
}

.mg-right-5 {
  margin-right: 5px;
}

.mg-right-0 {
  margin-right: 0;
}

.mg-top-0 {
  margin-top: 0;
}

label.with-checkbox {
  margin-right: 15px;
}

.tab.small-tab {
  padding: 10px;

  .main-col {
    padding: 0 5px;
  }
}

.tab.full-tab {
  margin-top: 15px;
  padding: 0;
}

.row-same-size {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
}

.row-same-size > [class*='col-'] {
  display: flex;
  flex-direction: column;

  .box {
    height: 100%;
  }
}

.mg-bottom-15 {
  margin-bottom: 15px;
}

.mg-bottom-25 {
  margin-bottom: 25px !important;
}

.mg-bottom-250 {
  margin-bottom: 250px !important;
}


.mg-top--15 {
  margin-top: -15px;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.i-mg-bottom-0 {
  margin-bottom: 0 !important;
}

.mg-bottom-2 {
  margin-bottom: 2px;
}

.mg-bottom-5 {
  margin-bottom: 5px !important;
}


.form-inline {
  display: inline-block;
}

.btn-icon {
  border: none;
  background: none;
  padding: 0;
}

.always-visible {
  display: block !important;

  .close-tab {
    font-size: 20px;
    margin: 5px;
  }
}

.close-tab {
  position: relative;
}

.popin-action {
  .close-tab {
    z-index: 999;
  }
}

.btn-small {
  padding: 0 5px;
}

.mg-left-10 {
  margin-left: 10px;
}

.mg-left-15 {
  margin-left: 15px;
}

.mg-right-10 {
  margin-right: 10px;
}

.pd-r-15 {
  padding-right: 15px;
}


.pwd-icon {
  position: absolute;
  cursor: pointer;
  right: 25px;
  top: 10px;
}


form.full {
  legend, label {
    padding-left: 0;
  }

  input[type="radio"]:checked + label {
    background: none;
  }

  .radio-scale input[type="radio"]:checked + label {
    background: $green;
  }

}

input + .suffix {
  padding-left: 10px;
  position: absolute;
  right: 20px;
  bottom: 6px;
  font-weight: bold;
  background: #fff;
}

[only_if] {
  display: none;
}

input[type="radio"] + label:after {
  content: "";
  display: none;
}

.bold {
  font-family: $font-bold;

}

input[name="form[gravity_signs_detail][]"]:checked + label {
  color: white;
  background: $red;
  font-weight: bold;
}

.box-top-right {
  position: absolute;
  top: 20px;
  right: 20px;
}


.btn-success, .btn-report {
  background: $green;
  border-color: $green;
  color: #fff;

  &:hover, &:focus, &:active {
    background: darken($green, 15);
  }

  &.btn-reverse {
    background: #fff;
    border-color: $green;
    color: $green;

    &:hover {
      background: darken($green, 15);
      color: #fff;
    }
  }

}


a, input, button, label {
  &.btn-patient-profile {
    font-weight: bold;
    padding: 2px 8px;
    border: none;
    color: white;
    font-family: $font-bold;
    border-radius: 50px;
    margin-right: 5px;
    text-decoration: none;
    width: auto;
    cursor: pointer;
    white-space: nowrap;
  }
}

.sort-input {
  background: #fff;
  width: 120px;
  color: #656565;
  height: 25px;
}


.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff;
  padding: 10px 20px;
  z-index: 999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;

  &.toast-error {
    background: $red;
  }

  &.toast-success {
    background: $green;
  }
}

.as-toast {
  box-shadow: none;
  pointer-events: none;

  .alert {
    color: #fff;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 10px 10px 10px 20px;

    &.alert-success {
      background: $green;
    }
    &.alert-warning {
      background: $orange;
    }

    &.alert-error {
      background: $red;
    }
  }
}

textarea.h-180 {
  height: 180px;
}

.icon-info {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;

  .icon-info-poppin {
    display: none;
  }

  &:hover {
    .icon-info-poppin {
      display: block;
    }
  }
}

.on-error {
  color: $red;
  border: 1.5px solid $red;
  font-weight: bold;

  &:focus {
    border-color: $red;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $red;
  }
}

.on-error + .suffix {
  color: $red;
}

.icon-info-poppin {
  position: absolute;
  z-index: 999;
  background: #fff;
  width: 430px;
  bottom: 20px;
  left: -18vh;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
  padding: 5px;
  cursor: initial;
}

.w-30 {
  width: 30%;
  padding: 5px;
}

.input-separator {
  padding: 6px 2px;
  font-weight: bold;

}

.btn-float {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99999;
}


.table-symptoms {
  tbody, thead {
    tr {
      td, th {
        min-width: 200px;
        padding: 4px;
      }
    }
  }

  td {
    font-weight: normal;
    font-family: $font;
  }

  th, .text-left {
    font-weight: bold;
    font-family: $font-bold;
    color: rgb(0, 144, 204);
  }

}

.text-underline {
  text-decoration: underline;
}

.report-page form div {
  position: relative;
}


div.cadre, h2.cadre {
  margin-top: 10px;

  width: 200px;
  /* Leur hauteur est définie automatiquement
     en fonction de la taille du contenu */
  height: auto;

  /* La distance entre le contenu et la bordure
     est 20px à droite et à gauche et est
     10px en haut et en bas */
  padding: 10px 20px 10px 20px;
  border: 3px solid $mid-blue;
}

.btn.btn-small {
  padding: 0 7px;
}

/*
Template management system
*/
.template-element {
  display: none;
}

.card-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 5px;
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  line-height: 35px;

  &.disabled {
    background-color: $grey;
  }
}

.inline {
  display: inline-block;
}


.voile-onboarding {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.49);
  z-index: 99;

  .onboarding--message {
    position: absolute;
    left: 50%;
    top: 40%;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    transform: translate(-50%, -50%);
    font-family: $font-bold;
  }
}

.browser-band {
  position: fixed;
  left: 0;
  right: 0;
  background: rgb(29, 29, 29);
  bottom: 0;
  height: 150px;
  padding: 20px 100px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  .close-band {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    cursor: pointer;
  }

  @media (max-width: $mobile-max) {
    display: none !important;
  }

}

.bg-red {
  background-color: $red;
}


.dropdown-inline {
  display: inline-block;

  .dropdown-menu {
    padding: 0;
  }

  a {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-family: $font;
    padding: 5px;
    border-bottom: 1px solid $grey;
  }
}

.flex-list {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}


.pagination span {
  border: 1px solid $default-color;
  border-radius: 5px;
  min-width: 27px;
  text-align: center;
  display: inline-block;
  margin: 0 2px;
  font-weight: bold;

  a {
    padding: 5px;
    display: inline-block;
  }

  &.current {
    padding: 5px;
    background: $success-color;
    color: #fff;
    border: 1px solid $success-color;
  }
}


.p1 {
  display: block;
  height: 22px;
  text-align: right;
}

.p2 {
  display: block;
  height: 16px;
}


.loader {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &.fixed {
    position: fixed;
  }

  background: #ffffff8a;
  z-index: 20;

  .loading-spinner {
    position: absolute;
    top: 50px;
    left: 45%;

    -webkit-animation: rotation 1.35s linear infinite;
    animation: rotation 1.35s linear infinite;

    &:nth-child(1) {
      stroke: $green
    }

    .circle {
      stroke-dasharray: 180;
      stroke-dashoffset: 0;
      -webkit-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
      -webkit-animation: turn 1.35s ease-in-out infinite;
      animation: turn 1.35s ease-in-out infinite;
    }
  }
}


@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}


@-webkit-keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}


@keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}


table .table-icon {
  color: $black;
  padding: 5px;

  &.blue {
    color: $default-color;

    &:hover {
      color: $green;
    }

  }

  &:hover {
    color: $green;
  }

}


.w-49 {
  width: 100%;
}


.password-checked {
  margin: 0;

  &.very_weak, &.weak {
    color: $red;
  }

  &.medium {
    color: $orange;
  }

  &.strong, &.very_strong {
    color: $green;
    font-family: $font-bold;
  }


}

.variables-name td {
  font-size: 12px;
}

.form-report .form-control {
  width: 90%;
}

.badge-danger {
  background-color: $dark-red;
  font-family: $font-bold;
}

.badge-danger-light {
  background-color: transparentize($dark-red, 0.3);
  font-family: $font-bold;
}

.badge-success-light {
  background-color: transparentize($green, 0.3);
  font-family: $font-bold;
}

.badge-warning-light {
  background-color: transparentize($orange, 0.3);
  font-family: $font-bold;
}

.badge-primary-light {
  background-color: transparentize($mid-blue, 0.3);
  font-family: $font-bold;
}

table .clickable {
  cursor: pointer;
  font-weight: bold;
}

table .table-order[order="ASC"] {
  .fa-sort-alpha-down, .sort-down {
    display: none;
  }
}

table .table-order[order="DESC"] {
  .fa-sort-alpha-up, .sort-up {
    display: none;
  }
}

div[id^="measure_group_form_types"] {
  margin-bottom: 15px;
}

.txt-deleted {
  text-decoration: line-through;
}

textarea[readonly] {
  cursor: not-allowed;
}

.flex-b-r {
  flex-direction: row-reverse;
}

.last-save {
  margin-top: 5px;
  margin-bottom: 0;
}

.documents-container, .table-container {
  //max-height: 500px;
  overflow: auto;
  // Else, my menu is not visible cuz it's in the scrollbar
  min-height: 300px;

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}

.h-150 {
  height: 150px;
}

.prescription-title {
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font;
  margin-bottom: 4px;
  float: left;
  max-width: 80%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
}

.big-input {
  font-weight: bold;
  font-size: 16px;
  font-family: $font-bold;
}


.popin-phone {
  width: 500px;
  max-width: 90%;
  overflow: visible;
}


@media (max-width: $mobile-max) {
  .main-container {
    padding: 0 5px;
  }

  .form-report {
    .free-text {
      display: block;

      label {
        display: none;
      }
    }

    .see-more {
      display: none;
    }
  }

  .self-evaluation-legend {
    display: none;
  }

  .gradiant-top {
    display: none;
  }

  .no-menu {
    header {
      background: transparent;

      button {
        display: none;
      }

      .logo {
        display: block;

        .logo-phone {
          height: 50px;
        }
      }
    }
  }
}


.service-header {
  line-height: 60px;
}

.step_one {
  .iti--allow-dropdown {
    margin-left: 40px;

    input[type="tel"] {
      width: 100% !important;
      padding-right: 18px !important;
    }
  }
}


.table {
  tr.data {
    border-top: 1px solid $mid-blue;
  }

  .r-answer {
    display: block;
    color: $mid-blue;
  }

  .r-freeText {
    font-weight: normal;
    font-family: $font;
    display: block;
  }
}


.dropdown-value {
  width: 100%;
  display: block;
  border-bottom: 1px solid $mid-blue;
  cursor: pointer;
  padding: 5px;

  .name {
    font-family: $font-bold;
    display: block;
  }

  .dateOfBirth {
    font-size: 12px;
    color: $black;
    display: block;
  }

  img {
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
    float: left;
  }

  &:hover {
    background: $mid-blue;

    .name, .dateOfBirth {
      color: #fff;
    }
  }
}

.form-control.tagify {
  height: auto;
  max-height: 80px;
  overflow: auto;
  box-shadow: none;
  min-height: 34px;
  overflow-x: hidden;
  padding: 0;
  line-height: 1;

  & + input {
    display: none;
  }
}

.tagify__dropdown {
  overflow-x: hidden;
}


.tagify-tag {
  img {
    width: 20px;
    border-radius: 50%;
    margin-right: 5px;
    float: left;
  }

  .name {
    font-size: 11px;
    line-height: 20px;
    font-family: $font-bold;
  }
}


.error_404 {
  display: none;
}

.pac-logo:after {
  display: none !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
}


.autocompleter-value.multi-line {
  padding: 6px;
  line-height: 13px;

  .v {
    display: block;
    font-style: initial;
    padding-bottom: 2px;

    &.bold {
      font-weight: bold;
      color: $dark-color;
    }
  }

  .sub-v {
    font-size: 12px;
  }
}


.input-autocomplete, .v2-autocomplete {
  position: relative;


  i {
    position: absolute;
    bottom: 7px;
    right: 10px;

    &.input-loader {
      right: 6px;
      bottom: 6px;
      color: $green;
      font-size: 18px;
    }
  }

  &.with-help-txt i.input-loader {
    bottom: 23px;
  }

}


.mg-top-10 {
  margin-top: 10px;
}

.mg-bottom-10 {
  margin-bottom: 10px;
}


.mic-btn {
  .fa-waveform {
    display: none;
  }

  &.active {
    .fa-waveform {
      display: initial;
    }

    .fa-microphone {
      display: none;
    }
  }
}

.transcript-text {
  display: inline-block;
  margin: 0;
}

.audio-container {
  margin-top: 10px;
}

.document-editor {
  .audio-container {
    position: absolute;
    top: 50px;
  }
}


.repeatable-row {
  hr {
    margin: 15px;
    border-top-color: $black;
  }
}


.bootstrap-tagsinput {
  .tag {
    position: relative;
    padding-left: 23px;

    [data-role="remove"] {
      position: absolute;
      left: 6px;
      top: 3px;
      margin-left: 0;
    }
  }
}


.v2-autocomplete {
  .bootstrap-tagsinput {
    overflow: hidden;
  }
}


.month-year-datepicker {
  .ui-datepicker-calendar {
    display: none;
  }

  .ui-datepicker-close {
    float: right;

  }
}


.touchable {
  cursor: pointer;
}

section[type="medics"] {
  text-align: right;
}


.dropdown-institutions {
  button {
    border: none;
    background: transparent;
    margin-right: 10px;
  }

  .dropdown-item:hover {
    box-shadow: 1px 1px 8px darken($grey, 10);
  }

  img:hover {
    box-shadow: none;
  }
}


.visibility-hidden {
  visibility: hidden;
}


.min-h-50 {
  min-height: 50px;
}


.popin-cgv {
  .content {
    overflow: auto;
    max-height: 450px;
    margin: 10px 0;
    padding-bottom: 30px;

    p, li {
      font-weight: bold;
      margin-bottom: 10px;
      letter-spacing: 0.3px;
      font-size: 14px;
    }

    h2, h3 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .popin-footer {
    box-shadow: 0px -15px 30px 30px #ffffff;
    z-index: 999;
    position: relative;

    .scroll-bottom {
      width: 40px;
      height: 40px;
      font-size: 25px;
      line-height: 40px;
      padding: 0;
    }
  }
}


.loading-progress {
  position: relative;
  z-index: 1000;
  text-align: center;
  padding-top: 60px;

}

.answer-free-text {
  display: block;
  font-size: 12px;
}


.variable_container {
  margin: 10px 0 25px 0;

  .toggle-var-group {
    display: block;

    h3 {
      display: inline-block;
    }

    i {
      display: inline-block;
      margin-top: 3px;
    }
  }

  ul {
    padding-left: 10px;
    list-style-type: none;

    li {
      margin-bottom: 5px;
    }
  }
}


.video-js {
  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.top-left {
  position: absolute;
  top: 5px;
  left: 5px;
}


.medical_team_avatar {
  max-width: 30px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}

.mg-bottom-19 {
  margin-bottom: 19px;
}


.txt-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
}


.v2-scale {
  flex-direction: column;

  .radio-scale {
    margin-left: 0 !important;

    label {
      height: auto;
      width: 100%;
      text-align: left;
      padding: 10px 5px;
      line-height: initial;
      padding-left: 5px !important;
      border: 1px solid white;
      box-sizing: border-box;
      margin-bottom: 5px;
    }
  }
}


.drag-container {
  position: relative;

  .drag-box {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    background: $grey;
    border: 2px dashed $green;
    display: none;

    .drag-box--content {
      pointer-events: none;
      position: absolute;
      top: 100px;
      text-align: center;
      display: block;
      width: 100%;
      font-size: 30px;
      font-weight: bold;
    }
  }
}


.stat-box {
  padding: 10px 10px 10px 50px;
  position: relative;
  margin-top: 10px;
  min-height: 80px;
  display: block;

  &.red {
    background-color: $red;
  }

  &.purple {
    background-color: $purple;
  }

  &.blue {
    background-color: $mid-blue;
  }

  &.green {
    background-color: $green;
  }

  &.orange {
    background-color: $orange;
  }

  i {
    color: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 50px;
    opacity: 0.5;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    text-align: right;
  }

  span {
    display: block;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    margin-top: 6px;
  }

}

.specialties-list {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.qs:not(.expanded) {
  .qs-expandable {
    display: none;
  }

  opacity: 0.5;
}


.sc:not(.expanded) {
  .sc-expandable {
    display: none;
  }

  opacity: 0.5;
}


.qs, .sc {
  position: relative;
  padding: 10px;

  hr {
    border-color: $black;
  }

  h3 {
    cursor: pointer;
  }

  .top-right {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .questions {
    border: 1px dashed;
    padding: 15px;
  }

  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }

  textarea {
    min-height: 100px;
  }

}


.mg-bottom-200 {
  margin-bottom: 200px;
}

.qs-list {
  min-height: 300px;
}


.alrt {

  .priority-4 {
    background-color: transparentize($dark-red, 0.80);
  }

  .priority-3 {
    background-color: transparentize($red, 0.80);
  }

  .priority-2 {
    background-color: transparentize($orange, 0.80);
  }


  hr {
    border-style: dashed;
  }

  .answ {
    color: $red;
    font-family: $font-bold;
  }
}

table.alerts {

  .alert-status-2, .alertType-priority-1 {
    background: $green;
  }

  .alert-status-1, .alertType-priority-4 {
    background: $red;
  }

  .alertType-priority-3 {
    background: $orange;
  }

  .alertType-priority-2 {
    background: $mid-blue;
  }

  img {
    max-height: 19px;
    border-radius: 50%;
    margin-top: -3px;
  }
}


.bootstrap-tagsinput.form-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  height: auto;
  min-height: 34px;
  padding: 2px 6px;
  font-size: 14px;
}


span.with-tooltip {
  color: $mid-blue;
  cursor: pointer;
  position: relative;

  .cs-tooltip {
    display: none;
    position: absolute;
    right: 20px;
    top: -10px;
    transform: translate(100%, -100%);
    background: #fff;
    border: 1px solid $mid-blue;
    border-radius: 5px;
    padding: 5px;
    font-size: 13px;
    min-width: 300px;
    font-family: $font;
    color: $black;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.31);
    cursor: default;

    .cs-tooltip--title {
      margin-bottom: 10px;
      display: block;
    }

    ul {
      padding-left: 10px;
      list-style-type: none;
    }

    li {
      margin-bottom: 10px;
    }

    strong {
      font-family: $font-bold;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $mid-blue;
      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }
}

.no-text-overflow {
  text-overflow: initial !important;
}

.archivable-status-2 {
  background: $orange;
}

.archivable-status-1 {
  background: $green;
}


.sc-title {
  .fa-chevron-down {
    display: none;
  }

  .fa-chevron-up {
    display: inline-block;
  }
}

.sc.expanded .sc-title {
  .fa-chevron-down {
    display: inline-block;
  }

  .fa-chevron-up {
    display: none;
  }
}


.qs-title {
  .fa-chevron-down {
    display: none;
  }

  .fa-chevron-up {
    display: inline-block;
  }
}

.qs.expanded .qs-title {
  .fa-chevron-down {
    display: inline-block;
  }

  .fa-chevron-up {
    display: none;
  }
}


.view_alert {
  &.priority-4 {
    color: $red;
  }

  &.priority-3 {
    color: $orange;
  }

  &.priority-2 {
    color: $mid-blue;
  }

  &.priority-1 {
    color: $green;
  }
}

.pointer {
  cursor: pointer;
}

.mg-5 {
  margin: 5px 15px 5px 0;
}

.mg-3 {
  margin: 3px;
}

.border-black {
  border-color: $black;
}

.m-l--15 {
  margin-left: -15px;
}


.dropdown-menu {
  padding: 5px;
}


.popin-action .imgs {
  border: 1px solid $black;
  text-align: center;
  max-height: 250px;
  overflow: scroll;

  img.mozaic {
    max-width: 48%;
    display: inline-block;
    margin: 1%;
  }
}

.input-inline {
  .bootstrap-tagsinput {
    /* max-height: 34px;

     .tag {
       max-width: 80%;
       overflow: hidden;
       display: inline-block;
     }*/
  }

  .v2-autocomplete--drawer {
    min-width: 100%;
    width: auto;

    span {
      min-width: 100%;
      width: auto;
      white-space: nowrap;
    }

  }

}


.action {
  display: none;
}

.has-actions {
  .file-input {
    display: none;
  }

  .mg-img:hover {
    .action {
      display: block;
    }
  }
}


.popin-fullscreen {
  position: fixed;
  top: 3vh;
  right: 15vw;
  left: 15vw;
  bottom: 10vh;
  width: 70vw;
  max-width: 70vw;
  transform: none;
  height: 87vh;
  max-height: 87vh;
}


.mh-400 {
  min-height: 400px;
}

.table-actions form, td form {
  display: inline-block;
}

.table-actions {
  min-width: 180px;
}


.documents {
  .doc-preview {
    position: absolute;
    left: -100px;
    top: 0;
    z-index: 999;
    display: block;
    border: 1px solid $black;
  }

  .td_doc_checkbox {
    width: 30px;
  }

  .td_doc_date {
    min-width: 110px;
  }

  .td_doc_type {
    min-width: 100px;
  }

  .td_doc_source {
    min-width: 200px;
  }

  .td_doc_status {
    min-width: 120px;
  }

  .td_doc_actions {
    min-width: 150px;
  }
}


.space-between {
  justify-content: space-between;
}


.controls i {
  margin: 5px;
  padding: 8px;
  border: 1px solid $mid-blue;
  border-radius: 50%;
  cursor: pointer;
}


.mh-100 {
  max-height: 100%;
}

.overflow-auto {
  overflow: auto;
}


.mobile-logo-admin {
  margin-left: 52%;
  width: 70px;
  height: 70px;
  background-size: contain;
  border: 1px solid black;
  background-repeat: no-repeat;
}

.flex {
  display: flex;
}


.search-patient-input {
  width: 330px;
}

.logout-loader {
  display: none;
}

.patient-documents .doc {
  width: 100%;
}


.stack-trace {
  width: 80vw;
  height: 60vh;
  overflow: scroll;
  text-align: left;
}

.settings-label-title {
  font-size: 12px !important;
  line-height: 16px !important;
  margin-bottom: 5px;
  color: var(--color-gray-900) !important;
  font-weight: var(--font-semibold) !important;
}

.bg-primary-300 {
  background-color: var(--color-primary-300) !important;
}

.border-primary-300 {
  border-color: var(--color-primary-300) !important;
}

.bg-danger-300 {
  background-color: var(--color-danger-300) !important;
}

.border-danger-300 {
  border-color: var(--color-danger-300) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-semi-bold {
  font-weight: bold !important;
}

.color-gray-900 {
  color: var(--color-gray-900) !important;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;

  i {
    font-weight: 300;
    margin-bottom: 20px;
  }

  p {
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-bottom: 5px !important;
    color: black !important;
  }

  a {
    margin-top: 20px;
  }
}

.margin-document-tabs {
  margin-top: -10px;
}

.scroll-bar {
  overflow: auto;
  display: flex;
}

.new-div {
  border: 2px solid darkorange;
  padding: 20px !important;
  position: relative;
  margin-top: 25px;
}

.new-div-button {
  position: absolute;
  top: -26px;
  left: -2px;
  background-color: darkorange;
  color: white;
  border: none;
  font-size: 12px;
  padding: 0 2px;
  font-weight: bold;
}

.line-through {
  text-decoration: line-through;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}

.ai-suggestion-item {
  padding: 10px 20px 10px 40px;

  border-bottom: 1px solid var(--color-gray-50);

  &:hover {
    background: var(--color-gray-100);
  }
}

.drawer-sidebar {
  display: block;
  position: fixed;
  width: calc(100% - 600px);
  height: 100vh;
  background: var(--color-white);
  top: 0;
  z-index: 99;
  right: calc(600px);
  max-width: 900px;


  .drawer-sidebar-header {
    padding: 20px 10px 10px 20px;

    h4 {
      color: var(--color-black);
      margin: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;

      i {
        font-size: 18px;
        cursor: pointer;
        color: var(--color-gray-500);
      }

    }

  }

  .drawer-sidebar-content .document {
    padding: 15px;
    border: 1px solid var(--color-gray-100);
    max-height: 80vh;
    overflow: auto;
    margin: 20px;
    p,.mention {
      font-size: 14px;
      color: var(--color-black);
      background: transparent;
    }

    &.with-iframe {
      padding: 0;
    }
    iframe {
      border: none;
    }

    .bg-warning-300 {
      background-color: var(--color-yellow-300);
    }

    .bg-warning-500 {
      background-color: var(--color-warning-300);
    }

  }

}
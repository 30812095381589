/**
 *  SUMMARY
 *    - VARIABLES
 *    - V1_FIX
 *    - BODY
 *    - TEXTS
 *    - GENERICS
 *    - FORMS
 *    - TAGS
 *    - DROPDOWNS
 *    - BUTTONS
 *    - POPUP_TOAST
 *    - NAVIGATION
 *    - MODAL
 *    - GRID_BLOCKS
 *    - PROFILE
 *    - PATIENT_FILE
 *    - LISTS
 *    - OTHERS
 *    - CALENDAR
 *    - TABLER
 *    - DISCUSSIONS
 *    - VISIO
 *    - EDITOR
 *    - QUESTIONNAIRE
 *    - SUBSCRIPTION
 *    - FAQ
 *    - SWITCHER
 *    - PRINT
 **/

/***************************/
/*       VARIABLES         */
/***************************/
@import './variables.scss';

/***************************/
/*         V1_FIX          */
/***************************/
.menu li i {
  margin-right: 3px;
}

/***************************/
/*          BODY           */
/***************************/
body.v2-css {
  font-family: var(--font-family);
  @extend .text-base;
  color: var(--color-gray-900);
  height: 100%;
  min-height: 100%;

  /***************************/
  /*         LAYOUT          */
  /***************************/
  @import './layout';

  /***************************/
  /*       TEXTS             */
  /***************************/
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family) !important;
    font-weight: bold;
  }

  h1 {
    @extend .text-3xl;
  }

  h2 {
    @extend .text-2xl;
  }

  h3 {
    @extend .text-xl;
  }

  h4 {
    @extend .text-lg;
  }

  h5 {
    @extend .text-base;
  }

  h1, h2, h3 {
    color: var(--color-black);
  }

  .text-black {
    color: var(--color-black);
  }

  .text-gray-500 {
    color: var(--color-gray-500);
  }

  .text-danger-500 {
    color: var(--color-danger-500);
  }

  h4, h5, h6 {
    font-weight: var(--font-semibold);
    color: var(--color-gray-500);
  }

  a:not(.btn) {
    font-family: var(--font-family) !important;
    font-weight: var(--font-normal) !important;
    @extend .text-sm;
    color: var(--color-primary-500);

    &:hover {
      text-decoration: underline;
    }
  }

  p, .page--content ul li {
    &, a:not(.btn) {
      @extend .text-xs;
    }

    &, strong {
      font-family: var(--font-family) !important;
    }

    font-weight: var(--font-normal);
    color: var(--color-gray-500);

    &.text-black {
      color: var(--color-black);
    }

    strong {
      font-weight: var(--font-bold);
    }
  }

  .page--content p {
    line-height: 200%;
  }

  .underline {
    background-color: var(--color-underline);
    height: 1px;
    border-bottom: 0;
    color: initial;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .text-gray-900 {
    color: var(--color-gray-900) !important;
  }

  /***************************/
  /*         GENERICS        */
  /***************************/
  .loader .loading-spinner:nth-child(1) {
    stroke: var(--color-gray-900);
  }

  .input-loader {
    color: var(--color-gray-900) !important;
  }

  video {
    border: 1px solid var(--color-gray-50);
    border-radius: var(--rounded-lg);
  }

  .box {
    @extend .v2__box_shadow;
  }

  .strip {
    margin-left: 0;
  }

  .gradiant-top {
    display: none;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .separator {
    border-bottom: 1px solid var(--color-gray-50);
    width: 100%;
  }


  .tab {
    border: 0px !important;
  }

  .nav.nav-tabs .nav-link {
    border-radius: 5px !important;
  }

  .information {
    p {
      margin: 20px 0;
    }

    .actions {
      a, input, button {
        @extend .text-sm;
        height: 36px;

        .material-icons {
          @extend .text-lg;
        }
      }
    }
  }

  .file-item {
    @extend .v2__box;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: var(--color-primary-50);
    }

    a {
      font-family: var(--font-family);
      @extend .text-xs;

      &, &:hover {
        text-decoration: none;
      }
    }

    .file-item__link {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 250px;
      white-space: nowrap;

      .doc-preview {
        left: 0;
        position: absolute;
        top: -120px;
        z-index: 10;
        @extend .v2__box;
      }
    }

    .file-item__actions {
      display: flex;
      gap: 10px;
      flex-shrink: 0;

      .file-item__action {
        color: var(--color-gray-300);

        &:hover {
          color: var(--color-gray-400);
        }

        .material-icons {
          @extend .text-xl;
        }
      }
    }

    .view-link {
      flex-grow: 1;
      font-weight: var(--font-medium);
      color: var(--color-primary-500);

      &:hover {
        text-decoration: underline;
      }
    }

    .generated-by {
      margin-left: auto;
      color: var(--color-gray-300) !important;
    }
  }

  .w-full {
    width: 100%;
  }

  .gap-15 {
    gap: 15px;
  }

  .gap-2 {
    gap: 0.5rem;
  }

  .gap-4 {
    gap: 1rem;
  }

  .flex-nowrap {
    flex-wrap: nowrap;
  }

  .items-center {
    align-items: center;
  }

  .items-end {
    align-items: flex-end;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .justify-center {
    justify-content: center
  }


  .iframe-metabase {
    width: 100%;
    height: 100vh;
    border: 0;
    display: flex;
    flex-grow: 1;
  }

  .h-full {
    height: 100%;
  }

  .bg-transparent {
    background-color: transparent;
  }

  .dropdown-item.new {
    background: var(--color-primary-500);
    color: var(--color-white);
    position: relative;

    &:after {
      content: "Nouveau";
      position: absolute;
      display: block;
      bottom: -5px;
      font-size: 8px;
      font-weight: bold;
      background: var(--color-warning-500);
      height: 10px;
      color: #fff !important;
      z-index: 9;
      overflow: visible;
      width: auto;
      line-height: 7px;
      padding: 2px;
      text-align: center;
      right: -15px;
      transform: rotate(340deg);
    }
  }

  /***************************/
  /*          FORMS          */
  /***************************/
  @import './forms';

  /***************************/
  /*          TAGS           */
  /***************************/
  .bootstrap-tagsinput {
    .tag,
    .autocompleter-value {
      @extend .text-sm;
      font-family: var(--font-family);
      font-weight: var(--font-medium);
    }

    input {
      margin: 0;
      padding: 0;
    }

    .tag {
      @extend .v2__tag;
    }
  }


  .tagsinput-drawer-loader {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border: none;
    width: auto;

    span {
      font-size: 12px;
      color: (var(--color-gray-500));
      border: none;
      width: auto;
      padding: 0;
    }
  }

  /***************************/
  /*        DROPDOWNS        */
  /***************************/
  .autocomplete-error {
    @extend .text-xs;
    color: var(--color-gray-900);
    text-align: center;
    font-family: var(--font-family);
    padding: 14px;
  }

  .input-autocomplete {
    i {
      bottom: 16px;

      &.input-loader {
        color: var(--color-primary-500);
      }
    }
  }

  .autocomplete, .autocompletor, .v2-autocomplete--drawer {
    @extend .v2__box_shadow;
    overflow: auto;
  }

  .v2-autocomplete, .autocompleter-value {
    border: 0;
    overflow: visible;

    i {
      line-height: 28px;
    }

    &, .v, .bold, .sub-v {
      font-family: var(--font-family);
      font-style: normal;
    }


    .v.bold {
      @extend .text-sm;
      font-weight: var(--font-semibold);
      color: var(--color-gray-900);
    }

    .sub-v {
      @extend .text-xs;
      color: var(--color-gray-400);

    }

    img, .v2_rounded_avatar {
      @extend .avatar_default;
      float: left;
      margin-right: 10px;
    }

    .input-loader {
      @extend .text-lg;
      bottom: 16px;
    }

    &.unique {
      height: var(--input-height);

      &.with-help-txt {
        margin-bottom: 25px;
      }

      &.with-error {
        height: var(--input-height-with-error);
      }

      .bootstrap-tagsinput {
        height: var(--input-height);
        line-height: 35px;
        padding: 0 5px;
        white-space: nowrap;

        input {
          height: var(--input-height);
        }
      }

      .input-loader {
        bottom: 7px;
      }
    }

    .input-loader {
      bottom: 11px;
    }

    &.selected {
      background-color: var(--color-gray-100);
    }

  }


  .v2-autocomplete--drawer.cim11 {
    max-height: 400px;
    padding-bottom: 15px;
    overflow-y: scroll;
    position: relative;
    overflow-x: hidden;

    .autocompleter-value {
      display: flex;
      flex-direction: row;
      height: 40px;
      padding: 0 var(--p-2);
      border: 1px solid var(--color-gray-100);
      gap: var(--p-2);
      line-height: 40px;
      margin: 15px 10px;
      border-radius: 10px;
      width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;

      i, em {
        display: flex;
        position: initial;
        line-height: 40px !important;
        font-weight: var(--font-normal) !important;
      }

      em {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.level-4 {
        margin-left: 5em;
        width: calc(100% - 6em);
      }

      &.level-3 {
        margin-left: 3em;
        width: calc(100% - 4em);
      }

      &.level-2 {
        margin-left: 1em;
        width: calc(100% - 2em);
      }

      .hover {
        display: none;
      }

      .not-hover {
        display: block;
      }


      &:hover {

        background-color: var(--color-primary-100);
        color: var(--color-primary-500);
        border: 1px solid var(--color-primary-500);


        i {
          color: var(--color-primary-500);
        }

        .hover {
          display: block;
        }

        .not-hover {
          display: none;
        }

      }
    }


    .autocomplete-error {
      border: none;
      padding-bottom: 0;
    }

  }


  .v2_user_autocomplete {
    display: flex;
    align-items: center;

    > * {
      margin: 0;
    }

    > span {
      border: 0;
    }
  }

  .v2-autocomplete--drawer {
    border: 1px solid var(--color-gray-50);
    box-shadow: var(--shadow-box);
  }

  .autocompleter-group {
    @extend .text-base;
    font-weight: var(--font-semibold);
    color: var(--color-gray-900);
    border: 0;
    background-color: var(--color-primary-50);
    height: 40px;
    padding-left: 15px;
    line-height: 30px;
    @extend .v2__ellipsis;
  }

  .v2-autocomplete.small_height_autocomplete {
    > div.v2-autocomplete--drawer {
      max-height: 100px;
    }
  }

  .not-found-link {
    width: 100%;
    display: block;
    border: 1px solid rgba(214, 219, 222, 0.20%);
    border-top: 0;
    padding: 10px 10px;
    box-shadow: 0 5px 14px 0 rgb(0 0 0 / 20%);
    border-radius: 0 0 0.5rem 0.5rem;
    font-weight: 600;
    position: absolute;
    z-index: 10;
    background: var(--color-white);
    line-height: 1.4 !important;
    font-size: 14px !important;
  }


  .document-pagination-loader {
    text-align: center;
    line-height: initial;
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    gap: 10px;

    span {
      font-size: 16px;
    }
  }


  /***************************/
  /*       BUTTONS           */
  /***************************/
  .btn,
  input[type="submit"],
  input[type="button"],
  button[type="submit"],
  a.btn,
  button.btn,
  label.btn {
    font-family: var(--font-family) !important;
    font-weight: var(--font-semibold) !important;
    border-radius: var(--rounded-lg);
    padding: 0 var(--p-4) !important;
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: var(--h-btn-base);

    &.small {
      height: var(--h-btn-small);
    }

    &.large {
      height: var(--h-btn-large);
    }

    span + span.text {
      margin-left: 10px;
    }

    span + span.text:not(.neverHide) {
      display: none;
    }

    .material-icons {
      @extend .text-sm;
    }

    @include md {
      span + span.text:not(.neverHide) {
        display: block;
      }
    }
  }

  // by default should be a primary
  .btn.btn-primary,
  button.btn.btn-primary,
  input[type="submit"].btn.btn-primary,
  a.btn.btn-primary,
  label.btn.btn-primary {
    @extend .v2__button_primary;
  }

  // default is a secondary type
  input[type="button"],
  button[type="submit"],
    // secondary
  .btn.btn-secondary,
  button.btn.btn-secondary,
  a.btn.btn-secondary {
    @extend .v2__button_secondary;
  }

  input[type="button"].btn-secondary-success,
  button[type="submit"].btn-secondary-success,
    // secondary sucess
  .btn.btn-secondary-success,
  button.btn.btn-secondary-success,
  a.btn.btn-secondary-success {
    @extend .v2__button_secondary-success;
  }

  .btn.btn-light,
  button.btn.btn-light,
  a.btn.btn-light {
    @extend .v2__button_light;
  }

  .btn.btn-ai,
  button.btn.btn-ai,
  a.btn.btn-ai {
    @extend .v2__button_ai;

    &.btn-reverse {
      @extend .v2__button_ai_light;

    }

  }

  .btn.btn-hover-danger,
  button.btn.hover-danger,
  input[type="submit"].btn.hover-danger,
  a.btn.hover-danger {
    @extend .v2__button_hover-danger;
  }

  .btn,
  button.btn,
  a.btn,
  label.btn {
    &.btn-warning {
      &, &.btn-reverse {
        @extend .v2__button_warning;
      }
    }

    &.red-button, &.btn-danger {
      &, &.btn-reverse {
        @extend .v2__button_danger;
      }
    }

    &.btn-danger-light {
      &, &.btn-reverse {
        @extend .v2__button_danger_light;
      }
    }

    &.btn-success {
      @extend .v2__button_success;
    }
  }

  .btn-full {
    width: 100%;
  }

  .mg-r-0 {
    margin-right: 0;
  }

  .pd-0 {
    padding: 0;
  }

  .mg-r-15 {
    margin-right: 15px;
  }

  .toggle {
    background-color: var(--color-primary-500);
  }

  .s-alert-container {
    margin: 0;
    padding: 0;
    @extend .v2__alerts;
  }

  .s-alert-container .with_decoration {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .badge {
    border-radius: var(--rounded);
    font-family: var(--font-family);
    display: inline-flex !important;
    gap: 5px;
    max-width: 100%;
    align-items: center;
    line-height: initial;

    .material-icons {
      font-size: var(--font-size-sm);
      flex: 0 0 auto;
    }

    &.hidden {
      display: none !important;
    }

    .txt {
      @extend .v2__ellipsis;
    }
  }

  .badge-important {
    background-color: var(--color-danger-100);
    color: var(--color-danger-500);
    font-weight: var(--font-normal);

    .material-icons {
      color: var(--color-danger-500) !important;
    }
  }

  .archivable-status-1 {
    background-color: var(--color-success-300);
  }

  .archivable-status-2 {
    background-color: var(--color-warning-300);
  }

  // medical
  .medical-background-1,.patient-treatment-background {
    background-color: var(--color-primary-500);
  }


  // family
  .medical-background-2,.patient-treatment-spot {
    background-color: var(--color-success-500);
  }

  // chirurgical
  .medical-background-3 {
    background-color: var(--color-warning-500);
  }

  // not medical
  .medical-background-4 {
    background-color: var(--color-gray-500);
  }

  // gyneco
  .medical-background-5 {
    background-color: var(--color-yellow-500);
  }

  .v2__block .small {
    @extend .text-xs;
  }


  .v2_rounded_avatar {
    color: var(--color-white);
    background-color: var(--color-primary-500);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--font-semibold);
    font-family: var(--font-family);
    text-decoration: none !important;

    @extend .avatar_default;

    &.xs {
      @extend .avatar_xs;
    }

    &.xl {
      @extend .avatar_xl;
    }

    &.xxl {
      @extend .avatar_xxl;
    }
  }

  .auto-save-band {
    background-color: var(--color-warning-50);
    margin-right: 0;

    @include md {
      margin-right: 52px;
    }

    .save-band {
      width: 100%;
      display: block;
      flex-direction: row;

      .text {
        display: inline-block;
        color: var(--color-gray-700);
        font-weight: var(--font-semibold);
        @extend .text-base;
        flex: 1 1 auto;
        line-height: 36px;
        margin-right: 15px;;
      }

      .button_container {
        display: inline-block;
        flex: 0 0 auto;
      }
    }
  }

  .card-input {
    height: var(--input-height);
    font-weight: var(--font-medium);
    border-radius: var(--rounded);
    border: 1px solid var(--color-gray-100);
    color: var(--color-gray-900);
    font-family: var(--font-family);
    padding: 0 12px;
  }

  /***************************/
  /*       POPUP_TOAST       */
  /***************************/
  .toast {
    font-weight: var(--font-semibold);
    border-radius: var(--rounded-sm);
    box-shadow: var(--shadow-box-popup);

    &.toast-error {
      background: var(--color-danger-500);
      border: 1px solid var(--color-danger-500);
    }

    &.toast-warning {
      background: var(--color-warning-500);
      border: 1px solid var(--color-warning-500);
    }

    &.toast-success {
      background: var(--color-success-500);
      border: 1px solid var(--color-success-500);
    }
  }

  .tuto_container {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      flex: 1 1 auto;
      margin-bottom: 0;
    }
  }

  @include lg {
    .tuto_container {
      flex-wrap: nowrap;
    }
  }

  .line_height_0 {
    line-height: 0;
  }

  .tab.tab-users {
    > .relative {
      @extend .container_loader;
    }
  }

  .admin_dashboard {
    .statistics {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .stat-box {
        @extend .v2__box_shadow;
        background-color: var(--color-white);
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        min-width: 200px;

        i {
          height: 100%;
          color: white;
          position: relative;
          top: unset;
          left: unset;
          padding: 10px;
          opacity: 1;
          line-height: 1.75em;
          min-width: 60px;
          text-align: center;
          font-size: 30px;
        }

        h3 {
          height: 100%;
          padding: 10px 30px;
          margin: 0;
          width: 100%;
          text-align: left;

          @extend .text-base;

          span {
            color: var(--color-gray-900);
            display: inline-block;
            margin-left: 15px;
          }
        }

        &.primary {
          i {
            background-color: var(--color-primary-500);
          }
        }

        &.primary-light {
          i {
            background-color: var(--color-primary-300);
          }
        }
      }
    }

    .data {
      border-top: 1px solid var(--color-gray-50);
      padding: 15px;

      .result {
        display: flex;
        gap: 10px;
      }
    }

    .filters_fields {
      width: 100%;
    }


    @include md {
      .statistics {
        flex-direction: row;

        .stat-box {
          h3 {
            width: 100%;
            text-align: center;

            span {
              display: block;
              margin-left: 0;
            }
          }
        }
      }
    }
  ;
  }

  .errorPage {
    display: flex;
    flex-direction: column;

    .errorHeader {
      padding: 25px 70px;

      img {
        width: 250px;
      }
    }

    #main-container {
      display: flex;
      align-items: center;
      min-height: calc(100vh - 130px) !important;
      justify-content: center;

      h1, h2, h3 {
        font-weight: var(--font-normal);
      }

      h1 {
        line-height: 30px;
        font-size: 30px;
        font-weight: var(--font-semibold);
      }

      h2 {
        line-height: 23px;
        font-size: 23px;
        margin-top: 15px;

        a {
          font-size: inherit;
          font-weight: var(--font-bold);
        }
      }

      h3 {
        line-height: 19px;
        font-size: 19px;
      }

      .illustration {
        background-image: url('../../../images/v2/woman_state.svg');
        background-size: 200px;
        min-height: 301px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  /***************************/
  /*       NAVIGATION        */
  /***************************/
  @import "./menu";
  @import "./top-bar";
  @import "./sidebar";

  /***************************/
  /*          MODAL          */
  /***************************/
  @import './modal';

  /***************************/
  /*          DRAWERS        */
  /***************************/
  @import './drawer';

  /***************************/
  /*      GRID_BLOCKS        */
  /***************************/
  @import './grid-blocks';

  /***************************/
  /*     PATIENT_FILE        */
  /***************************/
  @import './patient-file';

  /***************************/
  /*          LISTS          */
  /***************************/
  @import './lists';

  /***************************/
  /*          TABLER         */
  /***************************/
  @import './tabler';

  /***************************/
  /*          VISIO          */
  /***************************/
  @import './visio';

  /***************************/
  /*         EDITOR          */
  /***************************/
  @import './editor';

  /***************************/
  /*         QUESTIONNAIRE   */
  /***************************/
  @import './questionnaire';

  /***************************/
  /*         SUBSCRIPTION   */
  /***************************/
  @import './subscription';

  /***************************/
  /*         FAQ             */
  /***************************/
  @import './faq';

  /***************************/
  /*       SWITCHER          */
  /***************************/
  @import './switcher';


  /***************************/
  /*     DRAG & DROP         */
  /***************************/
  @import './drag-and-drop';

  /***************************/
  /*       PRINT          */
  /***************************/
  @import './print';


  @import "app-store";

  /***************************/
  /*         SETTINGS        */
  /***************************/

  .pro-sante-connect-btn-icon {
    width: 20px;
    margin-right: 10px;
  }

}

.v2__block .list-content .diseases {
  &:after {
    content: " ";
    display: block;
    background: var(--color-gray-100);
    height: 1px;
    margin: 0 10px;
  }
}

.BOTO-6-caption {

  .d-flex.justify-content-start {
    display: flex;
    justify-content: start;
    gap: 4px;
  }

  .d-flex.justify-content-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
      display: flex;
      gap: 4px;
      align-items: center;

      span {
        text-decoration: underline;
        color: var(--color-black);
      }
    }
  }

  .text-75 {
    font-size: 0.9rem;
  }

  .text-50 {
    font-size: 0.75rem;
  }

  .text-grey-75 {
    color: #9b9b9b;
  }

  .mr-1 {
    margin-right: 4px;
  }

  .mb-2 {
    margin-bottom: 8px !important;
  }

  .mt-2 {
    margin-top: 8px;
  }
}

.synapse-app .component-patient-profile-composite-theriaque.is-widget .profile-allergies {
  position: relative;
  z-index: 2;
}

.z-1 {
  z-index: 1;
}

.synapse-app .posology-form-header {
  width: 100%;
}

.synapse-app.adverse-effect-checker .col-lg-8 {
  max-width: 100%;
}

.synapse-app {
  .btn.btn--blue {
    background-color: #003753 !important;
  }
}


.synapse-widget .component-certification-dm-tooltip-v2, .component-certification-dm-tooltip .tooltip-content {
  p, span, a {
    color: var(--color-black);

    &.text-grey-70 {
      color: var(--grey-75);
    }
  }

  &.position-right {
    top: 35px;
  }

}

.synapse-widget .prescription-widget .posology-form-container {
  position: relative;
}


.prescription-widget .favorite-posologies-selector {
  display: none !important;
}

.synapse-app .posology-model-expand-button,
.synapse-widget .posology-model-expand-button {
  display: none !important;
}

.synapse-app .expandable-validate .separator,
.synapse-widget .expandable-validate .separator {
  display: none !important;
}

.synapse-app .posology-form .component-select .options,
.synapse-widget .posology-form .component-select .options {
  width: 100% !important;
}


.synapse-app .component-researchBarComposite .row,
.synapse-widget .component-researchBarComposite .row {
  margin: 0 !important;
}

.synapse-app .posology-form,
.synapse-widget .posology-form {
  max-width: 100%;

  header {
    padding: 0;
    height: initial;
  }

}

.synapse-app .posoform-additional-infos-section .checkboxes-section,
.synapse-widget .posoform-additional-infos-section .checkboxes-section {
  gap: 1ch;
}

.synapse-app .number-input-component .number-input-field,
.synapse-widget .number-input-component .number-input-field {
  height: 4ch;
}

.synapse-widget.z-1 {
  z-index: 10;
  position: relative;

  .advanced-overlay {
    max-width: 600px;
    position: fixed !important;
    top: 10% !important;
    transform: translate(-50%, 0) !important;
    left: 50% !important;
  }

  input {
    height: initial;
    padding: 0;
    border: none;
  }

  button label {
    display: flex;
  }

  [data-testid="recurrentFrequency"], [data-testid="discontinuousFrequency"] {
    label {
      display: flex;
    }
  }

}

.posology-checker, .posology-list {
  margin-top: 15px;
}

.synapse-app .additional-infos-options .options-content .date-inputs .input-wrapper,
.synapse-widget .additional-infos-options .options-content .date-inputs .input-wrapper {
  width: 100% !important;
}

.synapse-widget .prescription-card .prescription-card-header {
  padding: 0;
  height: initial;
}

.synapse-app .summary-posology .summary-posology-header,
.synapse-widget .summary-posology .summary-posology-header {
  height: auto;
  padding: 0 5px;
}

.synapse-app button.btn,
.synapse-widget button.btn {
  background-color: transparent !important;
}

.synapse-app .component-button,
.synapse-widget .component-button {
  color: var(--color-white);
  background-color: var(--color-gray-900);

  &.component-button-small--neutral {
    color: var(--color-gray-900);
    background-color: var(--color-white);
  }
}

.synapse-app .posology-form-tooltip-plus > .popover .popover-content .popover-button,
.synapse-widget .posology-form-tooltip-plus > .popover .popover-content .popover-button {
  display: block !important;
}

.synapse-app .popover {
  display: block !important;
}

.synapse-app .card-action-button {
  display: block !important;
}

.synapse-widget .prescription-widget {
  margin: 10px auto 0 auto;
  min-height: auto !important;
  max-width: initial;
  overflow: visible;

  .prescription-content {
    margin-top: 0;

    .mx-3 {
      margin: 0 0 5px 0;
    }
  }

  .medical-resources-autocomplete {
    max-width: 100%;

    &:hover,&:focus,&:active {
      z-index: 31;
    }
  }
}


.synapse-app .posology-form .dose-unit-autocomplete .component-search .options {
  width: auto;
}

.synapse-app .component-profile-header {
  display: flex;
  gap: 15px;

  .row.mt-3 {
    margin-top: 0 !important;
  }
}

.synapse-app .component-dynamicUnitInput .input-group {
  padding-right: 15px;

  .input-group-addon {
    border: none;
    background: transparent;
  }
}

div[id^="select-dropdown-"] {
  background: #fff;
  border: 1px solid var(--color-gray-900);
  border-radius: 5px;
  z-index: 99;
  position: relative;

  button {
    padding: 10px;
    font-size: 14px;

    &:hover {
      background: var(--color-gray-100);
    }
  }
}

.synapse-widget[role="dialog"] {
  z-index: 99;
}

.synapse-app, .synapse-widget {

  .card-action-button {
    display: flex !important;
  }

  fieldset label {
    line-height: initial;
    width: 100%;
  }

  .prescription-duration-quantity-input,
  .prescription-times-renew-input,
  [data-testid^="quantity"],
  [data-testid^="renewal"] {
    input {
      background: transparent;
      padding: 0;
      height: initial;
      border: none;
    }
  }

  [data-testid="no-generic-substitution-select"] {
    margin-top: 25px !important;
  }

  button div select {
    position: absolute;
  }

  .autocomplete-line-container {
    display: block !important;
  }

  .toggle {
    background-color: transparent;
  }

  input[type="checkbox"], input[type="radio"] {
    display: none;
  }

  input[type="text"] {
    border: none;
    padding: 0;
    background: transparent;
  }

  .prescription-list {
    padding: 0 !important;
    margin-bottom: 5px;
  }

  header {
    display: initial !important;
    height: auto;

    &.summary-posology-header, &[data-testid="prescription-header"] {
      padding: 0;
    }

    .header-section-container, .medical-resources-autocomplete {
      button {
        display: block !important;
      }

      label {
        display: flex;

        input {
          border: none;
          padding: 0;
          background: transparent;
          height: initial;
        }
      }
    }
  }
}

div[role="tooltip"] {
  background: #fff;
  color: var(--color-gray-900);
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
}

div[class$="backdrop"] {
  z-index: 9;
}

.v2__editor {
  .editor_fields {
    .posology-detail-btn {
      margin: 50px 0 -70px auto;
      display: block;
      position: relative;
      z-index: 99;
    }
  }
}

button[data-testid="add-non-medicated-line-btn"] {
  margin-top: 10px;
}

.non-medicated-card-content {
  fieldset label {
    padding: 0 10px;

    button {
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }
}

.patient-recent-text {
  padding-left: 16px;
  margin-bottom: 8px;
  color: var(--color-black) !important;
  font-size: 14px;
  font-weight: 600;
}

.search-input {
  background-color: var(--color-gray-50) !important;
  color: var(--color-gray-600);
  padding-left: 25px !important;
}
.search-input-icon {
  position: absolute;
  left: 5px;
  top: 10px;
  font-size: 18px;
  color: var(--color-gray-600);
  pointer-events: none;
}


// Main container styles
.patient-search-item {
  &:hover {
    background-color: var(--color-gray-50);
  }

  border-bottom: 1px solid var(--color-gray-100);

  &:last-child {
    border-bottom: 0;
  }


  // Link styles
  .link {
    display: flex;
    overflow: hidden;
    gap: 1rem;
    text-decoration: none !important;
    padding: 1.5rem 1rem;
    justify-content: space-between;
    max-width: 100%;
    color: var(--color-black);
  }

  // Inner container styles
  .inner-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    text-overflow: ellipsis;
  }

  // Name styles
  .name {
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-black) !important;
  }

  // Subtitle styles
  .subtitle {
    font-size: 0.875rem;
    text-align: left;
    color: var(--color-gray-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  .inner-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  // Icon container styles
  .icon-container {
    margin: auto 0;
  }

  // SVG styles
  .icon {
    width: 24px;
    height: 25px;
    fill: none;

    path {
      fill: #9CA7AF;
    }
  }
}


.patient-search-container {
  max-height: 90%;
  overflow: auto;
}

#patients-list--drawer.drawer .drawer-content {
  padding-bottom: 0;
}
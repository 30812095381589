@import "variables";

@import "../../../node_modules/intl-tel-input/build/css/intlTelInput.css";

form {
  .form-col {
    .col-form-label + .small {
      margin-top: 5px;
    }
  }

  #actions-container {
    text-align: center;
    margin-top: -5px;

    &.edit-profile-action {
      text-align: center;
      margin-top: 15px;
    }

    input, button {
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
      width: 150px;

      &[type="checkbox"],&[type="radio"] {
        width: auto;
        height: auto;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .multifieldElement {
    border: 1px solid $success-color;
    padding: 5px 5px 0 5px;
    margin-bottom: 10px;
    border-radius: 5px;

    .multifieldElement {
      border: 1px dashed;
      padding: 5px 5px 0 5px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
  }

  .multifieldElementContainer {
    border: 1px solid $success-color;
    padding: 5px 5px 0 5px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
}

canvas.signature {
  border: 1px solid black;
  display: block;
  margin-bottom: 5px;
  background-color: #fff;
}

.remove-link {
  margin-bottom: 0.25rem;

  + .form-group {
    clear: both;
  }
}

fieldset {
  width: 100%;
}

.compact-form .form-group {
  margin-bottom: 2px;
}

.form-with-sep .form-group:nth-child(2n) {
  background: $grey;
}


.as-filter span {
  display: none;
}


.compact-form {
  .col-form-label {
    min-height: 28px;
    margin-bottom: 0;
    line-height: 22px;
    padding-top: 4px;

    &[data-help] {
      min-height: 50px !important;
    }

  }

  .form-control {
    height: 28px;
    padding: 3px 12px;
  }


  textarea.form-control {
    height: auto;
  }

  .col-form-legend {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 1em;
    font-weight: bold;
    font-family: $font-bold;
  }

  .form-collection-list {
    list-style: none;
    padding: 0;
  }

  .form-group {
    margin-bottom: 10px;
  }

}

.radio-scale-container {
  display: flex;
}

.radio-scale {
  margin: 0;
  flex: 1 1 auto;

  label {
    padding: 0;
    height: 3.5rem;
    width: 3.5rem;
    text-align: center;
    line-height: 3.5rem;
  }
}

.radio--hidden {
  display: none;
}

input[type="radio"]:checked + label {
  background-color: $success-color;
  color: $black;
}

input[type="checkbox"] {
  margin-right: 3px;
  display: inline-block;
}

label.required:after {
  content: " *";
  color: $red;
}

form {
  #inputs-container {
    margin-top: -10px;
    width: 97%;

    .input-container {
      margin-bottom: 5px;
    }

    label {
      float: left;
      width: 50%;
    }

    button, input, select {
      float: right;
      width: 50%;
    }

    .dateOfBirthSelect {
      float: right;
      width: 50%;
      select {
        float: none;
        width: 30%;
      }
    }

    select[multiple] {
      width: auto;
    }

    input.pull-left {
      width: 40%;
      margin-bottom: 5px;
      background-color: $red;
      border: 1px solid $red;
    }

    button.pull-left {
      width: 40%;
      margin-bottom: 5px;
      background-color: $red;
      border: 1px solid $red;

      &:hover, &:focus {
        color: $red;
        background-color: white;
      }
    }

    input {
      &.pull-left {
        &:hover, &:focus {
          color: $red;
          background-color: white;
        }
      }

      &.pull-right {
        width: 20%;
      }
    }
  }

  .image-container {
    margin-bottom: 5px;

    #dropfile {
      width: 110px;
      border: 2px dashed $grey;
      line-height: 15px;
      font-size: 0.85em;
      text-align: center;
      float: right;
      height: 100px;
      margin-top: -10px;
      background-color: white;

      img {
        height: auto;
        vertical-align: middle;
        border: 0;
        -ms-interpolation-mode: bicubic;
        max-height: 100%;
        max-width: 100%;
        float: none;
        border-radius: 0;

        &.illustration {
          margin-top: 8px;
        }
      }
    }
  }

  #actions-container {
    text-align: center;
    margin-top: -5px;

    &.edit-profile-action {
      text-align: center;
      margin-top: 15px;
    }

    input, button {
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
      width: 150px;
    }
  }

  .multifieldElement {
    border: 1px dashed;
    padding: 5px 5px 0 5px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .multifieldElementContainer {
    border: 1px solid $green;
    padding: 5px 5px 0 5px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 772px) {
  form #inputs-container {
    button, input, select {
      float: none;
      width: 100%;
    }
  }
}

@media screen and (max-width: 772px) {
  form #inputs-container select[multiple] {
    width: 100%;
  }
}

.ui-datepicker {
  background-color: #fff;
  border: 1px solid $default-color;
  border-radius: 4px;
  box-shadow: 0 0 8px fade-in($default-color,0.5);
  display: none;
  margin-top: 4px;
  padding: 10px;
  width: 240px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: $black;
      -webkit-transition: color 0.1s ease-in-out;
      -moz-transition: color 0.1s ease-in-out;
      -o-transition: color 0.1s ease-in-out;
      transition: color 0.1s ease-in-out;
    }
  }

  td:hover a {
    color: $black;
    -webkit-transition: color 0.1s ease-in-out;
    -moz-transition: color 0.1s ease-in-out;
    -o-transition: color 0.1s ease-in-out;
    transition: color 0.1s ease-in-out;
  }

  .ui-datepicker-header {
    margin-bottom: 4px;
    text-align: center;
  }

  .ui-datepicker-title {
    font-weight: 700;
  }

  .ui-datepicker-prev, .ui-datepicker-next {
    cursor: default;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    height: 20px;
    line-height: 1;
    margin-top: 2px;
    width: 30px;
  }

  .ui-datepicker-prev {
    float: left;
    text-align: left;
  }

  .ui-datepicker-next {
    float: right;
    text-align: right;
  }

  .ui-datepicker-prev:before {
    content: "\e079";
  }

  .ui-datepicker-next:before {
    content: "\e080";
  }

  .ui-icon {
    display: none;
  }

  .ui-datepicker-calendar {
    table-layout: fixed;
    width: 100%;

    th {
      text-align: center;
      padding: 4px 0;
    }

    td {
      text-align: center;
      padding: 4px 0;
      border-radius: 4px;
      -webkit-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      -moz-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      -o-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

      &:hover {
        background-color: $grey;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .ui-datepicker-current-day {
    background-color: darken($default-color,20);

    a {
      color: #fff;
    }
  }

  .ui-datepicker-calendar .ui-datepicker-unselectable:hover {
    background-color: #fff;
    cursor: default;
  }
}

.form-error {
  + .form-error {
    display: none !important;
  }

  text-align: right;
  color: $red !important;
  font-style: italic;
  margin-bottom: 15px !important;
  display: block;
  font-size: 13px !important;
  font-weight: normal;
  font-family: $font;
}

div[id^="app_questionnaire_answersgroup"] {
  position: relative;
  .see-more {
    right: -25px;
  }
}

.multifieldElement .form-group:before {
  clear: both;
}

.form-import {
  .selectors-row {
      .selector-title {
        text-align: left;
        padding-left: 10px;
        font-size: var(--font-size-sm);
        font-weight: var(--font-semibold);
        font-family: var(--font-family);
      }

      .selector-select {
        margin: 0 5px;
      }

      .sample-data {
        padding: 0 10px;
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
      }
  }
}

form[name=app_medical_background] {
  input[type=radio]:checked + label {
    background-color: transparent;
  }

  label[for*=Alive] + .col-sm-6 > div,
  label[for*=TakenCareOf] + .col-sm-6 > div {
    display: flex;
    flex-direction: row;

    .form-check {
      &:nth-child(2), &:nth-child(3) {
        margin-left: 20px;
      }
    }

    .form-check-label {
      vertical-align: middle;
    }
  }
}

fieldset {
  .form-check {
    padding-top: 0;
  }

  label.form-checkbox-label.required:after {
    content: "";
    color: transparent;
  }
}

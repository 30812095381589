
.sidebar_icons {
  .menu-top {
    display: flex;
    align-items: center;

  }
}


.floating-notification-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 9;
  top: 10px;

  right: 10px;
  left: 10px;
  width: auto;
  max-height: 50vh;
  overflow: auto;

  @include sm {
    right: 65px;
    left: auto;
    width: 300px;
    max-height: calc(100vh - 10px);
    overflow: hidden;

  }

}

.sidebar-right {
  max-height: calc(100vh);
  display: none;
  z-index: 10;

  .menu-top {
    display: flex;
    flex-direction: column;


    .new {
      background: var(--color-primary-500);
      width: 40px;
      height: 40px;
      line-height: 35px;
      text-align: center;
      border-radius: 50%;
      position: relative;

      &:after {
        content: "Nouveau";
        position: absolute;
        display: block;
        bottom: -7px;
        font-size: 8px;
        font-weight: bold;
        background: var(--color-warning-500);
        height: 10px;
        color: #fff !important;
        z-index: 9;
        overflow: visible;
        width: auto;
        line-height: 7px;
        padding: 2px;
        text-align: center;
        left: -2px;
        right: -2px;
      }
    }



    .sidebar-icon {
      &, i.fa {
        @extend .text-lg;
      }

      .notification-dot {
        @extend .v2__badge;
      }
    }

    .change_version {
      display: block !important;
      margin-left: 0;
      margin-right: 0;
      line-height: 24px;
    }
  }

  .sidebar_icons {
    height: 100%;
    min-width: 56px;
  }

  .sidebar_content {
    min-width: 300px;
    height: 100%;
    margin-left: -1px;
    background-color: var(--color-primary-50);
    border-left: 1px solid var(--color-gray-50);
  }
}

.notification--drawer {
  display: none;
  width: 100%;
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;

  height: 100%;
  background-color: var(--color-primary-50);
  max-height: 100%;

  font-family: var(--font-family);

  .notification--drawer__header {
    padding: 15px;
    border-bottom: 1px solid var(--color-gray-50);
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    > div {
      flex: 1 1 auto;
    }

    span.sidebar-right-close {
      display: block;
      flex: 0 0 auto;
      font-size: var(--font-size-3xl);
      color: var(--color-gray-400);
      cursor: pointer;
    }


    span.title {
      @extend .text-xs;
      display: block !important;
      text-transform: uppercase;
      color: var(--color-gray-400);
      font-weight: var(--font-semibold);
      margin-bottom: 5px;
    }

    .notif_push {
      position: absolute;
      top: -1px;
      left: 105px;
      opacity: 0;

      .material-icons {
        font-size: var(--font-size-lg);
        color: var(--color-gray-700);
      }

      &:hover {
        .material-icons {
          color: var(--color-primary-500);
        }
      }
    }

    span.unread {
      @extend .text-base;
      display: block !important;
      font-weight: var(--font-semibold);
      color: var(--color-black);
    }
  }

  @include md {
    .notification--drawer__header .notif_push {
      opacity: 1;
    }
  }

  .notification--drawer__footer {
    border-top: 1px solid var(--color-gray-50);
    background-color: transparent;
    flex: 0 0 auto;

    a {
      display: block;
      text-align: center;
      padding: 10px 15px;
      height: initial;
      line-height: initial;
    }
  }

  .items {
    height: 100%;
    overflow: auto;
    padding: 15px;
    flex: 1 1 auto;
    max-height: none;

    .notification--item {
      @extend .notification--item;
    }
  }
}

.notification--item {
  border-radius: var(--rounded-lg);
  padding: 5px 10px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-100);

  &:hover {
    background-color: var(--color-gray-50);
  }

  &.n-unread {
    background-color: var(--color-primary-100);
    border: 1px solid var(--color-primary-100);

    &:hover {
      background-color: var(--color-primary-200);
    }
  }

  .mark-read {
    display: none;
  }

  a:hover {
    text-decoration: none;
  }

  .n-title {
    display: block;
    @extend .text-sm;
    color: var(--color-gray-900);
    width: 100%;
  }

  .n-content, .link .date {
    @extend .text-xs;
  }

  .n-content {
    color: var(--color-gray-900);
    font-weight: var(--font-semibold);
  }

  .date {
    color: var(--color-gray-500);
    font-weight: var(--font-normal);
    display: block;
    float: right;
    text-align: right;
    padding-top: 5px;
  }

  .close {
    display: none;
  }

  &.floating {
    border: 1px solid var(--color-underline);
    position: relative;
    width: auto;

    .close {
      position: absolute;
      top: 5px;
      right: 5px;
      display: block;
      span {
        font-size: var(--font-size-sm);
      }
    }

  }

}


@include md {

  .notification--drawer.inTopBar {
    display: none !important;
  }


  .sidebar-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    border-left: 1px solid var(--color-gray-50);

    display: flex;
    flex-direction: row;

    align-items: center;
    background-color: var(--color-white);

    .separator {
      margin: 15px 8px 15px 8px;
      width: auto;
    }

    .service-header {
      display: none;
    }

    .profile {
      padding-top: 22px;
      text-align: center;

      > div {
        margin: 15px 0 24px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          margin: auto;
          display: inline-block;
        }
      }

      span {
        display: none;
      }

      img:not(.icon) {
        box-shadow: none;
        width: 32px;
        height: 32px;
        min-width: 32px;
        font-size: 14px;
        border-radius: 100%;

        &.logo-institution {
          border-radius: 4px;
        }
      }

      .icon {
        box-shadow: none;
        &:hover {
          box-shadow: none;
        }
      }

    }

    .menu-top {
      position: relative;
      display: flex;
      gap: 24px;
      margin-top: 15px;
      margin-right: 0;

      .sidebar-icon {
        color: var(--color-gray-500);
        display: block;
        text-align: center;
        position: relative;
        line-height: 1;

        .notification-dot {
          position: absolute;
          right: -5px;
          top: -10px;
          height: 15px;
          min-width: 15px;
          border-radius: 8px;
          padding: 0 4px;
          line-height: 16px;
        }
      }

      .push {
        text-align: center;
        display: block;
      }
    }

    .notification--drawer {
      position: relative;
      top: 0;
      display: flex;
      width: 100%;
      max-height: calc(100vh);
      padding: 0;
    }

    .pull-right {
      float: initial !important;
    }
  }
}

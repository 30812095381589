/**
 *  SUMMARY
 *   - GENERICS
 *   - FORM_EDITOR
 *   - GRID_EDITOR
 *   - VARIABLES
 *   - TOGGLE_INPUT
 *   - MODEL_CONTENT
 */

/***********************/
/*      GENERICS       */
/***********************/
.toggle_button {
  cursor: pointer;

  display: none;

  .toggle_button_close {
    display: none;
  }
}

.toggle_input {
  display: none;
}

.new-buttons.new-buttons-editor {
  justify-content: start;
  padding-top: 15px;
  gap: 5px;
  flex-wrap: wrap;
}

.v2__document_editor {
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  .v2__alerts {
    margin: 0;

    &:empty {
      display: none;
    }

    > .alert {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
  }

  .v2__grid_editor {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 0 !important;

    .v2__block {
      overflow: visible;
    }

    // For field "drugs"
    .v2__editor {
      flex: auto;
    }

    .v2__editor .v2__form_row {
      flex-direction: column;

      &:before, &:after {
        display: none;
      }

      .v2-autocomplete {
        .bootstrap-tagsinput {
          display: flex;
          align-items: center;
          position: relative;

          span {
            display: block;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            padding-right: 15px;

            > span {
              position: absolute;
              right: -9px;
              left: auto;
              height: 18px;

              &:after {
                padding: 0;
              }
            }
          }
        }

        .v2-autocomplete--drawer {
          .autocompleter-group {
            @extend .text-sm;
            height: auto;
            padding-left: 10px;
            padding-right: 10px;
          }

          .autocompleter-value em {
            @extend .text-xs;
            font-weight: var(--font-normal);

            &.owner {
              font-weight: normal;
              font-style: initial;
              margin-top: 0;
              line-height: var(--font-size-2xs);
              padding: 0;
              color: var(--color-gray-500);
              font-size: var(--font-size-2xs);
            }


          }
        }
      }

      .dropdown-menu {
        min-width: auto !important;
      }

      [class^="col-"],
      [class^="col-sm-"] {
        padding: 0;
      }

      .btn {
        height: var(--input-height);
      }
    }

    .multiple_choices {
      .content-choices {
        display: flex;
        flex-wrap: wrap;

        .form-checkbox-label {
          margin-right: 15px;
        }
      }
    }

    .v2__editor .v2-autocomplete:not(.medical_record_field_input) .bootstrap-tagsinput {
      span + input {
        width: 1px;

        &:focus, &:focus-visible, &:focus-within, &:active {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .v2__options {
      display: flex;
      flex-direction: column;
      gap: 10px;

      select {
        margin-bottom: 0;
      }

      .last-save {
        color: var(--color-gray-700);
      }

      .v2__options_actions {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        gap: 10px;
      }
    }
  }

  .editor_fields,
  .v2__editor .v2__form_row {
    .bootstrap-tagsinput {
      input {
        padding: var(--p-2);
        max-width: 140px;
      }
    }
  }


  .edit-structured-data {
    margin-left: 15px;

    i {
      font-size: var(--font-size-xs);
    }
  }

}

@include editor {
  .v2__document_editor {
    .v2__grid_editor {
      flex-direction: row;
      gap: 10px;

      .v2__editor {
        min-width: 900px;
        padding-bottom: 200px;
      }

      .v2__options {
        // width is ignored by flex grow
        max-width: 290px;
        min-width: 290px;
      }
    }
  }
}

@include lg {
  .v2__document_editor {
    .v2__grid_editor {
      .v2__editor {
        .v2__form_row {
          flex-direction: row;
          align-items: end;

          .v2-autocomplete {
            width: 210px;
            max-width: 210px;
          }

          .v2__editor_toggle {
            flex-grow: 0;
            flex-shrink: 1;
            width: auto;
          }
        }
      }
    }
  }
}

@include xl {
  .v2__document_editor {
    .v2__grid_editor {
      .v2__editor {
        .v2__form_row {
          .v2__editor_toggle {
            width: auto;
          }
        }
      }
    }
  }
}

/***********************/
/*     FORM_EDITOR     */
/***********************/
.form_editor {
  .last-save {
    @extend .text-sm;
    text-align: right;
  }
}

.repeater-header {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: end;
}

.is-loading .structured-fields-empty {
  display: none;
}

/***********************/
/*     GRID_EDITOR     */
/***********************/
.grid_editor {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-top: 15px;
  border-top: 1px solid var(--color-gray-50);

  .editor {
    display: flex;
    flex-direction: column;

    .editor__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      .btn {
        margin: 0;
      }
    }

    .editor_fields {
      .empty strong {
        @extend .text-sm;
        font-weight: var(--font-normal);
        color: var(--color-gray-500);
        display: block;
        text-align: center;
        min-height: 50vh;
      }

      .small-box {
        overflow: inherit;
        border-radius: 0;
        border-radius: var(--rounded);

        margin-bottom: 15px;

        background-color: var(--color-primary-50);
        border: 1px solid var(--color-gray-50);

        background-color: var(--color-primary-100);
        border: 1px solid var(--color-primary-100);

        h2 {
          padding: 10px 15px;

          label {
            margin: 0;
          }
        }

        &.w-49 {
          width: auto;
        }

        .dt-editor-content, > .min-150 {
          border: 0;
          border-top: 1px solid var(--color-gray-100);
          padding: 15px 10px 15px 15px;
          margin: 0;
        }

        .dt-editor-content.form-col {
          padding-top: 0;
        }

        > .min-150 {
          padding-top: 10px;
        }
      }

      &#fields {
        .min-150,
        .dt-editor-content .toolbar,
        .dt-editor-content .editor {
          max-width: inherit;
          margin: 0;
        }

        textarea.dt-editor-content p {
          margin: 10px 0 0 0;
        }

      }

      .touchable {
        padding: 15px;

        &, > * {
          @extend .text-sm;
          font-weight: var(--font-semibold) !important;
          border: 0;
        }

        i {
          color: var(--color-gray-600);

          &:hover {
            color: var(--color-primary-500);
          }

        }
      }

      .editor {
        border: 1px solid var(--color-gray-100);

        p {
          color: var(--color-gray-900);
        }
      }

      .list {
        .repeatable-row {
          display: flex;
          flex-flow: wrap;
          gap: 10px;
          position: relative;

          &:not(:first-child) {
            padding-top: 10px;
          }

          &:before,
          &:after {
            display: none;
          }

          .remove_btn_container {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            color: var(--color-gray-500);
            cursor: pointer;

            i {
              @extend .text-sm;
              margin: 0;
            }
          }


          [class^="col-sm-"] {
            min-width: 250px;
            width: auto;
            float: initial;
            flex-grow: 1;
          }


          .is-small {
            max-width: 110px;
            min-width: auto;
          }

        }

        .repeatable-row:not(:first-child) {
          border-top: 1px solid var(--color-gray-50);

        }
      }
    }

    .document-editor {
      border: 1px solid var(--color-gray-50);
      box-shadow: none;
      margin: 15px;

      p {
        color: var(--color-gray-900);
      }
    }


    .loader-container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 999;
      background: transparentize(#fff, 0.2);

      .loading-tip {
        margin-top: 20px;
        min-height: 200px;
      }

    }

  }

  > .editor {
    width: calc(100% - 10px);
  }
}

/********************/
/*    VARIABLES     */
/********************/

.v2__options__container.fixed {
  position: fixed;
  width: 290px;
  top: 10px;
  z-index: 1;
}

.v2__grid_editor .v2__options .variables {
  background-color: var(--color-white);
  margin-top: 15px;

  .search,
  .variable_group a,
  .variable_group .variable_list {
    margin-left: 15px;
    margin-right: 15px;
  }

  h2 {
    @extend .text-base;
    color: var(--color-gray-900);
    margin-bottom: 0;
    border: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      cursor: pointer;
      font-size: var(--font-size-base);
      color: var(--color-gray-700);
    }

  }

  .variable_container {
    border-top: 1px solid var(--color-gray-50);
    min-height: 100px;
    display: flex;
    flex-direction: column;
    max-height: 55vh;
    overflow: scroll;
    position: relative;

    .variable_group {
      border-bottom: 1px solid var(--color-gray-50);
      padding-top: 15px;
      padding-bottom: 15px;

      a {
        > * {
          @extend .text-sm;
          font-weight: var(--font-semibold) !important;
          margin: 0;
        }

        i {
          color: var(--color-gray-600);
        }
      }

      .variable_list {
        display: flex;
        flex-flow: wrap;
        gap: 2px;
        margin-top: 0.5rem;
      }

      .variable_tag {
        @extend .text-sm;
        @extend .v2__tag;
        cursor: pointer;

        &.empty-var {
          display: none;
        }
      }
    }

    &.show-empty {
      .variable_group {
        .variable_tag.empty-var {
          display: inline-flex;
          background-color: var(--color-danger-100);
        }
      }
    }

  }
}


@include md {
  .grid_editor {
    position: relative;
    flex-direction: row;

    > .editor {
      width: 70%;
      min-height: 250px;
    }

    .options {
      width: 29%;
    }
  }

  /*************************/
  /*     TOGGLE_INPUT      */
  /*************************/
  .toggle_button {
    display: flex;
  }

  .toggle_input:checked {
    & ~ .grid_editor {
      display: block;

      .options {
        display: none;
      }

      > .editor {
        width: calc(100% - 10px);
      }

      .toggle_button {
        .toggle_button_open {
          display: none;
        }

        .toggle_button_close {
          display: block;
        }
      }
    }
  }
}

.repeater-editor {
  padding: 10px;
  border: 1px solid var(--color-gray-200);
  margin-bottom: 10px;
}


/***********************/
/*    MODEL_CONTENT    */
/***********************/
.editor__model__content {
  > p {
    margin: 10px 0;
  }

  .grid_editor {
    margin-top: 0;
    border-top: 0;
  }
}


code.primary {
  color: var(--color-primary-500);
  background: var(--color-primary-100);
  font-weight: bold;
}

.BOTO-93-backdrop {
  z-index: 3;
}

div[class$="modalPlatform"] div[class$="footer"] {
  button:nth-child(1) {
    background-color: var(--color-white);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    border: 1px solid var(--color-gray-100);
    color: var(--color-gray-900);
    font-weight: var(--font-semibold);
    border-radius: var(--rounded-lg);
  }

  button:nth-child(2) {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    background-color: var(--color-gray-900);
    border: 1px solid var(--color-gray-900);
    color: var(--color-white);
    font-weight: var(--font-semibold);
    border-radius: var(--rounded-lg);
  }
}


.logo-synapse {
  position: absolute;
  width: 150px;
  right: 15px;
  z-index: 30;
}

#editeur_synapse {
  z-index: 2;
}

section[type="drugs_synapse"] br + br, section[type="drugs"] br + br {
  height: 10px;
  display: block;
  line-height: 8px;
  font-size: 13px;
  content: " ";
}

.synapse-widget .prescription-widget .posology-form-container {
  position: absolute;;
  z-index: 31;
  top: -10px;
  background-color: var(--color-white);

  header {
    button {
      display: flex !important;
    }
  }

  .autocomplete-dropdown {
    z-index: 49;
  }

  .posology-form {
    overflow: auto;
    max-height: 400px;
    position: relative;
    max-width: 100%;
  }


  footer {
    position: sticky;
    border: 0;
    right: 0;
    left: 0;
    z-index: 2;
    bottom: 0;
  }

}

.synapse-widget .additional-infos-options .options-content .date-inputs,
.synapse-widget .additional-infos-options .options-content .second-line {
  label {
    display: flex;
    padding: 2px 10px 0 15px;
  }

  input {
    background: none;
    border: none;
    height: initial;
    padding: 0;
  }
}

.synapse-widget [data-testid="packaging-select"] label {
  display: none;
}

.synapse-widget .drug-name {
  padding: 0;
}

.synapse-widget [data-testid="posology-form-dose-unit-autocomplete"] label {
  display: flex;
}

.synapse-widget .posoform-posology-section {
  width: 100%;
}

.synapse-widget [data-testid="posology-model-button"] {
  display: none;
}

.synapse-widget [data-testid="validate-button"] {
  border-radius: 5px;
  button {
    color: var(--color-white);
    background: var(--color-gray-900-opactity-08);
    font-weight: 600;
    font-size: 14px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.synapse-widget .advanced-validation {
  button {
    font-weight: 600;
    font-size: 14px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;
  }
  button:first-child {
    border: 1px solid var(--color-gray-900-opactity-08);
    background: var(--color-white);
    color: var(--color-gray-900-opactity-08);
  }
  button:last-child {
    color: var(--color-white);
    background: var(--color-gray-900-opactity-08);
  }
}



.synapse-widget .d-flex {
  display: flex !important;
}

.synapse-widget .justify-content-between {
  justify-content: space-between !important;
}

.synapse-widget .posology-form .component-select .options {
  z-index: 3;
}

.synapse-widget .mb-2 {
  margin-bottom: 8px !important;
}
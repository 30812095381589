@import "variables_without_font";

@font-face {
  font-family: $font;
  src: url('../../fonts/Gilroy-Light.otf') format("truetype");
}


@font-face {
  font-family: $font-bold;
  src: url('../../fonts/Gilroy-ExtraBold.otf') format("truetype");
}

@font-face {
  font-family: $font-semiBold;
  src: url('../../fonts/Gilroy-SemiBold.ttf') format("truetype");
}
